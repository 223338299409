import {
  GraphQLTypes,
  InputType,
  Selector
} from '~/__generated__/backend/zeus';
import { g } from '~/globals';
import { userSelector } from '~/querySelectors/userSelector';

const queryKey = ['profile'];
// Define select here to derive typing
const selector = Selector('Query')({
  me: {
    ipAddress: true,
    user: userSelector,

    dealerships: {
      id: true,
      name: true
    }
  }
});
export type MeQueryType = InputType<GraphQLTypes['Query'], typeof selector>;

/**
 * This query can be used in all react components. The reason why we store it
 * on the outermost level her (and not just the selector) is to ensure that we
 * reuse the query selector.
 */
export default function meQuery() {
  return {
    queryKey,
    queryFn: () => {
      return g.reg.tdcBackendClient.gqlQueryClient({
        dealershiplessAuth: true
      })(selector);
    }
  };
}

export const resetMeQuery = async () => {
  await g.reg.queryClient.resetQueries({
    queryKey
  });
};
