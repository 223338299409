import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import config from './config';
import { g } from './globals';
import { languageAtom } from './state/language';

const setLang = () => {
  const currentLang = g.reg.jotaiStore.get(languageAtom);
  void i18n.changeLanguage(currentLang);
};

void i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    ns: ['translation', 'files'],
    defaultNS: 'translation',

    nsSeparator: false,
    keySeparator: false,
    fallbackLng: false,

    debug: !config.isProduction,

    backend: {
      // for all available options read the backend's repository readme file
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    },

    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  })
  .then(setLang);

g.reg.jotaiStore.sub(languageAtom, setLang);

export default i18n;
