import { atom } from 'jotai';
import range from 'lodash/range';
import { ChangeEvent, Fragment, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import {
  GraphQLTypes,
  InputType,
  Selector
} from '~/__generated__/backend/zeus';
import uploadFileAction from '~/actions/formSubmissions/uploadFileAction';
import Signature from '~/components/Signature';
import Spinner from '~/components/Spinner';
import FileErrorIcon from '~/components/icons/FileErrorIcon';
import { g } from '~/globals';
import { dataUriToBlob } from '~/lib/stringUtils';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

type Value =
  | {
      t: 'checkbox';
      value?: boolean;
    }
  | {
      t: 'string';
      value: string;
    }
  | {
      t: 'file';
      fileUrl: string;
      fileId: string;
    };

export const formFieldSelector = Selector('FormField')({
  type: true,
  formFieldId: true,
  characterListLength: true,

  checkboxGroup: {
    rule: true,
    id: true,
    n: true
  },

  formFieldPlacements: {
    formFieldPlacementId: true,

    page: true,
    positionX: true,
    positionY: true,
    width: true,
    height: true,
    textFontSize: true
  }
});
type FormField = InputType<GraphQLTypes['FormField'], typeof formFieldSelector>;

export const mkFormDataAtom = () => atom<Record<string, Value>>({});

type Props = {
  dealershipSlug: string;
  authHeaders: HeadersInit;
  formDataAtom: ReturnType<typeof mkFormDataAtom>;
  pdfUrl: string;
  formFieldSubmissions: FormField[];
};

export default function PdfFiller({
  dealershipSlug,
  authHeaders,
  formDataAtom,
  pdfUrl,
  formFieldSubmissions: formFields
}: Props) {
  const inputRefs = useRef<Map<string, HTMLInputElement>>(new Map());
  const divRefs = useRef<Map<string, HTMLElement>>(new Map());
  const [numPages, setNumPages] = useState(0);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  const updateValue = (formFieldId: string, value: Value) => {
    g.reg.jotaiStore.set(formDataAtom, (prev) => {
      return {
        ...prev,
        [formFieldId]: value
      };
    });

    // Update refs
    const field = formFields?.find((ffs) => ffs.formFieldId === formFieldId);

    field?.formFieldPlacements?.forEach((ffp) => {
      if (value.t === 'string') {
        const input = inputRefs.current.get(
          ffp.formFieldPlacementId ?? 'no-form-field-placement-id'
        );
        if (input) {
          input.value = value.value;
        }
      } else if (value.t === 'checkbox') {
        const input = inputRefs.current.get(
          ffp.formFieldPlacementId ?? 'no-form-field-placement-id'
        );
        if (input) {
          input.checked = value.value ?? false;
        }
      } else if (value.t === 'file') {
        const div = divRefs.current.get(
          ffp.formFieldPlacementId ?? 'no-form-field-placement-id'
        );

        if (div?.innerHTML) {
          div.innerHTML = `<img src="${value.fileUrl}" />`;
        }
      }
    });
  };

  const setSignature = async (formFieldId: string, dataURI: string) => {
    const blob = await dataUriToBlob(dataURI);

    const uploadIfo = await uploadFileAction(
      { dealershipSlug },
      'png',
      'image/png',
      blob,
      blob.size.toString()
    );
    updateValue(formFieldId, {
      t: 'file',
      fileId: uploadIfo?.fileId ?? 'no-file-id',
      fileUrl: dataURI
    });
  };

  // We can not re render this while entering into the forms fields.
  // We really should not do this.
  return (
    <div>
      {/* It is super important that this does not see rerenders */}
      <Document
        options={{
          httpHeaders: authHeaders
        }}
        file={pdfUrl}
        onLoadSuccess={onDocumentLoadSuccess}
        className="flex flex-col space-y-1"
        loading={
          <div
            className="flex w-full h-72 justify-center items-center"
            data-test-id={'pdf-filling-document-spinner'}
          >
            <Spinner />
          </div>
        }
        error={
          <div className="flex flex-col w-full h-72 justify-center items-center">
            <div className="flex flex-col space-y-4 items-center">
              <div className="relative">
                <FileErrorIcon className="w-20 icon-negative" />
              </div>

              <p className="text-negative">Failed to load document</p>
            </div>
          </div>
        }
      >
        {range(numPages).map((idx, pageNumberMinusOne) => (
          <div data-test-id={'pdf-filling-page-wrapper'} key={idx}>
            <Page
              key={pageNumberMinusOne}
              pageNumber={pageNumberMinusOne + 1}
              renderAnnotationLayer={false}
              renderTextLayer={false}
              className="border border-inactive"
            >
              {formFields.map((field, i) => (
                <Fragment key={i}>
                  {field.formFieldPlacements
                    ?.filter((p) => p.page === pageNumberMinusOne)
                    .map((placement) => {
                      // Only rely on this for initial render
                      const initialValue =
                        g.reg.jotaiStore.get(formDataAtom)[
                          field.formFieldId ?? 'no-form-field-id'
                        ];
                      if (field.type === 'TEXT') {
                        return (
                          <input
                            defaultValue={
                              initialValue?.t === 'string'
                                ? initialValue.value
                                : ''
                            }
                            key={placement.formFieldPlacementId}
                            ref={(el) => {
                              if (el) {
                                inputRefs.current.set(
                                  placement.formFieldPlacementId ??
                                    'no-form-field-placement-id',
                                  el
                                );
                                el.onkeyup = (e: Event) => {
                                  const ie =
                                    e as unknown as ChangeEvent<HTMLInputElement>;
                                  updateValue(
                                    field.formFieldId ?? 'no-form-field-id',
                                    {
                                      t: 'string',
                                      value: ie.target.value
                                    }
                                  );
                                };
                              } else {
                                inputRefs.current.delete(
                                  placement.formFieldPlacementId ??
                                    'no-form-field-placement-id'
                                );
                              }
                            }}
                            name={placement.formFieldPlacementId ?? undefined}
                            className="border-yellow-500 border-2 absolute bg-transparent align-top p-0 m-0 overflow-hidden"
                            style={{
                              resize: 'none',
                              boxSizing: 'border-box',
                              fontSize: placement.textFontSize ?? undefined,
                              left: placement.positionX ?? undefined,
                              top: placement.positionY ?? undefined,
                              width: placement.width ?? undefined,
                              height: placement.height ?? undefined
                            }}
                            data-test-id={`form-pdf-filling-${field.type}-${placement.formFieldPlacementId}`}
                          />
                        );
                      } else if (field.type === 'CHARACTER_LIST') {
                        return (
                          <input
                            defaultValue={
                              initialValue?.t === 'string'
                                ? initialValue.value
                                : ''
                            }
                            key={placement.formFieldPlacementId}
                            ref={(el) => {
                              if (el) {
                                inputRefs.current.set(
                                  placement.formFieldPlacementId ??
                                    'no-form-field-placement-id',
                                  el
                                );
                                el.onkeyup = (e: Event) => {
                                  const ie =
                                    e as unknown as ChangeEvent<HTMLInputElement>;
                                  updateValue(
                                    field.formFieldId ?? 'no-form-field-id',
                                    {
                                      t: 'string',
                                      value: ie.target.value
                                    }
                                  );
                                };
                              } else {
                                inputRefs.current.delete(
                                  placement.formFieldPlacementId ??
                                    'no-form-field-placement-id'
                                );
                              }
                            }}
                            name={placement.formFieldPlacementId ?? undefined}
                            className="border-yellow-500 border-2 absolute bg-transparent align-top p-0 m-0 overflow-hidden"
                            maxLength={field.characterListLength ?? undefined}
                            style={{
                              resize: 'none',
                              boxSizing: 'border-box',
                              fontSize: placement.textFontSize ?? undefined,
                              left: placement.positionX ?? undefined,
                              letterSpacing: placement.width ?? undefined,
                              top: placement.positionY ?? undefined,
                              width:
                                (placement.width ?? 0) *
                                  (field.characterListLength ?? 1) +
                                (placement.width ?? 0) +
                                // Space for the curer to not push the text to the left
                                2 * (placement.width ?? 0),
                              padding: (placement.width ?? 0) / 2,
                              height: placement.height ?? undefined
                            }}
                            data-test-id={`form-pdf-filling-${field.type}-${placement.formFieldPlacementId}`}
                          />
                        );
                      } else if (field.type === 'CHECKBOX') {
                        return (
                          <input
                            type="checkbox"
                            key={placement.formFieldPlacementId}
                            ref={(el) => {
                              if (el) {
                                inputRefs.current.set(
                                  placement.formFieldPlacementId ??
                                    'no-form-field-placement-id',
                                  el
                                );

                                // Initiate the checkbox value to be unchecked
                                updateValue(
                                  field.formFieldId ?? 'no-form-field-id',
                                  {
                                    t: 'checkbox',
                                    value: false
                                  }
                                );

                                el.onchange = () => {
                                  const value =
                                    g.reg.jotaiStore.get(formDataAtom)[
                                      field.formFieldId ?? 'no-form-field-id'
                                    ];
                                  updateValue(
                                    field.formFieldId ?? 'no-form-field-id',
                                    {
                                      t: 'checkbox',
                                      value:
                                        value?.t === 'checkbox'
                                          ? !value.value
                                          : false
                                    }
                                  );
                                };
                              } else {
                                inputRefs.current.delete(
                                  placement.formFieldPlacementId ??
                                    'no-form-field-placement-id'
                                );
                              }
                            }}
                            name={placement.formFieldPlacementId ?? undefined}
                            className="border-yellow-500 border-2 absolute bg-transparent align-top p-0 m-0 overflow-hidden"
                            style={{
                              resize: 'none',
                              boxSizing: 'border-box',
                              fontSize: placement.textFontSize ?? undefined,
                              left: placement.positionX ?? undefined,
                              top: placement.positionY ?? undefined,
                              width: placement.width ?? undefined,
                              height: placement.height ?? undefined
                            }}
                            data-test-id={`form-pdf-filling-${field.type}-${placement.formFieldPlacementId}`}
                          />
                        );
                      } else if (field.type === 'SIGNATURE') {
                        return (
                          <div
                            ref={(el) => {
                              if (el) {
                                divRefs.current.set(
                                  placement.formFieldPlacementId ??
                                    'no-form-field-placement-id',
                                  el
                                );
                              } else {
                                divRefs.current.delete(
                                  placement.formFieldPlacementId ??
                                    'no-form-field-placement-id'
                                );
                              }
                            }}
                            key={placement.formFieldPlacementId}
                            className="relative cursor-pointer"
                            style={{
                              position: 'absolute',
                              left: placement.positionX ?? undefined,
                              top: placement.positionY ?? undefined,
                              width: placement.width ?? undefined,
                              height: placement.height ?? undefined
                            }}
                            data-test-id={`form-pdf-filling-${field.type}-${placement.formFieldPlacementId}`}
                          >
                            <Signature
                              onSignatureSubmit={(dataURi) =>
                                setSignature(
                                  field.formFieldId ?? 'no-form-field-id',
                                  dataURi
                                )
                              }
                              dataTestIdCanvas={`form-pdf-signature-canvas-${placement.formFieldPlacementId}`}
                              dataTestIdOpenModal={`form-pdf-open-modal-${placement.formFieldPlacementId}`}
                              dataTestIdSubmit={`form-pdf-submit-button-${placement.formFieldPlacementId}`}
                            />
                          </div>
                        );
                      }
                    })}
                </Fragment>
              ))}
            </Page>
          </div>
        ))}
      </Document>
    </div>
  );
}
