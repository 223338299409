import classNames from 'classnames';
import { FC, useEffect, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { DropdownOption } from './dropdownConfig';

const Dropdown: FC<{
  options: DropdownOption[];
  onSelect: (option: DropdownOption) => Promise<void> | void;
  className?: string;
  highlightedValue?: string;
}> = ({ options, onSelect, className, highlightedValue }) => {
  const highlightedRef = useRef<HTMLDivElement | null>(null);

  /**
   * This useEffect is key to ensure that highlighted element are properly rendered
   * And scrolled to element
   */
  useEffect(() => {
    if (highlightedRef.current) {
      highlightedRef.current.scrollIntoView({ block: 'nearest' });
    }
  }, [highlightedValue]);

  return (
    <div
      className={twMerge(
        'py-spacing-02 rounded-radius-02 bg-primary shadow-shadow-03 w-80 max-h-60 overflow-y-scroll',
        className
      )}
    >
      {options.map((option) => {
        const isHighlighted = option.value === highlightedValue;
        return (
          <div
            key={option.value}
            ref={isHighlighted ? highlightedRef : null}
            onClick={() => {
              void onSelect(option);
            }}
            className={twMerge(
              classNames(
                'w-full px-spacing-04 py-spacing-03 desktop:py-spacing-02 focus:outline focus:outline-2 focus:-outline-offset-2 outline-blue-800 hover:bg-gray-100 active:bg-gray-200',
                { 'bg-gray-100 text-white': isHighlighted }
              )
            )}
          >
            <p className="body-02 text-gray-900 text-start">{option.label}</p>
          </div>
        );
      })}
    </div>
  );
};

export default Dropdown;
