import { FC, Fragment, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '..';
import Button from '../../button';

/**
 * This modal is used when a user tries to perform an action with serious consequences such as removing a co-buyer from a transaction.
 * This component can be used to show the user a warning message before proceeding with the action.
 * The user has to actively confirm the action by clicking the confirmation button.
 *
 * Example:
 *
 * ```tsx
 * const Page = () => {
 *  const { t } = useTranslation();
 *  const [isUserConfirmationModalOpen, setIsUserConfirmationModalOpen] = useState(false);
 *
 *  const removeCoBuyer = () => {
 *    setIsUserConfirmationModalOpen(false);
 *    ...
 *  }
 *
 *  return (
 *    <div>
 *      <UserConfirmationModal
 *        isOpen={isUserConfirmationModalOpen}
 *        message={t('You are about to remove the co-buyer from this transaction. This action is permanent and cannot be undone. Are you sure you want to proceed?')}
 *        onClose={() => setIsUserConfirmationModalOpen(false)}
 *        onConfirm={removeCoBuyer}
 *      />
 *
 *      <Button
 *        label="Remove co-buyer"
 *        onClick={() => setIsUserConfirmationModalOpen(true)}
 *      />
 *    </div>
 *  );
 * }
 * ```
 *
 * @param isOpen: Whether to show the modal
 * @param message: The warning message
 * @param onClose: Function that closes the modal as the open state is managed in the parent component. This is also called if the user cancels their action.
 * @param onConfirm: Function that is called if the user confirms their action
 * @param dataTestId: Id that can be referenced in end to end tests
 */
const UserConfirmationModal: FC<{
  isOpen: boolean;
  message: string | ReactElement;
  onClose: () => void;
  onConfirm: () => void;
  dataTestId?: string;
}> = ({ isOpen, message, onClose, onConfirm, dataTestId }) => {
  // TODO: FIX THIS, we need to get translations passed in as we do not extract translations from
  // the libs. Alternatively, fix the translations extractions function
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      title={t('Warning')} // The modal title is consistent.
      onClose={onClose}
      dataTestId={dataTestId}
    >
      <Fragment>
        <p className="body-01">{message}</p>

        <div className="flex flex-row justify-between">
          <Button
            label={t('Cancel')} // The button label is consistent
            onClick={onClose}
            variant="SECONDARY"
            dataTestId={`${dataTestId}-cancel-button`}
          />

          <Button
            label={t('Confirm')} // The button label is consistent
            onClick={onConfirm}
            dataTestId={`${dataTestId}-confirm-button`}
          />
        </div>
      </Fragment>
    </Modal>
  );
};

export default UserConfirmationModal;
