import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { g } from '~/globals';
import customerQuery from '~/queries/customerQuery';
import meQuery from '~/queries/meQuery';
import { languageAtom } from '~/state/language';

export const useEnsureLanguage = (
  transactionId: string,
  dealershipSlug: string
) => {
  const { data: meData } = useQuery(meQuery());
  const { i18n } = useTranslation();

  const { data: customerData } = useQuery(
    customerQuery(
      transactionId,
      meData?.me?.user?.id ?? undefined,
      dealershipSlug
    )
  );

  const firstLanguage = customerData?.customer?.languageSelection?.at(0);

  // When language change, we change the language fot eh application
  useEffect(() => {
    const setTo = firstLanguage ?? 'en';
    g.reg.jotaiStore.set(languageAtom, setTo);

    // Fire and forget
    void i18n.changeLanguage(setTo);
  }, [firstLanguage, i18n]);
};
