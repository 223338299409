import {
  EmploymentStatus,
  HousingStatus,
  IncomeInterval,
  VehicleBodyType,
  VehicleCondition,
  VehicleLifeCycleStage,
  VehiclePrincipalPriorUse,
  VehicleRegistrationAddressAnswer
} from '~/__generated__/backend/zeus';

/**
 * This is a helper function. When adding new enums, they have to be included here as  well
 *
 * @param s
 * @returns
 */
export const stringToVehicleCondition = (
  s: string
): VehicleCondition | undefined => {
  const VehicleConditionMap: { [key: string]: VehicleCondition } = {
    EXCELLENT: VehicleCondition.EXCELLENT,
    GOOD: VehicleCondition.GOOD,
    SATISFACTORY: VehicleCondition.SATISFACTORY,
    UNSATISFACTORY: VehicleCondition.UNSATISFACTORY
  };

  return VehicleConditionMap[s];
};

/**
 * This is a helper function. When adding new enums, they have to be included here as  well
 *
 * @param s
 * @returns
 */
export const stringToVehicleBodyType = (
  s: string
): VehicleBodyType | undefined => {
  const VehicleBodyTypeMap: { [key: string]: VehicleBodyType } = {
    COUPE: VehicleBodyType.COUPE,
    MINIVAN: VehicleBodyType.MINIVAN,
    OTHER: VehicleBodyType.OTHER,
    SEDAN: VehicleBodyType.SEDAN,
    SUV: VehicleBodyType.SUV
  };

  return VehicleBodyTypeMap[s];
};

/**
 * This is a helper function. When adding new enums, they have to be included here as  well
 *
 * @param s
 * @returns
 */
export const stringToHousingStatus = (s: string): HousingStatus | undefined => {
  const HousingStatusMap: { [key: string]: HousingStatus } = {
    OWNING: HousingStatus.OWNING,
    RENTING: HousingStatus.RENTING,
    LIVING_WITH_RELATIVES: HousingStatus.LIVING_WITH_RELATIVES,
    OTHER: HousingStatus.OTHER
  };

  return HousingStatusMap[s];
};

/**
 * This is a helper function. When adding new enums, they have to be included here as  well
 *
 * @param s
 * @returns
 */
export const stringToEmploymentStatus = (
  s: string
): EmploymentStatus | undefined => {
  const EmploymentStatusMap: { [key: string]: EmploymentStatus } = {
    EMPLOYED: EmploymentStatus.EMPLOYED,
    RETIRED: EmploymentStatus.RETIRED,
    SELF_EMPLOYED: EmploymentStatus.SELF_EMPLOYED,
    STUDENT: EmploymentStatus.STUDENT,
    UNEMPLOYED: EmploymentStatus.UNEMPLOYED
  };

  return EmploymentStatusMap[s];
};

/**
 * This is a helper function. When adding new enums, they have to be included here as  well
 *
 * @param s
 * @returns
 */
export const stringToIncomeInterval = (
  s: string
): IncomeInterval | undefined => {
  const IncomeIntervalMap: { [key: string]: IncomeInterval } = {
    MONTHLY: IncomeInterval.MONTHLY,
    WEEKLY: IncomeInterval.WEEKLY,
    YEARLY: IncomeInterval.YEARLY,
    // Deprecated
    BI_WEEKLY: IncomeInterval.BI_WEEKLY
  };

  return IncomeIntervalMap[s];
};

/**
 * This is a helper function. When adding new enums, they have to be included here as  well
 *
 * @param s
 * @returns
 */
export const stringToVehicleRegistrationAddressAnswers = (
  s: string
): VehicleRegistrationAddressAnswer | undefined => {
  const VehicleRegistrationAddressAnswerMap: {
    [key: string]: VehicleRegistrationAddressAnswer;
  } = {
    SAME: VehicleRegistrationAddressAnswer.SAME,
    DIFFERENT: VehicleRegistrationAddressAnswer.DIFFERENT
  };

  return VehicleRegistrationAddressAnswerMap[s];
};

/**
 * This is a helper function. When adding new enums, they have to be included here as  well
 *
 * @param s
 * @returns
 */
export const stringToVehicleLifeCycleStage = (
  s: string
): VehicleLifeCycleStage => {
  const VehicleLifeCycleStageMap: {
    [key: string]: VehicleLifeCycleStage;
  } = {
    IS_USED: VehicleLifeCycleStage.IS_USED,
    IS_NEW: VehicleLifeCycleStage.IS_NEW
  };

  return VehicleLifeCycleStageMap[s];
};

/**
 * This is a helper function. When adding new enums, they have to be included here as well
 */
export const stringToVehiclePrincipalPriorUse = (
  s: string
): VehiclePrincipalPriorUse => {
  const VehiclePrincipalPriorUseMap: {
    [key: string]: VehiclePrincipalPriorUse;
  } = {
    COMMERCIAL: VehiclePrincipalPriorUse.COMMERCIAL,
    DEMO: VehiclePrincipalPriorUse.DEMO,
    FLEET: VehiclePrincipalPriorUse.FLEET,
    LIVERY_VEHICLE: VehiclePrincipalPriorUse.LIVERY_VEHICLE,
    OTHER: VehiclePrincipalPriorUse.OTHER,
    PRIVATE_PASSENGER_VEHICLE:
      VehiclePrincipalPriorUse.PRIVATE_PASSENGER_VEHICLE,
    RENTAL_VEHICLE: VehiclePrincipalPriorUse.RENTAL_VEHICLE,
    TAXI: VehiclePrincipalPriorUse.TAXI
  };

  return VehiclePrincipalPriorUseMap[s];
};
