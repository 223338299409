import { Dialog } from '@headlessui/react';
import { Close } from '@mui/icons-material';
import classNames from 'classnames';
import { FC, ReactElement } from 'react';
import Button from '../button';

/**
 * The modal component renders a dialog above all elements of the application on a darkened backdrop.
 *
 * Example:
 *
 * ```tsx
 * const Page = () => {
 *  const [isOpen, setIsOpen] = useState(false);
 *
 *  return (
 *    <div>
 *      <Modal
 *        isOpen={isOpen}
 *        title="Title"
 *        onClose={() => setIsOpen(false)}
 *      >
 *        <p>This is a message.</p>
 *      </Modal>
 *
 *      <Button
 *        label="Open modal"
 *        onClick={() => setIsOpen(true)}
 *      />
 *    </div>
 *  );
 * }
 * ```
 *
 * @param isOpen: Whether to show the modal and it's content
 * @param title: The title shown on top of the modal
 * @param onClose: Function that closes the modal as the open state is managed in the parent component.
 * @param children: React elements rendered as the modal content.
 * @param dataTestId: Id that can be referenced in end to end tests
 */
const Modal: FC<{
  isOpen: boolean;
  title: string;
  onClose: () => void;
  children: ReactElement;
  dataTestId?: string;
}> = ({ isOpen, title, onClose, dataTestId, children }) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Dialog.Backdrop className="fixed inset-0 bg-black/40" />

      {/* Full-screen container to center the panel */}
      <div
        className={classNames(
          'fixed inset-0 flex w-screen items-center justify-center p-spacing-04',
          'z-10' // The z-index here is used within the context of the Dialog to show it above the backdrop. The Dialog handles being rendered on top of other application components.
        )}
        data-test-id={dataTestId}
      >
        <Dialog.Panel className="flex flex-col w-full max-w-screen-sm bg-primary p-spacing-04 tablet:p-spacing-05 space-y-spacing-04 tablet:space-y-spacing-05 rounded-radius-04 justify-center shadow-shadow-05">
          <div className="flex flex-row justify-between items-center">
            <Dialog.Title className="heading-emphasized-02">
              {title}
            </Dialog.Title>

            <Button
              variant="GHOST"
              iconLeft={<Close />}
              size="SMALL"
              className="text-primary"
              onClick={onClose}
            />
          </div>

          {children}
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default Modal;
