import { PrequalFormSchema } from '@thedealersconcierge/lib/codecs/schema/prequalApplication';
import { format } from 'date-fns';
import { cognitoRequestOtpAction } from '~/actions/auth/cognitoRequestOtpAction';
import { hasExistingUserAction } from '~/actions/auth/hasExistingUser';
import requestOtpExistingUserDEVAction from '~/actions/auth/requestOtpExistingUserDEVAction';
import { PrequalFormValues } from '~/components/forms/PrequalForm';
import config from '~/config';
import { g } from '~/globals';
import { gqlMutationClient } from '~/lib/backend';

const submitWebPreQualApplicationWorkflow = async ({
  dealershipId,
  value,
  ipAddress,
  onSubmittedFormValues,
  onSubmittedPrequal,
  onRequestedOtp
}: {
  dealershipId: string;
  value: {
    phoneNumber: string;
    email: string;
    firstName: string;
    middleName: string;
    lastName: string;
    birthdate: string;
    socialSecurityNumber: string;
    street: string;
    apartmentDetails: string;
    zip: string;
    city: string;
    state: string;
    signature: string;
    confirmNotAffectCreditScoreAndPrequalConsented: boolean;
    acknowledgesElectronicConsent: boolean;
    hasCommunicationsConsented: boolean;
  };
  ipAddress: string;
  onSubmittedFormValues: (
    value: PrequalFormValues,
    formData: PrequalFormSchema
  ) => void;
  onSubmittedPrequal: () => void;
  onRequestedOtp: () => void;
}) => {
  const d: PrequalFormSchema = {
    state: value.state,
    suffix: '',
    middleName: value.middleName ?? '',
    birthdate: new Date(
      // The prequal schema works with dates. We have to make sure it's a UTC date
      `${value.birthdate}T00:00:00.000Z`
    ),
    dateTime: format(new Date(), 'MM/dd/yyyy hh:mm a'),
    name: `${value.firstName} ${value.lastName}`.trim(),
    deviceId: g.reg.browser.userAgent(),
    street: value.street,
    apartmentDetails: value.apartmentDetails,
    city: value.city,
    zip: value.zip,
    signature: value.signature,
    socialSecurityNumber: value.socialSecurityNumber,
    firstName: value.firstName,
    lastName: value.lastName,
    ipAddress
  };

  // Just for good measure!
  const formData = PrequalFormSchema.parse(d);

  onSubmittedFormValues(value, formData);

  const hasExistingUser = await hasExistingUserAction(value.email);

  // If we do have an existing user for an email, the user has to login first
  if (!hasExistingUser) {
    const response = await gqlMutationClient({ dealershipId })({
      submitPrequalApplicationAndCreateUser: [
        {
          dealershipId,
          email: value.email,
          phoneNumber: value.phoneNumber,
          formData: formData
        },
        {
          __typename: true,
          '...on GraphQLError': {
            message: true
          },
          '...on MutationSubmitPrequalApplicationAndCreateUserSuccess': {
            data: {
              status: true
            }
          }
        }
      ]
    });

    if (
      response.submitPrequalApplicationAndCreateUser?.__typename ===
      'MutationSubmitPrequalApplicationAndCreateUserSuccess'
    ) {
      onSubmittedPrequal();
    } else {
      throw Error(
        response.submitPrequalApplicationAndCreateUser?.message ??
          'An unexpected error happend when submitting the Prequal'
      );
    }
  } else {
    if (config.useCognito) {
      await cognitoRequestOtpAction(value.email);
    } else {
      await requestOtpExistingUserDEVAction(value.email);
    }

    onRequestedOtp();
  }
};

export default submitWebPreQualApplicationWorkflow;
