import { captureException } from '@sentry/react';
import { useForm } from '@tanstack/react-form';
import { useQuery } from '@tanstack/react-query';
import { useStore } from '@tanstack/react-store';
import { zodValidator, ZodValidator } from '@tanstack/zod-form-adapter';
import { Button, ProgressBar, Spinner } from '@thedealersconcierge/components';
import classNames from 'classnames';
import { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import createEmploymentAction from '~/actions/employments/createEmploymentAction';
import updateEmploymentAction from '~/actions/employments/updateEmploymentAction';
import EmploymentForm, {
  EmploymentFormValues,
  ValidEmploymentSchema
} from '~/components/forms/EmploymentForm';
import Header from '~/components/Header';
import customerQuery, {
  CustomerQueryType,
  resetCustomerQuery
} from '~/queries/customerQuery';
import meQuery from '~/queries/meQuery';
import { useNavigate, useParams } from '~/router';

const Forms: FC<{
  transactionId: string;
  userId: string;
  dealershipSlug: string;
  existingData?: CustomerQueryType | undefined;
  isModification: boolean;
}> = ({
  transactionId,
  userId,
  dealershipSlug,
  existingData,
  isModification
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const existingCustomerData = existingData?.customer;
  // We might have an existing current employment when navigating back and forth
  const existingCurrentEmployment =
    existingCustomerData?.employments?.edges?.find(
      (e) => e.node?.timelinePosition === 0
    )?.node;
  // We might have an existing previous employment when navigating back and forth
  const existingPreviousEmployment =
    existingCustomerData?.employments?.edges?.find(
      (e) => e.node?.timelinePosition === 1
    )?.node;
  const currentEmploymentForm = useForm<EmploymentFormValues, ZodValidator>({
    defaultValues: {
      employmentStatus: existingCurrentEmployment?.employmentStatus ?? '',
      employerName: existingCurrentEmployment?.employerName ?? '',
      employerPhoneNumber: existingCurrentEmployment?.employerPhoneNumber ?? '',
      occupation: existingCurrentEmployment?.occupation ?? '',
      durationYears: existingCurrentEmployment?.durationYears?.toString() ?? '',
      durationMonths:
        existingCurrentEmployment?.durationMonths?.toString() ?? '',
      incomeAmount: existingCurrentEmployment?.incomeAmount?.toString() ?? '',
      incomeInterval: existingCurrentEmployment?.incomeInterval ?? '',
      otherIncomeSource: existingCurrentEmployment?.otherIncomeSource ?? '',
      otherIncomeAmount:
        existingCurrentEmployment?.otherIncomeAmount?.toString() ?? '',
      otherIncomeInterval: existingCurrentEmployment?.otherIncomeInterval ?? ''
    },
    validators: {
      onSubmit: ValidEmploymentSchema(t, 'CURRENT')
    },
    validatorAdapter: zodValidator(),
    onSubmit: async ({ value }) => {
      // We have to check the conditionally required employer fields when the employment status is 'employed' or 'self_employed'
      if (['EMPLOYED', 'SELF_EMPLOYED'].includes(value.employmentStatus)) {
        if (value.employerName === '') {
          // Did not find a way to make it work with zod validation as this is conditionally required
          toast.error(t('The employer name is required'));

          throw Error('FORM_VALIDATION_ERROR');
        }

        if (value.employerPhoneNumber === '') {
          // Did not find a way to make it work with zod validation as this is conditionally required
          toast.error(t('The employer phone number is required'));

          throw Error('FORM_VALIDATION_ERROR');
        }

        if (value.occupation === '') {
          // Did not find a way to make it work with zod validation as this is conditionally required
          toast.error(t('The role is required'));

          throw Error('FORM_VALIDATION_ERROR');
        }
      }

      const employmentValues = {
        employmentStatus: value.employmentStatus,
        employerName: value.employerName,
        employerPhoneNumber:
          value.employerPhoneNumber.length > 0
            ? value.employerPhoneNumber
            : undefined,
        occupation: value.occupation,
        durationYears:
          value.durationYears.length > 0
            ? parseInt(value.durationYears)
            : undefined,
        durationMonths:
          value.durationMonths.length > 0
            ? parseInt(value.durationMonths)
            : undefined,
        incomeAmount:
          value.incomeAmount.length > 0
            ? parseFloat(value.incomeAmount)
            : undefined,
        incomeInterval:
          value.incomeInterval.length > 0 ? value.incomeInterval : undefined,
        otherIncomeSource: value.otherIncomeSource,
        otherIncomeAmount:
          value.otherIncomeAmount.length > 0
            ? parseFloat(value.otherIncomeAmount)
            : undefined,
        otherIncomeInterval:
          value.otherIncomeInterval.length > 0
            ? value.otherIncomeInterval
            : undefined
      };

      if (existingCurrentEmployment?.id) {
        await updateEmploymentAction(
          existingCurrentEmployment.id,
          { dealershipSlug },
          employmentValues
        );
      } else {
        await createEmploymentAction(
          transactionId,
          { dealershipSlug },
          {
            ...employmentValues,
            timelinePosition: 0
          }
        );
      }
    }
  });
  const atLeastTwoYearsOfEmploymentHistory = useStore(
    currentEmploymentForm.store,
    ({ values }) => {
      return (
        parseInt(values.durationYears ? values.durationYears : '0') * 12 +
          parseInt(values.durationMonths ? values.durationMonths : '0') >=
        24
      );
    }
  );
  const previousEmploymentForm = useForm<EmploymentFormValues, ZodValidator>({
    defaultValues: {
      employmentStatus: existingPreviousEmployment?.employmentStatus ?? '',
      employerName: existingPreviousEmployment?.employerName ?? '',
      employerPhoneNumber:
        existingPreviousEmployment?.employerPhoneNumber ?? '',
      occupation: existingPreviousEmployment?.occupation ?? '',
      durationYears:
        existingPreviousEmployment?.durationYears?.toString() ?? '',
      durationMonths:
        existingPreviousEmployment?.durationMonths?.toString() ?? '',
      incomeAmount: existingPreviousEmployment?.incomeAmount?.toString() ?? '',
      incomeInterval: existingPreviousEmployment?.incomeInterval ?? '',
      otherIncomeSource: existingPreviousEmployment?.otherIncomeSource ?? '',
      otherIncomeAmount:
        existingPreviousEmployment?.otherIncomeAmount?.toString() ?? '',
      otherIncomeInterval: existingPreviousEmployment?.otherIncomeInterval ?? ''
    },
    validators: {
      onSubmit: ValidEmploymentSchema(t, 'PREVIOUS')
    },
    validatorAdapter: zodValidator(),
    onSubmit: async ({ value }) => {
      // We only update or create the previous employment if it's required.
      // Also, the previous employment form is only shown if the customer has worked less than 2 years at their current employment.
      if (atLeastTwoYearsOfEmploymentHistory) {
        return;
      }

      // We have to check the conditionally required employer fields when the employment status is 'employed' or 'self_employed'
      if (['EMPLOYED', 'SELF_EMPLOYED'].includes(value.employmentStatus)) {
        if (value.employerName === '') {
          // Did not find a way to make it work with zod validation as this is conditionally required
          toast.error(t('The employer name is required'));

          throw Error('FORM_VALIDATION_ERROR');
        }

        if (value.employerPhoneNumber === '') {
          // Did not find a way to make it work with zod validation as this is conditionally required
          toast.error(t('The employer phone number is required'));

          throw Error('FORM_VALIDATION_ERROR');
        }

        if (value.occupation === '') {
          // Did not find a way to make it work with zod validation as this is conditionally required
          toast.error(t('The occupation is required'));

          throw Error('FORM_VALIDATION_ERROR');
        }
      }

      const employmentValues = {
        employmentStatus: value.employmentStatus,
        employerName: value.employerName,
        employerPhoneNumber:
          value.employerPhoneNumber.length > 0
            ? value.employerPhoneNumber
            : undefined,
        occupation: value.occupation,
        durationYears:
          value.durationYears.length > 0
            ? parseInt(value.durationYears)
            : undefined,
        durationMonths:
          value.durationMonths.length > 0
            ? parseInt(value.durationMonths)
            : undefined,
        incomeAmount:
          value.incomeAmount.length > 0
            ? parseFloat(value.incomeAmount)
            : undefined,
        incomeInterval:
          value.incomeInterval.length > 0 ? value.incomeInterval : undefined,
        otherIncomeSource: value.otherIncomeSource,
        otherIncomeAmount:
          value.otherIncomeAmount.length > 0
            ? parseFloat(value.otherIncomeAmount)
            : undefined,
        otherIncomeInterval:
          value.otherIncomeInterval.length > 0
            ? value.otherIncomeInterval
            : undefined
      };

      if (existingPreviousEmployment?.id) {
        await updateEmploymentAction(
          existingPreviousEmployment.id,
          { dealershipSlug },
          employmentValues
        );
      } else {
        await createEmploymentAction(
          transactionId,
          { dealershipSlug },
          {
            ...employmentValues,
            timelinePosition: 1
          }
        );
      }
    }
  });
  const hasFilledOutCurrentEmployment = useStore(
    currentEmploymentForm.store,
    ({ values, isValid }) => {
      return (
        isValid &&
        (Boolean(values.durationMonths) || Boolean(values.durationYears))
      );
    }
  );
  const handleGoToNext = async () => {
    try {
      setIsSubmitting(true);

      await Promise.all([
        currentEmploymentForm.handleSubmit(),
        previousEmploymentForm.handleSubmit()
      ]);

      // The forms are validated upon submission
      // So if they are valid, we know we can proceed
      if (
        currentEmploymentForm.state.isValid &&
        (atLeastTwoYearsOfEmploymentHistory ||
          previousEmploymentForm.state.isValid)
      ) {
        await resetCustomerQuery(transactionId, userId);
        navigate(
          {
            pathname:
              '/dashboard/:dealershipSlug/:transactionId/credit-application/acknowledgements',
            search: isModification ? 'modification=true' : ''
          },
          {
            params: {
              dealershipSlug,
              transactionId
            }
          }
        );
      } else if (!atLeastTwoYearsOfEmploymentHistory) {
        toast.error(
          t('At least two years of employment history are required.')
        );
      }
    } catch (error) {
      if (error instanceof Error && error.message === 'FORM_VALIDATION_ERROR') {
        return;
      }

      captureException(error);
      console.error(error);
      toast.error(t('An unexpected error happened'));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Fragment>
      <EmploymentForm
        form={currentEmploymentForm}
        isSubmitting={isSubmitting}
        type="CURRENT"
        dataTestId="credit-application-current-employment-form"
      />

      <div
        className={classNames(
          'col-span-4 tablet:col-span-6 desktop:col-span-8 tablet:col-start-2 desktop:col-start-3', // Parent grid layout
          'flex flex-col space-y-spacing-01',
          {
            hidden:
              !hasFilledOutCurrentEmployment ||
              atLeastTwoYearsOfEmploymentHistory
          }
        )}
      >
        <h2 className="heading-emphasized-02">{t('Previous Employment')}</h2>

        <p>
          {t('Including the last two years of your employment is required.')}
        </p>
      </div>

      <EmploymentForm
        form={previousEmploymentForm}
        isSubmitting={isSubmitting}
        type="PREVIOUS"
        className={classNames({
          hidden:
            !hasFilledOutCurrentEmployment || atLeastTwoYearsOfEmploymentHistory
        })}
      />

      <div
        className={classNames(
          'col-span-4 tablet:col-span-6 desktop:col-span-8 tablet:col-start-2 desktop:col-start-3', // Parent grid layout
          'flex flex-row justify-end pt-spacing-04'
        )}
      >
        <Button
          label={t('Next')}
          isLoading={isSubmitting}
          onClick={() => void handleGoToNext()}
          dataTestId="credit-application-employment-next-button"
        />
      </div>
    </Fragment>
  );
};

const EmploymentInformation = () => {
  const { t } = useTranslation();
  const searchParams = new URLSearchParams(location.search);
  const isModification = searchParams.get('modification') === 'true';
  const navigate = useNavigate();
  const { dealershipSlug, transactionId } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/credit-application/employment'
  );
  const { data: meData } = useQuery(meQuery());
  const userId = meData?.me?.user?.id ?? undefined;
  const { data: customerData, isFetched: fetchedCustomerData } = useQuery(
    customerQuery(transactionId, userId, dealershipSlug)
  );
  const handleGoBack = () => {
    navigate(
      {
        pathname:
          '/dashboard/:dealershipSlug/:transactionId/credit-application/address',
        search: isModification ? 'modification=true' : ''
      },
      {
        params: {
          dealershipSlug,
          transactionId
        }
      }
    );
  };
  const handleCancel = () => {
    navigate('/dashboard/:dealershipSlug/:transactionId', {
      params: {
        dealershipSlug,
        transactionId
      }
    });
  };

  return (
    <div className="relative flex flex-col items-center bg-primary h-full max-h-screen overflow-y-scroll">
      <Header
        title={
          isModification
            ? t('Credit Application Modification')
            : t('Credit Application')
        }
        className="w-full"
        leftElement={
          <Button
            label={t('Back')}
            variant="GHOST"
            size="LARGE"
            onClick={handleGoBack}
          />
        }
        rightElement={
          <Button
            label={t('Cancel')}
            variant="GHOST"
            size="LARGE"
            onClick={handleCancel}
          />
        }
      />

      <div
        className={classNames(
          'mobile-screen-grid tablet:tablet-screen-grid desktop:desktop-screen-grid', // Grid layout
          'size-full space-y-spacing-05 tablet:space-y-spacing-06 py-spacing-05 tablet:py-spacing-06'
        )}
      >
        <div
          className={classNames(
            'col-span-4 tablet:col-span-8 desktop:col-span-10 desktop:col-start-2', // Parent grid layout
            'w-full relative flex flex-row justify-center'
          )}
        >
          <ProgressBar totalSteps={3} currentStep={2} />
        </div>

        {userId && fetchedCustomerData && (
          <Forms
            transactionId={transactionId}
            userId={userId}
            dealershipSlug={dealershipSlug}
            existingData={customerData}
            isModification={isModification}
          />
        )}

        {!fetchedCustomerData && (
          <div
            className={classNames(
              'col-span-4 tablet:col-span-8 desktop:col-span-10 desktop:col-start-2', // Parent grid layout
              'w-full h-96 relative flex flex-row justify-center items-center'
            )}
          >
            <Spinner color="GREY" size="LARGE" />
          </div>
        )}
      </div>
    </div>
  );
};

export default EmploymentInformation;
