import { z } from "zod";
import { FormBuilderFormFieldPlacementSchema } from "./formFieldPlacement";

export const FormFieldType = z.union([
  z.literal("TEXT"),
  z.literal("CHARACTER_LIST"),

  z.literal("SIGNATURE"),
  z.literal("IMAGE"),

  // Individual Checkbox
  z.literal("CHECKBOX"),
]);
export type FormFieldType = z.infer<typeof FormFieldType>;

export const TransactionRoleSchema = z.union([
  z.literal("CO_BUYER"),
  z.literal("BUYER"),
  z.literal("SALES_PERSON"),
]);
export type TransactionRoleSchema = z.infer<typeof TransactionRoleSchema>;

export const FormBuilderFormFieldValidationSchema = z.object({
  formFieldId: z.string(),
  createdAt: z.string(),
  formId: z.string().uuid(),
  type: FormFieldType,

  prefillPath: z.string().nullish(),
  fixedValue: z.string().nullish(),
  enteredBy: TransactionRoleSchema.nullish(),
  demoData: z.string().nullish(),
  checkboxGroupId: z.string().nullish(),
  characterListLength: z.number().int().nullish(),

  formFieldPlacements: z.array(FormBuilderFormFieldPlacementSchema).nullish(),
});

export type FormBuilderFormFieldValidationSchema = z.TypeOf<
  typeof FormBuilderFormFieldValidationSchema
>;

export const RuleTypes = z.enum(["EXACTLY_N"]);

// See CheckBoxGroup in the prisma schema for more information
export const CheckboxRuleValidationSchema = z.object({
  id: z.string(),
  label: z.string(),
  rule: RuleTypes,
  n: z.number(),
});
export type CheckboxRuleValidationSchema = z.TypeOf<
  typeof CheckboxRuleValidationSchema
>;

export const FormBuilderStateValidationSchema = z.object({
  fields: FormBuilderFormFieldValidationSchema.array(),
  checkBoxRules: CheckboxRuleValidationSchema.array(),
});

export type FormBuilderStateValidationSchema = z.TypeOf<
  typeof FormBuilderStateValidationSchema
>;

/**
 * FORM BUILDER MESSAGES
 *
 * These are the messages we can send to the backend
 */
export const FormBuilderMessageValidationSchema = z.object({
  type: z.literal("UPDATE_FORMS"),
  payload: FormBuilderStateValidationSchema,
});
export type FormBuilderMessageValidationSchema = z.infer<
  typeof FormBuilderMessageValidationSchema
>;
