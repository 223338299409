import { ReactFormApi } from '@tanstack/react-form';
import { ZodValidator } from '@tanstack/zod-form-adapter';
import {
  Button,
  Checkbox,
  DateInput,
  PhoneNumberInput,
  Select,
  SignatureInput,
  SocialSecurityNumberInput,
  TextInput
} from '@thedealersconcierge/components';
import { inputBackgroundTypes } from '@thedealersconcierge/components/src/input/inputConfig';
import { phoneNumber } from '@thedealersconcierge/lib/codecs/validation/phonenumber';
import { socialSecurityNumber } from '@thedealersconcierge/lib/codecs/validation/socialSecurityNumber';
import { getFormFieldErrorMsg } from '@thedealersconcierge/lib/utils/forms';
import classNames from 'classnames';
import { TFunction } from 'i18next';
import { FC, Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { z } from 'zod';
import config from '~/config';
import stateOptions from '~/config/formSelectionOptions/stateOptions';
import FormContainer from './FormContainer';

export type PrequalFormValues = {
  firstName: string;
  middleName: string;
  lastName: string;
  socialSecurityNumber: string;
  birthdate: string;
  street: string;
  apartmentDetails: string;
  city: string;
  state: string;
  zip: string;
  phoneNumber: string;
  email: string;
  confirmNotAffectCreditScoreAndPrequalConsented: boolean;
  acknowledgesElectronicConsent: boolean;
  hasCommunicationsConsented: boolean;
  signature: string;
};

export const ValidPrequalFormSchema = (t: TFunction) =>
  z.object({
    firstName: z.string().min(1, t('This field is required')),
    middleName: z.string(),
    lastName: z.string().min(1, t('This field is required')),
    socialSecurityNumber: socialSecurityNumber(),
    birthdate: z
      .string()
      .datetime(t('This field is required')) // The date picker selects values as DateTime strings
      .or(z.string().date('This field is required')), // The API returns data as Date strings
    street: z.string().min(1, t('This field is required')),
    apartmentDetails: z.string(),
    city: z.string().min(1, t('This field is required')),
    state: z.string().min(1, t('This field is required')),
    zip: z.string().min(1, t('This field is required')),
    phoneNumber: phoneNumber(),
    email: z.string().email(),
    confirmNotAffectCreditScoreAndPrequalConsented: z.literal(true),
    acknowledgesElectronicConsent: z.literal(true),
    hasCommunicationsConsented: z.literal(true),
    signature: z.string().min(1, t('This field is required'))
  });

const PrequalForm: FC<{
  environment: 'IN_APP' | 'WEB';
  form: ReactFormApi<PrequalFormValues, ZodValidator>;
  isSubmitting: boolean;
  dealershipSlug: string;
  dealershipName: string;
  onSubmit: () => void;
  dataTestId: string;
  className?: string;
}> = ({
  environment,
  form,
  isSubmitting,
  dealershipSlug,
  dealershipName,
  onSubmit,
  dataTestId,
  className
}) => {
  const { t, i18n } = useTranslation();

  return (
    <div
      className={classNames(
        'mobile-screen-grid tablet:tablet-screen-grid desktop:desktop-screen-grid', // Grid layout
        'size-full space-y-spacing-05 tablet:space-y-spacing-06 py-spacing-05 tablet:py-spacing-06'
      )}
    >
      <div
        className={classNames(
          'col-span-4 tablet:col-span-6 desktop:col-span-8 tablet:col-start-2 desktop:col-start-3', // Parent grid
          'space-y-spacing-04'
        )}
      >
        <h2 className="heading-emphasized-02">
          {t('Pre-Qualification Application')}
        </h2>

        {environment === 'IN_APP' && (
          <div className="space-y-spacing-02">
            <p className="text-secondary">
              {t(
                'Once this form has been filled out, you will not be able to edit it. Please read the information below:'
              )}
            </p>

            <p className="text-secondary">
              {t(
                'This next step allows the dealer to view your credit score and accurately check what programs are available for your purchase.'
              )}
            </p>

            <p className="text-secondary">
              {t(
                'Note: This action will not affect your credit score and is not considered a Hard Inquiry on your credit file.'
              )}
            </p>
          </div>
        )}
      </div>

      <FormContainer title={t('Personal Information')} className={className}>
        <Fragment>
          <form.Field
            name="firstName"
            validators={{
              onBlur: z.string().min(1, t('This field is required'))
            }}
          >
            {(field) => {
              return (
                <TextInput
                  value={field.state.value}
                  label={t('First Name')}
                  placeholder={t('First Name')}
                  autoCapitalize="words"
                  required
                  disabled={isSubmitting}
                  onChange={field.handleChange}
                  onBlur={field.handleBlur}
                  errorMessage={getFormFieldErrorMsg(field)}
                  backgroundType={inputBackgroundTypes.LIGHT}
                  className="col-span-2 desktop:col-span-3"
                  dataTestId={`${dataTestId}-first-name`}
                />
              );
            }}
          </form.Field>

          <form.Field
            name="middleName"
            validators={{
              onBlur: z.string()
            }}
          >
            {(field) => {
              return (
                <TextInput
                  value={field.state.value}
                  label={t('Middle Name')}
                  placeholder={t('Middle Name')}
                  autoCapitalize="words"
                  required={false}
                  disabled={isSubmitting}
                  onChange={field.handleChange}
                  onBlur={field.handleBlur}
                  errorMessage={getFormFieldErrorMsg(field)}
                  backgroundType={inputBackgroundTypes.LIGHT}
                  className="col-span-2"
                  dataTestId={`${dataTestId}-middle-name`}
                />
              );
            }}
          </form.Field>

          <form.Field
            name="lastName"
            validators={{
              onBlur: z.string().min(1, t('This field is required'))
            }}
          >
            {(field) => {
              return (
                <TextInput
                  value={field.state.value}
                  label={t('Last Name')}
                  placeholder={t('Last Name')}
                  autoCapitalize="words"
                  required
                  disabled={isSubmitting}
                  onChange={field.handleChange}
                  onBlur={field.handleBlur}
                  errorMessage={getFormFieldErrorMsg(field)}
                  backgroundType={inputBackgroundTypes.LIGHT}
                  className="col-span-4 tablet:col-span-2 desktop:col-span-3"
                  dataTestId={`${dataTestId}-last-name`}
                />
              );
            }}
          </form.Field>

          <form.Field
            name="socialSecurityNumber"
            validators={{
              onBlur: socialSecurityNumber()
            }}
          >
            {(field) => {
              return (
                <SocialSecurityNumberInput
                  value={field.state.value}
                  label={t('Social Security Number/TIN')}
                  placeholder={t('Social Security Number/TIN')}
                  required
                  disabled={isSubmitting}
                  onChange={field.handleChange}
                  onBlur={field.handleBlur}
                  errorMessage={getFormFieldErrorMsg(field)}
                  backgroundType={inputBackgroundTypes.LIGHT}
                  className="col-span-4 tablet:col-span-3 desktop:col-span-4"
                  dataTestId={`${dataTestId}-ssn`}
                />
              );
            }}
          </form.Field>

          <form.Field
            name="birthdate"
            validators={{
              onBlur: z
                .string()
                .datetime(t('This field is required')) // The date picker selects values as DateTime strings
                .or(z.string().date('This field is required')), // The API returns data as Date strings
              // We do the onChange validation because the calendar pop-up stays open when a value is selected
              onChange: z
                .string()
                .datetime(t('This field is required')) // The date picker selects values as DateTime strings
                .or(z.string().date('This field is required')) // The API returns data as Date strings
            }}
          >
            {(field) => {
              return (
                <DateInput
                  value={field.state.value}
                  label={t('Date of Birth')}
                  placeholder={t('Date of Birth')}
                  required
                  disabled={isSubmitting}
                  onChange={field.handleChange}
                  onBlur={field.handleBlur}
                  errorMessage={getFormFieldErrorMsg(field)}
                  backgroundType={inputBackgroundTypes.LIGHT}
                  className="col-span-4 tablet:col-span-3 desktop:col-span-4"
                  dataTestId={`${dataTestId}-birthdate`}
                />
              );
            }}
          </form.Field>

          <form.Field
            name="street"
            validators={{
              onBlur: z.string().min(1, t('This field is required'))
            }}
          >
            {(field) => {
              return (
                <TextInput
                  value={field.state.value}
                  label={t('Street Address')}
                  placeholder={t('Street Address')}
                  autoCapitalize="words"
                  required
                  disabled={isSubmitting}
                  onChange={field.handleChange}
                  onBlur={field.handleBlur}
                  errorMessage={getFormFieldErrorMsg(field)}
                  backgroundType={inputBackgroundTypes.LIGHT}
                  className="col-span-4 desktop:col-span-5"
                  dataTestId={`${dataTestId}-street`}
                />
              );
            }}
          </form.Field>

          <form.Field
            name="apartmentDetails"
            validators={{
              onBlur: z.string()
            }}
          >
            {(field) => {
              return (
                <TextInput
                  value={field.state.value}
                  label={t('Apartment Details')}
                  placeholder={t('Apartment Details')}
                  required={false}
                  disabled={isSubmitting}
                  onChange={field.handleChange}
                  onBlur={field.handleBlur}
                  errorMessage={getFormFieldErrorMsg(field)}
                  backgroundType={inputBackgroundTypes.LIGHT}
                  className="col-span-4 tablet:col-span-2 desktop:col-span-3"
                  dataTestId={`${dataTestId}-apartment-details`}
                />
              );
            }}
          </form.Field>

          <form.Field
            name="city"
            validators={{
              onBlur: z.string().min(1, t('This field is required'))
            }}
          >
            {(field) => {
              return (
                <TextInput
                  value={field.state.value}
                  label={t('City')}
                  placeholder={t('City')}
                  autoCapitalize="words"
                  required
                  disabled={isSubmitting}
                  onChange={field.handleChange}
                  onBlur={field.handleBlur}
                  errorMessage={getFormFieldErrorMsg(field)}
                  backgroundType={inputBackgroundTypes.LIGHT}
                  className="col-span-4 tablet:col-span-2 desktop:col-span-3"
                  dataTestId={`${dataTestId}-city`}
                />
              );
            }}
          </form.Field>

          <form.Field
            name="state"
            validators={{
              onBlur: z.string().min(1, t('This field is required'))
            }}
          >
            {(field) => {
              return (
                <Select
                  value={field.state.value}
                  label={t('State')}
                  placeholder={t('State')}
                  required
                  disabled={isSubmitting}
                  onSelect={(option) => {
                    field.handleChange(option.value);
                  }}
                  onBlur={field.handleBlur}
                  errorMessage={getFormFieldErrorMsg(field)}
                  backgroundType={inputBackgroundTypes.LIGHT}
                  className="col-span-4 tablet:col-span-2"
                  options={stateOptions}
                  dataTestId={`${dataTestId}-state`}
                />
              );
            }}
          </form.Field>

          <form.Field
            name="zip"
            validators={{
              onBlur: z.string().min(1, t('This field is required'))
            }}
          >
            {(field) => {
              return (
                <TextInput
                  value={field.state.value}
                  label={t('ZIP Code')}
                  placeholder={t('ZIP Code')}
                  required
                  disabled={isSubmitting}
                  onChange={field.handleChange}
                  onBlur={field.handleBlur}
                  errorMessage={getFormFieldErrorMsg(field)}
                  backgroundType={inputBackgroundTypes.LIGHT}
                  className="col-span-4 tablet:col-span-2 desktop:col-span-3"
                  dataTestId={`${dataTestId}-zip`}
                />
              );
            }}
          </form.Field>

          <form.Field
            name="phoneNumber"
            validators={{
              onBlur: phoneNumber()
            }}
          >
            {(field) => {
              return (
                <PhoneNumberInput
                  value={field.state.value}
                  label={t('Phone Number')}
                  placeholder={t('Phone Number')}
                  required
                  disabled={isSubmitting || environment === 'IN_APP'}
                  onChange={field.handleChange}
                  onBlur={field.handleBlur}
                  errorMessage={getFormFieldErrorMsg(field)}
                  backgroundType={inputBackgroundTypes.LIGHT}
                  className="col-span-4 tablet:col-span-3 desktop:col-span-4"
                  dataTestId={`${dataTestId}-phone-number`}
                />
              );
            }}
          </form.Field>

          <form.Field
            name="email"
            validators={{
              onBlur: z.string().email()
            }}
          >
            {(field) => {
              return (
                <TextInput
                  value={field.state.value}
                  inputType="email"
                  label={t('Email')}
                  placeholder={t('Email')}
                  required
                  disabled={isSubmitting || environment === 'IN_APP'}
                  onChange={field.handleChange}
                  onBlur={field.handleBlur}
                  errorMessage={getFormFieldErrorMsg(field)}
                  backgroundType={inputBackgroundTypes.LIGHT}
                  className="col-span-4 tablet:col-span-3 desktop:col-span-4"
                  dataTestId={`${dataTestId}-email`}
                />
              );
            }}
          </form.Field>
        </Fragment>
      </FormContainer>

      <div
        className={classNames(
          'col-span-4 tablet:col-span-6 desktop:col-span-8 tablet:col-start-2 desktop:col-start-3', // Parent grid
          'space-y-spacing-04'
        )}
      >
        <h3>{t('Disclosures and Consents')}</h3>

        <div className="space-y-spacing-02">
          <form.Field name="confirmNotAffectCreditScoreAndPrequalConsented">
            {(field) => {
              return (
                <div
                  className="flex flex-row space-x-spacing-02 cursor-pointer"
                  onClick={() => {
                    field.handleChange(!field.state.value);
                  }}
                  data-test-id={`${dataTestId}-confirm-not-affect-credit-score`}
                >
                  <Checkbox
                    checked={field.state.value}
                    onChange={() => {}} // The whole div is clickable
                    disabled={isSubmitting}
                    size="LARGE"
                  />

                  <p className="body-01">
                    <Trans t={t} values={{ dealershipName }}>
                      I understand that this is Not an Application for Credit
                      and will not affect my credit score, and I hereby consent
                      to have my credit file accessed for the purposes of
                      prequalifying for a vehicle loan through a soft inquiry,
                      which will not impact my credit score, while agreeing to
                      the{' '}
                      <a
                        target="_blank"
                        className="text-blue-500 hover:underline"
                        href={t('privacy-notice', {
                          backendUrl: config.rawBackendUrl,
                          dealershipSlug: dealershipSlug,
                          lang: i18n.language,
                          ns: 'files',
                          defaultValue:
                            '{{backendUrl}}/document/{{dealershipSlug}}/{{lang}}/privacy-notice.pdf'
                        })}
                      >
                        Privacy Notice
                      </a>
                      , and{' '}
                      <a
                        target="_blank"
                        className="text-blue-500 hover:underline"
                        href="https://files.mytdc.net/prequal-terms-and-conditions.pdf"
                      >
                        Disclosures
                      </a>{' '}
                      and acknowledge that I may be contacted by{' '}
                      {'{{dealershipName}}'}and that I might not prequalify
                      depending on the prequalification criteria.
                    </Trans>
                  </p>
                </div>
              );
            }}
          </form.Field>

          <form.Field name="acknowledgesElectronicConsent">
            {(field) => {
              return (
                <div
                  className="flex flex-row space-x-spacing-02 cursor-pointer"
                  onClick={() => {
                    field.handleChange(!field.state.value);
                  }}
                  data-test-id={`${dataTestId}-acknowledges-electronic-consent`}
                >
                  <Checkbox
                    checked={field.state.value}
                    onChange={() => {}} // The whole div is clickable
                    disabled={isSubmitting}
                    size="LARGE"
                  />

                  <p className="body-01">
                    <Trans t={t}>
                      <b>Electronic Consent and Acknowledgment</b>
                      <br />I hereby consent and acknowledge that I will receive
                      all required disclosures electronically via the TDC
                      platform. I understand that access to these disclosures
                      will be available through the TDC Buyer Portal at{' '}
                      <a
                        target="_blank"
                        className="text-blue-500 hover:underline"
                        href="https://app.mytdc.net/signin"
                      >
                        https://app.mytdc.net/signin
                      </a>
                      . By providing my consent, I agree to review and retain
                      the provided electronic disclosures as necessary.
                    </Trans>
                  </p>
                </div>
              );
            }}
          </form.Field>

          <form.Field name="hasCommunicationsConsented">
            {(field) => {
              return (
                <div
                  className="flex flex-row space-x-spacing-02 cursor-pointer"
                  onClick={() => {
                    field.handleChange(!field.state.value);
                  }}
                  data-test-id={`${dataTestId}-communication-consent`}
                >
                  <Checkbox
                    checked={field.state.value}
                    onChange={() => {}} // The whole div is clickable
                    disabled={isSubmitting}
                    size="LARGE"
                  />

                  <p className="body-01">
                    <Trans t={t}>
                      <b>Contact Consent</b>
                      <br />
                      Having provided my phone number, I consent to receive
                      texts and emails from this dealership. I can opt out
                      anytime. My consent isn't required for purchase, and I
                      accept any messaging and any data charges that may apply
                      by my service provider.
                    </Trans>
                  </p>
                </div>
              );
            }}
          </form.Field>
        </div>

        <h3>{t('Our Commitment to Privacy')}</h3>

        <p className="body-01">
          <Trans t={t}>
            All information stored in our database is secure and is strictly
            confidential. Your personal and credit information will only be used
            to fulfill your request in accordance with our{' '}
            <a
              className="text-blue-500 hover:underline"
              href="https://files.mytdc.net/privacy-policy-dealergenix-jan2024.pdf"
              target="_blank"
            >
              Privacy Policy
            </a>
            .
          </Trans>
        </p>
      </div>

      <div
        className={classNames(
          'col-span-4 tablet:col-span-6 desktop:col-span-8 tablet:col-start-2 desktop:col-start-3' // Parent grid
        )}
      >
        <form.Field
          name="signature"
          validators={{
            onChange: z.string().min(1, t('This field is required'))
          }}
        >
          {(field) => {
            return (
              <div
                className={classNames(
                  'flex flex-col w-full space-y-spacing-01',
                  {
                    'opacity-40': isSubmitting
                  }
                )}
              >
                <SignatureInput
                  value={field.state.value}
                  onSubmit={field.handleChange}
                  required
                  disabled={isSubmitting}
                  // This template refers to Prequal and HCA Signature Bounding box
                  template={{
                    width: 150,
                    height: 60
                  }}
                  dataTestIdOpenModal={`${dataTestId}-signature-open-modal`}
                  dataTestIdCanvas={`${dataTestId}-signature-canvas`}
                  dataTestIdSubmit={`${dataTestId}-submit-signature`}
                />
              </div>
            );
          }}
        </form.Field>
      </div>

      <div
        className={classNames(
          'col-span-4 tablet:col-span-6 desktop:col-span-8 tablet:col-start-2 desktop:col-start-3', // Parent grid
          'flex justify-end'
        )}
      >
        <Button
          label={t('Submit')}
          isLoading={isSubmitting}
          onClick={onSubmit}
          dataTestId={`${dataTestId}-submit`}
        />
      </div>
    </div>
  );
};

export default PrequalForm;
