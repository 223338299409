import { Menu } from '@headlessui/react';
import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import Dropdown from '../input/dropdown';
import { DropdownOption } from '../input/dropdown/dropdownConfig';

const SelectBase: FC<{
  options: DropdownOption[];
  value?: string;
  label: string;
  placeholder: string;
  onSelect: (option: DropdownOption) => Promise<void> | void;
  onBlur?: (() => void) | undefined;
  required: boolean;
  disabled: boolean;
  className?: string;
  dataTestId?: string;
  inputElement: (open: boolean) => React.ReactNode;
}> = ({
  options,
  value,
  placeholder,
  onSelect,
  onBlur,
  required,
  disabled,
  className,
  dataTestId,
  inputElement
}) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const selectRef = useRef<HTMLSelectElement | null>(null);

  // Tracks which option is currently highlighted
  const [highlightedValue, setHighlightedValue] = useState<string>(value ?? '');

  // Keep local highlightedValue in sync with incoming prop changes
  useEffect(() => {
    setHighlightedValue(value ?? '');
  }, [value]);

  /**
   * Intercept key presses on the hidden <select>.
   * - ArrowUp / ArrowDown: manually cycle through the options
   * - Enter: select the highlighted option and close the menu
   * - Otherwise: let the native typeahead do its thing
   */
  const handleNavigationKey = (e: React.KeyboardEvent) => {
    const currentValue = selectRef.current?.value ?? '';
    const currentIndex = options.findIndex((opt) => opt.value === currentValue);

    if (e.key.length === 1 && /[a-z0-9]/i.test(e.key)) {
      selectRef.current?.focus();
      return;
    }

    if (['ArrowDown', 'ArrowUp'].includes(e.key)) {
      e.preventDefault();
      e.stopPropagation();

      let nextIndex = currentIndex;
      if (currentIndex < 0) {
        nextIndex = e.key === 'ArrowDown' ? 0 : options.length - 1;
      } else {
        nextIndex =
          e.key === 'ArrowDown'
            ? Math.min(currentIndex + 1, options.length - 1)
            : Math.max(currentIndex - 1, 0);
      }

      const nextOption = options[nextIndex];
      if (nextOption) {
        setHighlightedValue(nextOption.value);
        if (selectRef.current) {
          selectRef.current.value = nextOption.value;
          selectRef.current.focus();
        }
      }
      return;
    }

    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();

      const selected = options.find(
        (opt) => opt.value === selectRef.current?.value
      );
      if (selected) {
        setHighlightedValue(selected.value);
        void onSelect(selected);
      }

      buttonRef.current?.click();
      selectRef.current?.blur();
      onBlur?.();
    }
  };

  return (
    <div className={twMerge('flex', className)}>
      <select
        ref={selectRef}
        value={value}
        onChange={(e) => {
          // This fires when the user types for typeahead or changes selection natively
          const selectedOption = options.find(
            (opt) => opt.value === e.target.value
          );
          if (selectedOption) {
            setHighlightedValue(selectedOption.value);
            void onSelect(selectedOption);
          }
        }}
        onKeyDown={handleNavigationKey}
        data-test-id={dataTestId}
        // see https://tailwindcss.com/docs/display
        // this class hides the element and keeps native functionality running, ideal for this usecase
        className="sr-only"
        disabled={disabled}
        required={required}
        tabIndex={-1} // Prevent tabbing into this element
      >
        <option value="" disabled>
          {placeholder}
        </option>

        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>

      <Menu>
        <Menu.Button
          className="relative outline-none w-full group"
          onBlur={onBlur}
          ref={buttonRef}
          onKeyDownCapture={handleNavigationKey}
          data-test-id={`${dataTestId}-button`}
        >
          {({ open }) => {
            return (
              <Fragment>
                {/* We need to pass down the open value to input element to track it's open state */}
                {inputElement(open)}

                <Menu.Items className="absolute outline-none w-full z-dropdown -mt-spacing-04">
                  <Dropdown
                    options={options}
                    highlightedValue={highlightedValue}
                    onSelect={async (option) => {
                      setHighlightedValue(option.value);
                      await onSelect(option);
                      // Explicitly re-run blur validation
                      onBlur?.();
                    }}
                    className="w-full"
                  />
                </Menu.Items>
              </Fragment>
            );
          }}
        </Menu.Button>
      </Menu>
    </div>
  );
};

export default SelectBase;
