import { GraphQLTypes } from '~/__generated__/backend/zeus';
import { DealershipIdentifier } from '~/globals';
import { gqlMutationClient } from '~/lib/backend';

const updateEmploymentAction = async (
  employmentId: string,
  dealershipIdentifier: DealershipIdentifier,
  values: GraphQLTypes['UpdateEmploymentInput']
) => {
  const resp = await gqlMutationClient(dealershipIdentifier)({
    updateEmployment: [
      {
        id: employmentId,
        employment: values
      },
      {
        __typename: true,
        '...on GraphQLError': {
          message: true
        },
        '...on MutationUpdateEmploymentSuccess': {
          data: { status: true }
        }
      }
    ]
  });

  if (
    !resp.updateEmployment ||
    resp.updateEmployment.__typename === 'GraphQLError'
  ) {
    throw new Error(resp.updateEmployment?.message ?? undefined);
  }
};

export default updateEmploymentAction;
