import { FC, HTMLAttributes, useState } from 'react';
import InputBase from '..';
import { InputBackgroundType } from '../inputConfig';

/**
 * https://backoffice.dev.mytdc.net/storybook/index.html?path=/story/input-textinput--default
 */
const TextInput: FC<{
  /**
   * dataTestId for E2E testing
   */
  dataTestId?: string;

  /**
   * Name of the input
   */
  name?: string;

  /**
   * Value of the input
   */
  value: string;

  /**
   * Label for the input
   */
  label: string;
  inputType?: 'text' | 'email';

  /**
   * Explainer text for the input
   */
  assistiveMessage?: string;
  errorMessage?: string | undefined | null;
  placeholder: string;
  onChange: (value: string) => void;
  onBlur?: (() => void) | undefined;
  required: boolean;
  disabled: boolean;
  className?: string;
  backgroundType?: InputBackgroundType;
  maxLength?: number;
  autoCapitalize?: HTMLAttributes<HTMLInputElement>['autoCapitalize'];
}> = ({
  maxLength,
  dataTestId,
  name,
  value,
  label,
  inputType = 'text',
  assistiveMessage,
  errorMessage,
  placeholder,
  onChange,
  onBlur,
  /**
   * Whether the input is required
   *
   * We don't use default values to make it explicit and avoid bugs
   */
  required,

  /**
   * Whether the input is disabled
   *
   * We don't use default values to make it explicit and avoid bugs
   */
  disabled,
  className,
  backgroundType = 'DARK',
  autoCapitalize
}) => {
  const [isTyping, setIsTyping] = useState(false);

  return (
    <InputBase
      inputComponent={
        <input
          autoCapitalize={autoCapitalize}
          maxLength={maxLength}
          data-test-id={dataTestId}
          name={name}
          type={inputType}
          value={value}
          className="body-01 w-full px-spacing-02 py-[6px] text-primary outline-none placeholder:text-tertiary disabled:text-tertiary bg-transparent"
          placeholder={placeholder}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          onFocus={() => {
            setIsTyping(true);
          }}
          onBlur={() => {
            setIsTyping(false);
            onBlur?.();
          }}
          disabled={disabled}
        />
      }
      label={label}
      assistiveMessage={assistiveMessage}
      required={required}
      disabled={disabled}
      isBeingUpdated={isTyping}
      errorMessage={errorMessage}
      className={className}
      backgroundType={backgroundType}
    />
  );
};

export default TextInput;
