import { captureException } from '@sentry/react';
import { useForm } from '@tanstack/react-form';
import { useQuery } from '@tanstack/react-query';
import { zodValidator, ZodValidator } from '@tanstack/zod-form-adapter';
import { Button, Tooltip } from '@thedealersconcierge/components';
import Typeahead, {
  TypeaheadOption
} from '@thedealersconcierge/components/src/input/typeahead';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { VehicleLifeCycleStage } from '~/__generated__/backend/zeus';
import Header from '~/components/Header';
import Spinner from '~/components/Spinner';
import GenericVehicleForm, {
  GenericVehicleFormType,
  VehicleValidationSchema
} from '~/components/forms/vehicle/GenericVehicleForm';
import ChevronRightIcon from '~/components/icons/ChevronRightIcon';
import { g } from '~/globals';
import { gqlMutationClient } from '~/lib/backend';
import { fetchHomenetVehicleDataByStockNumber } from '~/queries/homenetQuery';
import { resetMeQuery } from '~/queries/meQuery';
import transactionQuery, {
  resetTransactionQuery
} from '~/queries/transactionQuery';
import { SingleHomenetVehicleData } from '~/querySelectors/homenetVehicleData';
import { Link, useNavigate, useParams } from '~/router';

const PurchaseVehiclePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { transactionId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/purchase-vehicle'
  );
  const { data, isLoading } = useQuery(
    transactionQuery(transactionId, dealershipSlug)
  );
  const vehicle = data?.transaction?.vehicle;
  const dealership = data?.transaction?.dealership;

  const [searchStockNumber, setSearchStockNumber] = useState(
    vehicle?.stockNumber
  );

  const handleCancel = () => {
    navigate('/dashboard/:dealershipSlug/:transactionId', {
      params: { transactionId, dealershipSlug }
    });
  };

  const { data: vehicleData, isLoading: isVehicleLoading } = useQuery(
    fetchHomenetVehicleDataByStockNumber(
      dealership?.id ?? undefined,
      searchStockNumber ?? undefined
    )
  );

  const vehicleOptions: TypeaheadOption<SingleHomenetVehicleData>[] =
    vehicleData?.dealership?.homenetVehicles?.edges
      ?.map((node) => {
        const e = node.node;
        return {
          key: e?.stockNumber ?? e?.id ?? 'no-key',
          label: `${e?.stockNumber}`,
          subtext: `${e?.make} ${e?.model} (${e?.year}) - ${e?.color}, ${e?.bodyType}`,
          value: e
        };
      })
      .filter(
        (e): e is TypeaheadOption<SingleHomenetVehicleData> => !!e.value
      ) ?? [];

  const handleSelectStockNr = (
    selected: TypeaheadOption<SingleHomenetVehicleData>
  ) => {
    const stockNumber = selected.value.stockNumber ?? '';

    form.setFieldValue('stockNumber', stockNumber);
    form.setFieldValue('vin', selected.value.vin ?? '');
    form.setFieldValue('make', selected.value.make ?? '');
    form.setFieldValue('model', selected.value.model ?? '');
    form.setFieldValue('year', selected.value.year ?? '');
    form.setFieldValue('color', selected.value.color ?? '');
    form.setFieldValue(
      'lifeCycleStage',
      selected.value.type
        ? selected.value.type.toUpperCase() === 'USED'
          ? VehicleLifeCycleStage.IS_USED
          : VehicleLifeCycleStage.IS_NEW
        : undefined
    );
    form.setFieldValue('mileage', selected.value.mileage ?? '');
    form.setFieldValue('trim', selected.value.trim ?? '');
    form.setFieldValue('condition', undefined); // Condition is not part of the homenet data
    setSearchStockNumber(stockNumber);
  };

  const form = useForm<GenericVehicleFormType, ZodValidator>({
    defaultValues: {
      stockNumber: vehicle?.stockNumber ?? '',
      lifeCycleStage: vehicle?.lifeCycleStage ?? undefined,
      vin: vehicle?.vin ?? '',
      make: vehicle?.make ?? '',
      model: vehicle?.model ?? '',
      year: vehicle?.year ?? '',
      trim: vehicle?.trim ?? '',
      mileage: vehicle?.mileage?.toString() ?? '',
      principalPriorUse: vehicle?.principalPriorUse ?? undefined
    },
    validators: { onSubmit: VehicleValidationSchema(t, 'PURCHASE') },
    validatorAdapter: zodValidator(),
    onSubmit: async (form) => {
      try {
        const values = form.value;

        setIsSubmitting(true);

        // Update when vehicle already exists
        if (vehicle?.id) {
          if (!values.lifeCycleStage) {
            throw Error(
              t('Please specify whether the vehicle is new or used.')
            );
          }

          const response = await gqlMutationClient({ dealershipSlug })({
            customerUpdatePurchaseVehicle: [
              {
                vehicleId: vehicle.id,
                vehicle: {
                  stockNumber: searchStockNumber,
                  lifeCycleStage: values.lifeCycleStage,
                  vin: values.vin,
                  make: values.make,
                  model: values.model,
                  year: values.year,
                  trim: values.trim,
                  mileage: parseFloat(values.mileage), // It is safe to do this since mileage is required
                  principalPriorUse: values.principalPriorUse
                }
              },
              {
                __typename: true,
                '...on GraphQLError': {
                  message: true
                },
                '...on MutationCustomerUpdatePurchaseVehicleSuccess': {
                  data: {
                    status: true
                  }
                }
              }
            ]
          });

          if (
            response.customerUpdatePurchaseVehicle?.__typename ===
            'GraphQLError'
          ) {
            throw new Error(
              response.customerUpdatePurchaseVehicle.message ?? undefined
            );
          }

          await Promise.all([
            resetMeQuery(),
            g.reg.queryClient.resetQueries({
              queryKey: ['transaction', transactionId]
            })
          ]);

          // Notify success & redirect
          toast.success('Successfully updated purchase vehicle', {
            autoClose: 2000 // 2 seconds
          });

          navigate('/dashboard/:dealershipSlug/:transactionId', {
            params: { transactionId, dealershipSlug }
          });
        } else {
          // Create a new Vehicle

          if (!values.lifeCycleStage) {
            throw Error(
              t('Please specify whether the vehicle is new or used.')
            );
          }

          const response = await gqlMutationClient({ dealershipSlug })({
            customerCreatePurchaseVehicle: [
              {
                transactionId: transactionId,
                vehicle: {
                  stockNumber: searchStockNumber,
                  lifeCycleStage: values.lifeCycleStage,
                  vin: values.vin,
                  make: values.make,
                  model: values.model,
                  year: values.year,
                  trim: values.trim,
                  mileage: parseFloat(values.mileage), // It is safe to do this since mileage is required
                  principalPriorUse: values.principalPriorUse
                }
              },
              {
                __typename: true,
                '...on GraphQLError': { message: true },
                '...on MutationCustomerCreatePurchaseVehicleSuccess': {
                  data: { status: true, id: true }
                }
              }
            ]
          });

          if (
            response.customerCreatePurchaseVehicle?.__typename ===
            'MutationCustomerCreatePurchaseVehicleSuccess'
          ) {
            await Promise.all([
              resetMeQuery(),
              resetTransactionQuery(
                transactionId ?? 'should-never-happen',
                dealershipSlug
              )
            ]);

            // Notify success & redirect
            toast.success(t('Successfully added purchase vehicle'), {
              autoClose: 2000 // 2 seconds
            });

            navigate('/dashboard/:dealershipSlug/:transactionId', {
              params: { transactionId, dealershipSlug }
            });
          } else {
            throw new Error(
              response.customerCreatePurchaseVehicle?.message ?? undefined
            );
          }
        }
      } catch (error) {
        captureException(error);
        console.error(error);

        if (error instanceof Error && error.message) {
          toast.error(error.message);
        } else {
          toast.error(
            t(
              'Cannot add purchase vehicle, Please try again or contact support.'
            )
          );
        }
      } finally {
        setIsSubmitting(false);
      }
    }
  });

  return (
    <div className="flex flex-col h-dvh">
      <Header
        title={t('Vehicle for Purchase')}
        leftElement={
          <button
            className=" text-primary-brand"
            onClick={handleCancel}
            disabled={isSubmitting}
          >
            {t('Cancel')}
          </button>
        }
      />

      <div className="flex w-full px-6 justify-center z-30">
        <div className="flex w-full max-w-screen-md self-center py-2">
          <Link
            to="/dashboard/:dealershipSlug/:transactionId"
            params={{ transactionId, dealershipSlug }}
            className="flex w-6"
          >
            <div className="relative">
              <ChevronRightIcon className="w-6 -scale-x-100 icon-tertiary" />
            </div>
          </Link>
        </div>
      </div>

      <div
        className="flex flex-col items-center overflow-y-scroll px-6"
        data-test-id={'vehicle-for-purchase-body'}
      >
        <div className="flex flex-col max-w-screen-md py-6 md:py-10 space-y-8 md:space-y-16 w-full">
          <div className="space-y-5">
            <h2>{t('Vehicle for Purchase')}</h2>

            <p>
              {t(
                'Please fill in or verify the information in the fields below.'
              )}
            </p>
          </div>

          {isLoading && (
            <div className="flex w-full aspect-square justify-center items-center relative">
              <Spinner className="w-10 aspect-square" />
            </div>
          )}

          {!isLoading && (
            <form
              className="space-y-4 md:space-y-6"
              onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                void form.handleSubmit();
              }}
              data-test-id={'vehicle-for-purchase-form'}
            >
              <div className="grid grid-cols-2 gap-6 w-full">
                <div className="col-span-2 w-full">
                  <div className="flex flex-row gap-4 w-full flex-grow">
                    <Typeahead
                      options={vehicleOptions}
                      onSelect={handleSelectStockNr}
                      onSearch={(searchString: string) => {
                        setSearchStockNumber(searchString);
                      }}
                      title={t('Enter Stock Number')}
                      isLoading={isVehicleLoading}
                      selectedItemKey={vehicle?.stockNumber ?? undefined} // To preselect
                      label="Stock Number"
                      placeHolder="E.g: J1232"
                      required={false}
                      assistiveMessage="Input vehicle stock number to start your search."
                      disabled={false}
                    />
                  </div>
                </div>
              </div>

              <GenericVehicleForm
                form={form}
                isSubmitting={isSubmitting}
                vehicleType="PURCHASE"
                dataTestId={`vehicle-for-purchase`}
              />

              <div className="flex flex-row justify-between pt-4">
                <Button
                  variant="SECONDARY"
                  type="button"
                  disabled={isSubmitting}
                  onClick={handleCancel}
                  dataTestId="vehicle-for-purchase-cancel-button"
                  label={t('Cancel')}
                />

                <Tooltip
                  anchor={
                    <div>
                      <Button
                        type="submit"
                        disabled={vehicle?.updatedByDealership || isSubmitting}
                        dataTestId="vehicle-for-purchase-submit-button"
                        label={t('Save')}
                      />
                    </div>
                  }
                  hide={!vehicle?.updatedByDealership}
                  content={
                    <p>
                      {t(
                        'Data has been entered by dealership and cannot be edited'
                      )}
                    </p>
                  }
                />
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default PurchaseVehiclePage;
