import { DealershipIdentifier, g } from '~/globals';

const uploadFileAction = async (
  // This call requires knowing the dealership
  dealership: DealershipIdentifier,
  fileExt: 'pdf' | 'png' | 'jpg',
  mimeType: 'image/png' | 'image/jpg' | 'application/pdf',
  body: BodyInit,
  contentLength?: string
) => {
  const fileUpload = await g.reg.tdcBackendClient.gqlMutationClient(dealership)(
    {
      makeUpload: [
        {
          fileExt,
          mimeType
        },
        {
          __typename: true,
          '...on GraphQLError': { message: true },
          '...on MutationMakeUploadSuccess': {
            data: {
              fileId: true,
              uploadUrl: true,
              file: {
                id: true,
                url: true
              }
            }
          }
        }
      ]
    }
  );

  if (
    !fileUpload.makeUpload ||
    fileUpload.makeUpload.__typename === 'GraphQLError'
  ) {
    throw new Error(fileUpload.makeUpload?.message ?? 'Unexpected error');
  }

  const res = await fetch(
    fileUpload.makeUpload.data.uploadUrl ?? 'should-never-happen',
    {
      method: 'PUT',
      headers: {
        'Content-Type': mimeType,
        ...(contentLength
          ? {
              'Content-Length': contentLength
            }
          : {})
      },
      body
    }
  );

  if (res.status !== 200) {
    throw new Error('File failed to upload, contact admin');
  }

  return fileUpload.makeUpload.data;
};

export default uploadFileAction;
