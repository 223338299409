/**
 * Exporting the config from a separate file to keep React fast-refresh working.
 */

export const spinnerColors = {
  GREY: 'GREY',
  BLUE: 'BLUE'
} as const;

export type SpinnerColor = (typeof spinnerColors)[keyof typeof spinnerColors];

export const spinnerSizes = {
  LARGE: 'LARGE', // For Full page loading
  MEDIUM: 'MEDIUM',
  SMALL: 'SMALL', // For buttons (medium & large), Inline loading
  XSMALL: 'XSMALL' // For buttons (xsmall & small)
} as const;

export type SpinnerSize = (typeof spinnerSizes)[keyof typeof spinnerSizes];
