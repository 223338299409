import { Button } from '@thedealersconcierge/components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { z } from 'zod';
import logoutAction from '~/actions/logoutAction';
import ChevronRightIcon from '~/components/icons/ChevronRightIcon';
import DocumentsIcon from '~/components/icons/DocumentsIcon';
import FileInput from '~/components/inputs/FileInput';
import { gqlMutationClient } from '~/lib/backend';
import { Link, useNavigate, useParams } from '~/router';
import ConsumerDashboardHeader from '../../../_components/ConsumerDashboardHeader';
import { combinePdfFiles } from './_utils';

const AddAdditionalDocumentTypes = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { transactionId, documentType, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/additional-documents/upload/:documentType'
  );

  // TODO: Refactor - we don't use location.state to pass around data
  const documentName = z.string().parse(location.state?.documentName);

  const [loggingOut, setLoggingOut] = useState(false);
  const handleLogout = () => {
    setLoggingOut(true);
    logoutAction();
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState<
    { file: File; pdfIsEncrypted: boolean }[]
  >([]);

  const handleUpload = async () => {
    setIsSubmitting(true);

    // Validation: Nothing to upload
    if (filesToUpload.length === 0) {
      toast.error('Nothing to upload');
      return;
    }

    // Validation invalid encryption
    if (
      filesToUpload.length > 1 &&
      filesToUpload.some((f) => f.pdfIsEncrypted)
    ) {
      toast.error('One of the files are encrypted');
      return;
    }

    // This case potentially is an encrypted file, and we just upload that as a single file
    const fileToUpload =
      filesToUpload.length === 1
        ? await filesToUpload[0].file.arrayBuffer()
        : await combinePdfFiles(filesToUpload.map((f) => f.file));

    // We always upload PDF
    const fileExt = 'pdf';
    const mimeType = 'application/pdf';

    // Provision a file on the backend
    const fileUpload = await gqlMutationClient({ dealershipSlug })({
      makeUpload: [
        {
          mimeType: mimeType,
          fileExt: fileExt
        },
        {
          __typename: true,
          '...on GraphQLError': { message: true },
          '...on MutationMakeUploadSuccess': {
            data: {
              fileId: true,
              uploadUrl: true
            }
          }
        }
      ]
    });

    const ul = fileUpload.makeUpload;

    // Could not provision the file
    if (ul?.__typename === 'GraphQLError') {
      toast.error(ul?.message ?? 'Unexpected error');
      return;
    }

    const data = ul?.data;

    // Mostly for typing
    if (!data?.uploadUrl || !data?.fileId) {
      toast.error('Malformed response');
      return;
    }

    const res = await fetch(data.uploadUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': mimeType
      },
      body: fileToUpload
    });

    if (res.status !== 200) {
      throw new Error('File failed to upload, contact admin');
    }

    await gqlMutationClient({ dealershipSlug })({
      uploadAdditionalDocuments: [
        {
          category: documentType,
          transactionId: transactionId,
          uploadedDocumentId: data.fileId,
          title: documentName,
          pdfIsEncrypted: true
        },
        {
          __typename: true,
          '...on GraphQLError': { message: true },
          '...on MutationUploadAdditionalDocumentsSuccess': {
            data: {
              id: true
            }
          }
        }
      ]
    });

    setIsSubmitting(false);
    navigate(`/dashboard/:dealershipSlug/:transactionId/glove-compartment`, {
      params: { transactionId, dealershipSlug }
    });
  };

  return (
    <div className="flex flex-col h-dvh w-full">
      <ConsumerDashboardHeader
        centerElement={
          <div className="flex flex-row space-x-spacing-02 items-center">
            <div className="relative">
              <DocumentsIcon className="size-4 md:size-5 icon-primary" />
            </div>

            <p className="heading-03 md:heading-emphasized-02 text-center">
              {t('Add Documents')}
            </p>
          </div>
        }
        rightElement={
          <Button
            size="SMALL"
            variant="GHOST"
            onClick={handleLogout}
            isLoading={loggingOut}
            className="!p-0"
            label={t('Logout')}
          />
        }
        showBorder
      />

      <div className="flex flex-col pt-spacing-04 space-y-spacing-05 w-full max-w-screen-md items-center self-center overflow-y-scroll">
        <div className="w-full px-spacing-05">
          <Link
            to="/dashboard/:dealershipSlug/:transactionId/additional-documents"
            params={{ transactionId, dealershipSlug }}
            className="flex w-6"
          >
            <div className="relative">
              <ChevronRightIcon className="w-6 -scale-x-100 icon-tertiary" />
            </div>
          </Link>
        </div>

        <div className="flex flex-col space-y-4 w-full">
          <div className="space-y-2 w-full px-6">
            <h2>{t('Add Additional Documents')}</h2>
            <p>
              {t(
                'Please upload documents or capture them using the device camera.'
              )}
            </p>
          </div>

          <div className="flex flex-col w-full p-6">
            <div>
              <FileInput
                selectedFiles={filesToUpload}
                setSelectedFiles={(e) => {
                  setFilesToUpload(e);
                }}
                uploading={isSubmitting}
              />
            </div>
            <div className="flex flex-row gap-4 mt-7">
              <Button
                disabled={!(filesToUpload.length > 0) || isSubmitting}
                onClick={() => {
                  void handleUpload();
                }}
                isLoading={isSubmitting}
                label={t('Submit')}
                variant="PRIMARY"
              />
              <Button
                variant="SECONDARY"
                onClick={() => {
                  setFilesToUpload([]);
                }}
                disabled={!(filesToUpload.length > 0) || isSubmitting}
                label={t('Clear File')}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddAdditionalDocumentTypes;
