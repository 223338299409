import { AxiosStatic } from 'axios';

/**
 * I don't know if this symbol fits in this file.
 *
 * I rename it is use a sane name across the app instead of "AxiosStatic"
 *
 * Potentially we will change the HttpClient down the road.
 */
export type HttpClient = AxiosStatic;

export interface BrowserInterface {
  userAgent(): string;
}

export class NullBrowserInfo implements BrowserInterface {
  userAgent(): string {
    console.warn('Using a null service - is this intended?');
    return 'no-user-agent';
  }
}

export class BrowserInfo implements BrowserInterface {
  userAgent(): string {
    return window.navigator.userAgent;
  }
}
