import { format } from 'date-fns';
import { TFunction } from 'i18next';
import { g } from '~/globals';
import { gqlMutationClient, gqlQueryClient } from '~/lib/backend';
import { customerSelector } from '~/querySelectors/customer';
import { DealershipType } from '~/querySelectors/dealership';
import generateAndUploadPrequalPdfWorkflow from '../prequal/generateAndUploadPrequalPdfWorkflow';
import { uploadCreditApplicationWorkflow } from './uploadCreditApplicationWorkflow';

const createAndSubmitCreditApplicationWorkflow = async ({
  transactionId,
  userId,
  dealership,
  signatureName,
  signature,
  deviceId,
  ipAddress,
  hasAcceptedAcknowledgements,
  hasAcceptedElectronicDisclosure,
  t
}: {
  transactionId: string;
  userId: string;
  dealership: DealershipType;
  signatureName: string;
  signature: string;
  deviceId: string;
  ipAddress: string;
  hasAcceptedAcknowledgements: boolean;
  hasAcceptedElectronicDisclosure: boolean;
  t: TFunction;
}) => {
  if (!dealership.name || !dealership.id) {
    throw new Error('Dealership name not available');
  }

  // TODO: Do not fetch data like this here - we should already have it cached.
  const customerResp = await gqlQueryClient({ dealershipId: dealership.id })({
    customer: [
      {
        transactionId,
        userId
      },
      customerSelector
    ]
  });
  const customer = customerResp.customer;

  if (!customer) {
    throw new Error('Could not fetch customer data');
  }

  let signedUploadedPrequalDocumentId: string | undefined;

  // We only generate the Prequal if there's none existing yet
  if (!customer.hasPrequalApplication) {
    // This check is for typing
    if (!customer.birthdate) {
      throw new Error('Birthdate not available');
    }

    const currentAddress = customer.residentialAddresses?.edges?.find(
      (e) => e.node?.timelinePosition === 0
    )?.node;

    if (!currentAddress) {
      throw new Error('Current address not available');
    }

    const prequalDocument = await generateAndUploadPrequalPdfWorkflow(
      t,
      {
        state: currentAddress.state ?? 'should-never-happen',
        suffix: '',
        middleName: customer.middleName ?? undefined,
        // The prequal schema works with dates. We have to make sure it's a UTC date
        birthdate: new Date(`${customer.birthdate}T00:00:00.000Z`),
        dateTime: format(new Date(), 'MM/dd/yyyy hh:mm a'),
        name: `${customer.firstName} ${customer.lastName}`.trim(),
        ipAddress,
        deviceId: g.reg.browser.userAgent(),
        street: currentAddress.street ?? 'should-never-happen',
        apartmentDetails: currentAddress.apartmentDetails ?? '',
        city: currentAddress.city ?? 'should-never-happen',
        zip: currentAddress.zipCode ?? 'should-never-happen',
        signature,
        socialSecurityNumber:
          customer.socialSecurityNumber ?? 'should-never-happen',
        firstName: customer.firstName ?? 'should-never-happen',
        lastName: customer.lastName ?? 'should-never-happen'
      },
      dealership.name,
      { dealershipId: dealership.id }
    );

    signedUploadedPrequalDocumentId = prequalDocument?.id ?? undefined;
  }

  const [creditApplicationDocument, formSubmissionData] =
    await uploadCreditApplicationWorkflow({
      customer,
      dealership,
      hasAcceptedAcknowledgements,
      hasAcceptedElectronicDisclosure,
      signature,
      deviceId,
      ipAddress,
      signatureName,
      t
    });

  if (!creditApplicationDocument.fileId) {
    throw new Error('Could not upload credit application document');
  }

  const resp = await gqlMutationClient({ dealershipId: dealership.id })({
    createAndSubmitCreditAndPrequalApplication: [
      {
        transactionId,
        formSubmissionData,
        signedUploadedPrequalDocumentId,
        signedUploadedCreditApplicationDocumentId:
          creditApplicationDocument.fileId
      },
      {
        __typename: true,
        '...on GraphQLError': {
          message: true
        },
        '...on MutationCreateAndSubmitCreditAndPrequalApplicationSuccess': {
          data: {
            status: true
          }
        }
      }
    ]
  });

  if (
    resp.createAndSubmitCreditAndPrequalApplication?.__typename ===
      'GraphQLError' ||
    !resp.createAndSubmitCreditAndPrequalApplication
  ) {
    throw new Error(
      resp.createAndSubmitCreditAndPrequalApplication?.message ||
        'Unexpected Error'
    );
  }
};

export default createAndSubmitCreditApplicationWorkflow;
