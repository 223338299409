import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import { g } from '~/globals';
import { gqlMutationClient } from '~/lib/backend';
import { userPool } from '~/lib/userpool';
import { authStateAtom, cognitoUserAtom } from '~/state/auth';

/**
 * Cognito with custom auth flow has been a REAL PAIN THE ASS!!!!
 *
 * THIS IS A hACK and needs to better integrated
 *
 * @param email
 * @param method
 */
const setMethod = async (email: string, method: 'SMS' | 'EMAIL') => {
  await gqlMutationClient({ dealershiplessAuth: true })({
    setDeliveryMethodForUser: [
      {
        email,
        method
      },
      {
        '...on MutationSetDeliveryMethodForUserSuccess': {
          data: {
            status: true
          }
        },
        '...on GraphQLError': {
          message: true
        }
      }
    ]
  });
};

export const cognitoRequestOtpAction = async (
  email: string,
  method: 'SMS' | 'EMAIL' = 'SMS'
): Promise<'OK' | 'CUSTOM_CHALLENGE_REQUIRED'> => {
  if (!userPool) {
    throw new Error('Cognito user pool was not loaded');
  }

  if (method) {
    await setMethod(email, method);
  }

  // Construct a user
  const user = new CognitoUser({
    Username: email,
    Pool: userPool
  });
  user.setAuthenticationFlowType('CUSTOM_AUTH');

  g.reg.jotaiStore.set(cognitoUserAtom, user);

  // We login with email and password here
  const authDetails = new AuthenticationDetails({
    Username: email,
    ClientMetadata: {
      Method: method // "SMS" | "EMAIL"

      // Currently we don't use this, as it needs to be modelled as two flows
      // to be able to fail on Sms and success on email (of the other way around)
      //   Flow: 'Verify'
    }
  });

  return new Promise<'OK' | 'CUSTOM_CHALLENGE_REQUIRED'>((resolve, reject) => {
    user.initiateAuth(authDetails, {
      // This one is expected to be invoked
      customChallenge: () => resolve('CUSTOM_CHALLENGE_REQUIRED'),
      onFailure: (failure) => {
        reject(failure);
      },

      // This will never happen, but it needs to be here.
      onSuccess: async (resp, confirmationNecessary) => {
        g.reg.jotaiStore.set(authStateAtom, {
          accessToken: resp.getAccessToken().getJwtToken(),
          idToken: resp.getIdToken().getJwtToken(),
          refreshToken: resp.getRefreshToken().getToken()
        });
        resolve('OK');
      }
    });
  });
};
