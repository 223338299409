import { GraphQLTypes } from '~/__generated__/backend/zeus';
import { DealershipIdentifier } from '~/globals';
import { gqlMutationClient } from '~/lib/backend';

const updateAddressAction = async (
  addressId: string,
  dealershipIdentifier: DealershipIdentifier,
  values: GraphQLTypes['AddressInput']
): Promise<void> => {
  const resp = await gqlMutationClient(dealershipIdentifier)({
    updateAddress: [
      {
        id: addressId,
        address: values
      },
      {
        __typename: true,
        '...on GraphQLError': {
          message: true
        },
        '...on MutationUpdateAddressSuccess': {
          data: {
            status: true
          }
        }
      }
    ]
  });

  if (!resp.updateAddress || resp.updateAddress.__typename === 'GraphQLError') {
    throw new Error(resp.updateAddress?.message ?? 'Unexpected error');
  }
};

export default updateAddressAction;
