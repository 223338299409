import { Selector } from '~/__generated__/backend/zeus';
import { g } from '~/globals';
import { homenetSelector } from '~/querySelectors/homenetVehicleData';

const homenetDataSelector = (
  dealershipId: string,
  searchStockNumber: string
) => {
  return Selector('Query')({
    dealership: [
      { id: dealershipId },
      {
        homenetVehicles: [
          { stockNumberSearchString: searchStockNumber, first: 10 },
          { edges: { node: homenetSelector } }
        ]
      }
    ]
  });
};

export const fetchHomenetVehicleDataByStockNumber = (
  dealershipId?: string,
  searchStockNumber?: string,
  dealershipSlug?: string
) => ({
  queryKey: ['homenetVehicleData', dealershipId, searchStockNumber],
  enabled: Boolean(dealershipId) && Boolean(searchStockNumber),
  queryFn: () => {
    return dealershipId && searchStockNumber
      ? g.reg.tdcBackendClient.gqlQueryClient(
          dealershipSlug ? { dealershipSlug } : { dealershiplessAuth: true }
        )(homenetDataSelector(dealershipId, searchStockNumber))
      : undefined;
  }
});
