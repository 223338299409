import { ChevronLeft } from '@mui/icons-material';
import { captureException } from '@sentry/react';
import { useForm, useStore } from '@tanstack/react-form';
import { zodValidator, ZodValidator } from '@tanstack/zod-form-adapter';
import { Button, TextInput } from '@thedealersconcierge/components';
import { getFormFieldErrorMsg } from '@thedealersconcierge/lib/utils/forms';
import classNames from 'classnames';
import { TFunction } from 'i18next';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { z } from 'zod';
import uploadScannerFileAction from '~/actions/document-scanner/uploadScannerDealJacketFileAction';
import Checkbox from '~/components/inputs/Checkbox';
import {
  DocumentType,
  DocumentTypeSchema,
  getDocumentTitle,
  getDocumentTypeOptions
} from '~/config/formSelectionOptions/additionalDocumentOptions';
import { gqlMutationClient } from '~/lib/backend';
import { useNavigate } from '~/router';
import { DocumentDetection } from '../_components/Scanner';
import { BaseLayout } from '../_components/ScreenBaseLayout';
import { generatePdfBlobFromScannedDocuments } from '../_utils/scanbot';

type ReviewConsumerFormType = {
  documentTitle: string;
  documentType: DocumentType;
};

/**
 * This is done to reduce the redudancy between inline validator and submit validator
 */
const getValidatorAttributes = (t: TFunction) => {
  return {
    documentTitle: z.string().min(1, t('Document Title is Required')),
    documentType: DocumentTypeSchema
  };
};

export function ReviewConsumer({
  pages,
  onNavBack,
  transactionId,
  submissionToken
}: {
  pages: DocumentDetection[];
  onNavBack: () => void;
  transactionId: string;
  submissionToken?: string;
}): JSX.Element {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const validatorAttributes = getValidatorAttributes(t);
  const ValidFormSchema = z.object(validatorAttributes);

  const form = useForm<ReviewConsumerFormType, ZodValidator>({
    defaultValues: {
      documentTitle: getDocumentTitle('BANK_STATEMENTS', t),
      documentType: 'BANK_STATEMENTS'
    },
    validators: {
      onSubmit: ValidFormSchema
    },
    validatorAdapter: zodValidator(),
    onSubmit: async (values) => {
      try {
        setIsSubmitting(true);

        // We generate the PDF To be uploaded here
        const pdfBlob = await generatePdfBlobFromScannedDocuments(pages);

        // Upload the files and get the uploaded file id
        const { fileId } = await uploadScannerFileAction({
          submissionToken,
          transactionId,
          pdfBlob,
          t
        });

        const response = await gqlMutationClient({
          dealershiplessAuth: true
        })({
          addScanToGloveCompartments: [
            {
              transactionId,
              submissionToken,
              fileId,
              title: values.value.documentTitle
            },
            {
              __typename: true,
              '...on GraphQLError': {
                message: true
              },
              '...on MutationAddScanToGloveCompartmentsSuccess': {
                data: {
                  status: true
                }
              }
            }
          ]
        });

        const addScan = response.addScanToGloveCompartments;
        if (addScan?.__typename === 'GraphQLError') {
          toast.error(addScan?.message ?? 'Unexpected error');
          return;
        }

        navigate('/scanner/done');
      } catch (error) {
        console.error(error);
        captureException(error);
        toast.error(t('An unexpected error happened'));
      } finally {
        setIsSubmitting(false);
      }
    }
  });

  // We use this to track document type and prevent unecessarry re-renders
  const documentType = useStore(
    form.store,
    ({ values }) => values.documentType
  );

  return (
    <BaseLayout>
      <BaseLayout.Top>
        <div className="grid grid-cols-3 place-items-center w-full px-spacing-04 py-spacing-02 border-b">
          <Button
            variant="GHOST"
            iconLeft={<ChevronLeft className="text-primary-brand" />}
            label="Back"
            size="SMALL"
            onClick={onNavBack}
            className="col-span-1 justify-self-start"
          />

          <p className="text-small font-bold col-span-1">{t('Review')}</p>
        </div>
      </BaseLayout.Top>

      <BaseLayout.Content>
        <form
          className="flex flex-col justify-stretch h-full w-full"
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            void form.handleSubmit();
          }}
        >
          {/* Document Type Selection */}
          <form.Field
            name="documentType"
            validators={{
              onBlur: validatorAttributes.documentType
            }}
          >
            {(field) => (
              <div className="flex flex-col w-full p-spacing-04">
                {getDocumentTypeOptions(t).map(({ value, label }) => (
                  <div
                    className="flex flex-row items-center space-y-spacing-01"
                    key={value}
                  >
                    <Checkbox
                      inputId={label}
                      variant="RADIO_BUTTON"
                      value={documentType === value}
                      onChange={() => {
                        field.setValue(value);

                        // When selecting others document title will set to empty
                        if (value !== 'OTHER') {
                          form.setFieldValue('documentTitle', label);
                        } else {
                          form.setFieldValue('documentTitle', '');
                        }
                      }}
                    />
                    <span className="ml-2 text-gray-700">{label}</span>
                  </div>
                ))}
              </div>
            )}
          </form.Field>

          {/* Document Title */}
          <form.Field
            name="documentTitle"
            validators={{
              onBlur: validatorAttributes.documentTitle
            }}
          >
            {(field) => (
              <TextInput
                label={t('Document Title')}
                placeholder={t('Eg. Bank Statement')}
                value={field.state.value}
                assistiveMessage={t('Type in the value')}
                errorMessage={getFormFieldErrorMsg(field)}
                disabled={false}
                required={true}
                backgroundType="LIGHT"
                onChange={field.handleChange}
                className={classNames('px-4', {
                  invisible: documentType !== 'OTHER'
                })}
              />
            )}
          </form.Field>

          {/* Document Previews */}
          <div
            className="overflow-x-auto scroll-smooth snap-x snap-mandatory w-full h-full "
            style={{
              WebkitOverflowScrolling: 'touch',
              overscrollBehavior: 'contain'
            }}
          >
            <div className="flex h-full">
              {pages.map((p, idx) => {
                const blob = p.cropped
                  ? new Blob([p.cropped], { type: 'image/png' })
                  : null;
                const url = blob ? URL.createObjectURL(blob) : undefined;

                return (
                  <div
                    key={idx}
                    className="
                      relative w-screen min-w-full h-full snap-center 
                      flex items-center justify-center px-spacing-03
                    "
                  >
                    <div className="w-full max-w-[90%] aspect-[1/1.414]">
                      {url && (
                        <img
                          draggable={false}
                          src={url}
                          alt={`Page ${idx + 1}`}
                          className="w-full h-full object-contain"
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </form>
      </BaseLayout.Content>

      <BaseLayout.Footer>
        <div className="w-full grid grid-cols-2 place-items-center p-4 border-t h-[80px]">
          <Button
            className="col-start-2" // Positions the button in the second column
            isLoading={isSubmitting}
            disabled={isSubmitting}
            size="SMALL"
            label={t('Upload')}
            type="submit"
            onClick={() => {
              void form.handleSubmit();
            }}
          />
        </div>
      </BaseLayout.Footer>
    </BaseLayout>
  );
}
