import {
  FetchFunction,
  Thunder,
  ZeusScalars
} from '~/__generated__/backend/zeus';
import config from '~/config';
import { getAuthHeaders } from '~/lib/backend';

const scalars = ZeusScalars({
  JSON: {
    encode: (e: unknown) => JSON.stringify(e),
    decode: (e: unknown) =>
      // The JSON fields in a response can be sent as objects and not strings.
      // Therefore, we have to check the type before trying to parse a JSON string.
      // If it's not a string, we are just returning the object
      (typeof e === 'string' ? JSON.parse(e) : e) as unknown
  },
  DateTime: {
    decode: (e: unknown) => new Date(e as string),

    // It inserts it verbatim, not with enclosing `"` for strings
    encode: (e: unknown) => `"${(e as Date).toISOString()}"`
  }
});

/**
 * We use this structure, as it is incredibly difficult to ensure that we sign in to the right
 * dealership, so we need to force the users of the function to think about it
 */
type DealershipPart =
  | {
      /**
       * ID of the dealership to carry dealership-indexed operations out on
       *
       * Only one of this or dealershipId is needed
       */
      dealershipSlug: string;
    }
  | {
      /**
       * ID of the dealership to carry dealership-indexed operations out on
       *
       * Only one of this or dealershipSlug is needed
       */
      dealershipId: string;
    }
  | {
      /**
       * This is a marker that we don't provide dealership
       */
      dealershiplessAuth: true;
    };

type Options = {
  /**
   * Throws if the graphQL errors out
   */
  throwOnError?: boolean;
} & DealershipPart;

const fetchFunction: (options: Options) => FetchFunction =
  (options) =>
  async (query, variables: Record<string, unknown> = {}) => {
    if ('dealershipId' in options) {
      options.dealershipId;
    }

    const authHeaders = await getAuthHeaders(
      'dealershipId' in options ? options.dealershipId : undefined,
      'dealershipSlug' in options ? options?.dealershipSlug : undefined
    );

    // TODO: This should go to axios, but that requires being able to mock these
    // So we will wait a bit here
    const response = await fetch(config.backendUrl, {
      body: JSON.stringify(
        {
          query,
          variables: variables
        },
        (_, value) => {
          return value;
        }
      ),
      method: 'POST',
      headers: {
        ...authHeaders,
        'Content-Type': 'application/json'
      }
    });

    const gqlResp = await response.json();

    if (gqlResp.errors) {
      if (options?.throwOnError) {
        throw new Error(JSON.stringify(gqlResp.errors));
      } else {
        console.error(gqlResp.errors);
      }
    }

    return gqlResp.data;
  };

export class TdcBackendClient {
  gqlMutationClient(options: Options) {
    return Thunder(fetchFunction(options), {
      scalars
    })('mutation');
  }

  gqlQueryClient(options: Options) {
    return Thunder(fetchFunction(options), {
      scalars
    })('query');
  }
}
