import { captureException } from '@sentry/react';
import { useForm } from '@tanstack/react-form';
import { useQuery } from '@tanstack/react-query';
import { zodValidator, ZodValidator } from '@tanstack/zod-form-adapter';
import { Button, Spinner } from '@thedealersconcierge/components';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import logoutAction from '~/actions/logoutAction';
import PrequalForm, {
  PrequalFormValues,
  ValidPrequalFormSchema
} from '~/components/forms/PrequalForm';
import ChevronRightIcon from '~/components/icons/ChevronRightIcon';
import DocumentIcon from '~/components/icons/DocumentIcon';
import customerQuery from '~/queries/customerQuery';
import meQuery from '~/queries/meQuery';
import transactionQuery from '~/queries/transactionQuery';
import { Link, useNavigate, useParams } from '~/router';
import submitPreQualApplicationWorkflow from '~/workflows/formSubmissions/prequal/submitPreQualApplicationWorkflow';
import ConsumerDashboardHeader from './_components/ConsumerDashboardHeader';

const Form: FC<{
  transactionId: string;
  dealershipSlug: string;
  dealershipName: string;
  ipAddress: string;
  existingValues: {
    firstName?: string;
    middleName?: string;
    lastName?: string;
    birthdate?: string;
    street?: string;
    apartmentDetails?: string;
    city?: string;
    state?: string;
    zip?: string;
    phoneNumber: string;
    email: string;
  };
}> = ({
  transactionId,
  dealershipSlug,
  dealershipName,
  ipAddress,
  existingValues
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const form = useForm<PrequalFormValues, ZodValidator>({
    defaultValues: {
      firstName: existingValues.firstName ?? '',
      middleName: existingValues.middleName ?? '',
      lastName: existingValues.lastName ?? '',
      socialSecurityNumber: '',
      birthdate: existingValues.birthdate ?? '',
      street: existingValues.street ?? '',
      apartmentDetails: existingValues.apartmentDetails ?? '',
      city: existingValues.city ?? '',
      state: existingValues.state ?? '',
      zip: existingValues.zip ?? '',
      phoneNumber: existingValues.phoneNumber,
      email: existingValues.email,
      confirmNotAffectCreditScoreAndPrequalConsented: false,
      acknowledgesElectronicConsent: false,
      hasCommunicationsConsented: false,
      signature: ''
    },
    validators: {
      onSubmit: ValidPrequalFormSchema(t)
    },
    validatorAdapter: zodValidator(),
    onSubmit: async ({ value }) => {
      try {
        setIsSubmitting(true);

        await submitPreQualApplicationWorkflow({
          t,
          values: value,
          dealershipName,
          dealershipIdentifier: { dealershipSlug },
          ipAddress,
          signature: value.signature,
          transactionId
        });

        navigate('/dashboard/:dealershipSlug/:transactionId', {
          params: { transactionId, dealershipSlug }
        });
      } catch (e) {
        captureException(e);
        console.error(e);
      } finally {
        setIsSubmitting(false);
      }
    }
  });

  return (
    <div className="flex flex-col items-center">
      <PrequalForm
        environment="IN_APP"
        form={form}
        isSubmitting={isSubmitting}
        dealershipSlug={dealershipSlug}
        dealershipName={dealershipName}
        onSubmit={() => {
          void form.handleSubmit();
        }}
        dataTestId="prequal-app"
      />
    </div>
  );
};

const PreQualPage = () => {
  const { t } = useTranslation();
  const { transactionId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/prequal'
  );
  const [loggingOut, setLoggingOut] = useState(false);
  const handleLogout = () => {
    setLoggingOut(true);
    logoutAction();
  };
  const { data: meData, isLoading: loadingMeData } = useQuery(meQuery());
  const { data: transactionData, isLoading: loadingTransactionData } = useQuery(
    transactionQuery(transactionId, dealershipSlug)
  );
  const { data: customerData, isLoading: loadingCustomerData } = useQuery(
    customerQuery(
      transactionId,
      meData?.me?.user?.id ?? undefined,
      dealershipSlug
    )
  );
  const currentAddress =
    customerData?.customer?.residentialAddresses?.edges?.find(
      (e) => e.node?.timelinePosition === 0
    )?.node;
  const dealershipName = transactionData?.transaction?.dealership?.name;

  return (
    <div className="flex flex-col h-dvh">
      <ConsumerDashboardHeader
        leftElement={
          <div className="relative">
            <img src="/logo.png" className="h-4" aria-label="Logo" />
          </div>
        }
        centerElement={
          <div className="flex flex-row space-x-2">
            <div className="relative">
              <DocumentIcon className="size-4 md:size-5 icon-primary" />
            </div>

            <h2 className="hidden md:flex items-center">
              {t('Documents & Forms')}
            </h2>

            <h3 className="flex md:hidden items-center">
              {t('Documents & Forms')}
            </h3>
          </div>
        }
        rightElement={
          <Button
            label={t('Logout')}
            size="SMALL"
            variant="GHOST"
            onClick={() => {
              handleLogout();
            }}
            isLoading={loggingOut}
          />
        }
        showBorder
      />

      <div className="flex flex-col pt-2 space-y-6 w-full items-center overflow-y-scroll">
        <div className="w-full px-4 md:px-6">
          <Link
            to="/dashboard/:dealershipSlug/:transactionId"
            params={{ transactionId, dealershipSlug }}
            className="flex w-6"
          >
            <div className="relative">
              <ChevronRightIcon className="w-6 -scale-x-100 icon-tertiary" />
            </div>
          </Link>
        </div>

        {(loadingMeData || loadingTransactionData || loadingCustomerData) && (
          <div className="flex w-full max-w-screen-md aspect-square justify-center items-center relative">
            <Spinner size="LARGE" color="GREY" />
          </div>
        )}

        {!loadingMeData &&
          !loadingTransactionData &&
          !loadingCustomerData &&
          (dealershipName &&
          customerData?.customer?.user?.phoneNumber &&
          customerData.customer.user.email ? (
            <Form
              transactionId={transactionId}
              dealershipSlug={dealershipSlug}
              dealershipName={dealershipName}
              ipAddress={meData?.me?.ipAddress ?? 'unknown'}
              existingValues={{
                firstName: customerData?.customer?.firstName ?? undefined,
                middleName: customerData?.customer?.middleName ?? undefined,
                lastName: customerData?.customer?.lastName ?? undefined,
                birthdate: customerData?.customer?.birthdate ?? undefined,
                street: currentAddress?.street ?? undefined,
                apartmentDetails: currentAddress?.apartmentDetails ?? undefined,
                city: currentAddress?.city ?? undefined,
                state: currentAddress?.state ?? undefined,
                zip: currentAddress?.zipCode ?? undefined,
                phoneNumber: customerData?.customer?.user?.phoneNumber,
                email: customerData.customer.user.email
              }}
            />
          ) : (
            <p>{t('Could not load all required data')}</p>
          ))}
      </div>
    </div>
  );
};

export default PreQualPage;
