import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { useLocation } from 'react-router';

import { kioskDealershipAtom } from '~/state/kiosk';

/**
 * This hook loads kiosk mode when information regarding the kiosk mode
 * is presented
 *
 * @returns
 */
export default function KioskModeHook() {
  const location = useLocation();
  const [kioskDealership, setKioskDealership] = useAtom(kioskDealershipAtom);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const dealership = searchParams.get('dealership');
    if (dealership === 'RESET') {
      setKioskDealership(null);
    } else if (dealership) {
      setKioskDealership({
        dealershipSlug: dealership
      });
    }
  }, [location]);

  return null;
}
