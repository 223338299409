import { Selector } from '~/__generated__/backend/zeus';

export const employmentSelector = Selector('Employment')({
  id: true,
  userId: true,
  transactionId: true,
  timelinePosition: true,
  employmentStatus: true,
  employerName: true,
  occupation: true,
  employerPhoneNumber: true,
  durationYears: true,
  durationMonths: true,
  incomeAmount: true,
  incomeInterval: true,
  otherIncomeSource: true,
  otherIncomeAmount: true,
  otherIncomeInterval: true
});
