import {
  GraphQLTypes,
  InputType,
  Selector
} from '~/__generated__/backend/zeus';

export const homenetSelector = Selector('HomenetVehicleData')({
  id: true,
  vin: true,
  make: true,
  model: true,
  year: true,
  mileage: true,
  transmission: true,
  fuelType: true,
  stockNumber: true,
  trim: true,
  type: true,
  color: true,
  bodyType: true
});

export type SingleHomenetVehicleData = InputType<
  GraphQLTypes['HomenetVehicleData'],
  typeof homenetSelector
>;
