import { ChevronLeft } from '@mui/icons-material';
import { captureException } from '@sentry/react';
import { useForm } from '@tanstack/react-form';
import { zodValidator, ZodValidator } from '@tanstack/zod-form-adapter';
import { Button, TextInput } from '@thedealersconcierge/components';
import { getFormFieldErrorMsg } from '@thedealersconcierge/lib/utils/forms';
import { TFunction } from 'i18next';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { z } from 'zod';
import {
  CustomerTransactionRole,
  FormSubmissionAccessEmum
} from '~/__generated__/backend/zeus';
import uploadScannerFileAction from '~/actions/document-scanner/uploadScannerDealJacketFileAction';
import Checkbox from '~/components/inputs/Checkbox';
import { gqlMutationClient } from '~/lib/backend';
import { useNavigate } from '~/router';
import { DocumentDetection } from '../_components/Scanner';
import { BaseLayout } from '../_components/ScreenBaseLayout';
import { generatePdfBlobFromScannedDocuments } from '../_utils/scanbot';

type ReviewFormType = {
  documentTitle: string;
  addTo: 'BUYER' | 'CO_BUYER';
  access: 'DEALERSHIP' | 'BOTH';
};

/**
 * This is done to reduce the redudancy between inline validator and submit validator
 */
const getValidatorAttributes = (t: TFunction) => {
  return {
    documentTitle: z.string().min(1, t('Document Title is Required')),
    addTo: z.union([z.literal('BUYER'), z.literal('CO_BUYER')]),
    access: z.union([z.literal('DEALERSHIP'), z.literal('BOTH')])
  };
};

export default function ReviewDashboard({
  pages,
  onNavBack,
  transactionId,
  submissionToken
}: {
  pages: DocumentDetection[];
  onNavBack: () => void;
  transactionId: string;
  submissionToken: string;
}): JSX.Element {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const validatorAttributes = getValidatorAttributes(t);
  const ValidFormSchema = z.object(validatorAttributes);

  const form = useForm<ReviewFormType, ZodValidator>({
    defaultValues: {
      documentTitle: '',
      access: 'DEALERSHIP',
      addTo: 'BUYER'
    },
    validators: {
      onSubmit: ValidFormSchema
    },
    validatorAdapter: zodValidator(),
    onSubmit: async (values) => {
      try {
        setIsSubmitting(true);

        const addTo =
          values.value.addTo === 'BUYER'
            ? CustomerTransactionRole.BUYER
            : CustomerTransactionRole.CO_BUYER;

        // We generate the PDF To be uploaded here
        const pdfBlob = await generatePdfBlobFromScannedDocuments(pages);

        // Upload the files and get the uploaded file id
        const { fileId } = await uploadScannerFileAction({
          submissionToken,
          transactionId,
          addTo:
            values.value.addTo === 'BUYER'
              ? CustomerTransactionRole.BUYER
              : CustomerTransactionRole.CO_BUYER,
          pdfBlob,
          t
        });

        const response = await gqlMutationClient({
          dealershiplessAuth: true
        })({
          addScanToDealerJacket: [
            {
              transactionId,
              submissionToken,
              addTo,
              fileId,
              title: values.value.documentTitle,
              access:
                values.value.access === 'DEALERSHIP'
                  ? FormSubmissionAccessEmum.DEALERSHIP
                  : FormSubmissionAccessEmum.BOTH
            },
            {
              __typename: true,
              '...on GraphQLError': {
                message: true
              },
              '...on MutationAddScanToDealerJacketSuccess': {
                data: {
                  status: true
                }
              }
            }
          ]
        });

        const addScan = response.addScanToDealerJacket;
        if (addScan?.__typename === 'GraphQLError') {
          toast.error(addScan?.message ?? 'Unexpected error');
          return;
        }

        navigate('/scanner/done');
      } catch (error) {
        console.error(error);
        captureException(error);
        toast.error(t('An unexpected error happened'));
      } finally {
        setIsSubmitting(false);
      }
    }
  });

  return (
    <BaseLayout>
      <BaseLayout.Top>
        <div className="grid grid-cols-3 place-items-center w-full px-spacing-04 py-spacing-02 border-b">
          <Button
            variant="GHOST"
            iconLeft={<ChevronLeft className="text-primary-brand" />}
            label={t('Back')}
            size="SMALL"
            onClick={onNavBack}
            className="col-span-1 justify-self-start"
          />

          <p className="text-small font-bold col-span-1">{t('Review')}</p>
        </div>
      </BaseLayout.Top>

      <BaseLayout.Content>
        <form
          className="flex flex-col justify-stretch h-full w-full"
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            void form.handleSubmit();
          }}
        >
          <div className="grid grid-cols-2 p-spacing-04">
            <form.Field
              name="access"
              validators={{
                onBlur: validatorAttributes.access
              }}
            >
              {(field) => (
                <div className="flex flex-col space-y-2">
                  <div
                    className="flex space-x-4 cursor-pointer"
                    onClick={() => {
                      field.setValue('DEALERSHIP');
                    }}
                  >
                    {/* The new component needs to support radio button */}
                    <Checkbox
                      variant="RADIO_BUTTON"
                      onChange={() => {
                        field.setValue('DEALERSHIP');
                      }}
                      value={field.state.value === 'DEALERSHIP'}
                    />
                    <div>{t('Dealership')}</div>
                  </div>

                  <div
                    className="flex space-x-4 cursor-pointer"
                    onClick={() => {
                      field.setValue('BOTH');
                    }}
                  >
                    {/* The new component needs to support radio button */}
                    <Checkbox
                      variant="RADIO_BUTTON"
                      onChange={() => {
                        field.setValue('BOTH');
                      }}
                      value={field.state.value === 'BOTH'}
                    />
                    <div>{t('Dealership & Customer')}</div>
                  </div>
                </div>
              )}
            </form.Field>

            <form.Field
              name="addTo"
              validators={{
                onBlur: validatorAttributes.addTo
              }}
            >
              {(field) => (
                <div className="flex flex-col space-y-2">
                  <div
                    className="flex space-x-4 cursor-pointer"
                    onClick={() => {
                      field.setValue('BUYER');
                    }}
                  >
                    {/* The new component needs to support radio button */}
                    <Checkbox
                      variant="RADIO_BUTTON"
                      onChange={() => {
                        field.setValue('BUYER');
                      }}
                      value={field.state.value === 'BUYER'}
                    />
                    <div>{t('Add to Buyer')}</div>
                  </div>
                  <div
                    className="flex space-x-4 cursor-pointer"
                    onClick={() => {
                      field.setValue('CO_BUYER');
                    }}
                  >
                    {/* The new component needs to support radio button */}
                    <Checkbox
                      variant="RADIO_BUTTON"
                      onChange={() => {
                        field.setValue('CO_BUYER');
                      }}
                      value={field.state.value === 'CO_BUYER'}
                    />
                    <div>{t('Add to Co-buyer')}</div>
                  </div>
                </div>
              )}
            </form.Field>
          </div>

          {/* Document Title */}
          <form.Field
            name="documentTitle"
            validators={{
              onBlur: validatorAttributes.documentTitle
            }}
          >
            {(field) => (
              <TextInput
                label={t('Document Title')}
                placeholder={t('Eg. Bank Statement')}
                value={field.state.value}
                assistiveMessage={t('Type in the value')}
                errorMessage={getFormFieldErrorMsg(field)}
                disabled={false}
                required={true}
                backgroundType="LIGHT"
                onChange={field.handleChange}
                className={'px-4'}
              />
            )}
          </form.Field>

          {/* Document Previews */}
          <div
            className="overflow-x-auto scroll-smooth snap-x snap-mandatory w-full h-full "
            style={{
              WebkitOverflowScrolling: 'touch',
              overscrollBehavior: 'contain'
            }}
          >
            <div className="flex h-full">
              {pages.map((p, idx) => {
                const blob = p.cropped
                  ? new Blob([p.cropped], { type: 'image/png' })
                  : null;
                const url = blob ? URL.createObjectURL(blob) : undefined;

                return (
                  <div
                    key={idx}
                    className="
                      relative w-screen min-w-full h-full snap-center 
                      flex items-center justify-center px-spacing-03
                    "
                  >
                    <div className="w-full max-w-[90%] aspect-[1/1.414]">
                      {url && (
                        <img
                          draggable={false}
                          src={url}
                          alt={`Page ${idx + 1}`}
                          className="w-full h-full object-contain"
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </form>
      </BaseLayout.Content>

      <BaseLayout.Footer>
        <div className="w-full grid grid-cols-2 place-items-center p-4 border-t h-[80px]">
          <Button
            className="col-start-2" // Positions the button in the second column
            isLoading={isSubmitting}
            disabled={isSubmitting}
            size="SMALL"
            label={t('Upload')}
            type="submit"
            onClick={() => {
              void form.handleSubmit();
            }}
          />
        </div>
      </BaseLayout.Footer>
    </BaseLayout>
  );
}
