import { TFunction } from 'i18next';
import { VehiclePrincipalPriorUse } from '~/__generated__/backend/zeus';

export type VehiclePrincipalPriorUseOption = {
  value: VehiclePrincipalPriorUse;
  label: string;
};

export default function vehiclePrincipalPriorUseOptions(t: TFunction) {
  return [
    { value: VehiclePrincipalPriorUse.COMMERCIAL, label: t('Commercial') },
    { value: VehiclePrincipalPriorUse.DEMO, label: t('Demo') },
    { value: VehiclePrincipalPriorUse.FLEET, label: t('Fleet') },
    {
      value: VehiclePrincipalPriorUse.LIVERY_VEHICLE,
      label: t('Livery vehicle')
    },
    { value: VehiclePrincipalPriorUse.OTHER, label: t('Other') },
    {
      value: VehiclePrincipalPriorUse.PRIVATE_PASSENGER_VEHICLE,
      label: t('Private passenger vehicle')
    },
    {
      value: VehiclePrincipalPriorUse.RENTAL_VEHICLE,
      label: t('Rental vehicle')
    },
    { value: VehiclePrincipalPriorUse.TAXI, label: t('Taxi') }
  ];
}
