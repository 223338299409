import { useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { ChangeEvent, Fragment, useRef, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import uploadFileAction from '~/actions/formSubmissions/uploadFileAction';
import Button from '~/components/Button';
import DesktopWebcamModal from '~/components/DesktopWebcamModal';
import Header from '~/components/Header';
import SelectableRow from '~/components/SelectableRow';
import Spinner from '~/components/Spinner';
import CaptureIcon from '~/components/icons/CaptureIcon';
import ChevronRightIcon from '~/components/icons/ChevronRightIcon';
import { g } from '~/globals';
import { gqlMutationClient, gqlQueryClient } from '~/lib/backend';
import { cropCardHolderImage } from '~/lib/files';
import { resetMeQuery } from '~/queries/meQuery';
import { vehicleSelector } from '~/querySelectors/vehicle';
import { Link, useNavigate, useParams } from '~/router';
import { kioskDealershipAtom } from '~/state/kiosk';

const TradeVehicleRegistrationCardPage = () => {
  const { t } = useTranslation();
  const kioskMode = useAtomValue(kioskDealershipAtom);
  const mobileCameraInputRef = useRef<HTMLInputElement | null>(null);
  const navigate = useNavigate();

  const { transactionId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/trade-vehicle/registrationCard'
  );
  const { data, isLoading } = useQuery({
    queryKey: ['transaction', transactionId, 'tradeVehicle'],
    queryFn: async () =>
      gqlQueryClient({ dealershipSlug })({
        transaction: [
          {
            id: transactionId
          },
          {
            tradeVehicle: vehicleSelector
          }
        ]
      })
  });
  const vehicle = data?.transaction?.tradeVehicle;
  const [hasRegistrationCard, setHasRegistrationCard] = useState(true);
  const [capture, setCapture] = useState<File | undefined>(undefined);
  const [isDesktopWebcamModalOpen, setIsDesktopWebcamModalOpen] =
    useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleCancel = () => {
    navigate('/dashboard/:dealershipSlug/:transactionId', {
      params: { transactionId, dealershipSlug }
    });
  };
  const handleYesClick = () => {
    setHasRegistrationCard(true);
  };
  const handleNoClick = () => {
    setHasRegistrationCard(false);
  };
  const handleCapture = () => {
    if (isMobile || isTablet) {
      mobileCameraInputRef.current?.click();
    } else {
      setIsDesktopWebcamModalOpen(true);
    }
  };
  const handleGoToNext = async () => {
    if (capture && vehicle?.id) {
      setIsSubmitting(true);

      const captureIsPng = capture.type.includes('png');
      const captureUpload = await uploadFileAction(
        { dealershipSlug },
        captureIsPng ? 'png' : 'jpg',
        captureIsPng ? 'image/png' : 'image/jpg',
        capture
      );

      /**
       * This check is for typing. As we've created the vehicle before,
       * all required fields should be set already on the vehicle.
       */
      if (
        !(
          vehicle.registrationName &&
          vehicle.registrationState &&
          vehicle.vin &&
          vehicle.make &&
          vehicle.model &&
          vehicle.year &&
          vehicle.mileage &&
          vehicle.bodyType &&
          vehicle.condition &&
          vehicle.color
        )
      ) {
        throw new Error('Missing vehicle data');
      }

      const resp = await gqlMutationClient({ dealershipSlug })({
        customerUpdateTradeVehicle: [
          {
            vehicleId: vehicle.id,
            vehicle: {
              registrationName: vehicle.registrationName,
              registrationState: vehicle.registrationState,
              vin: vehicle.vin,
              make: vehicle.make,
              model: vehicle.model,
              year: vehicle.year,
              mileage: vehicle.mileage,
              bodyType: vehicle.bodyType,
              condition: vehicle.condition,
              color: vehicle.color,
              registrationCardId: captureUpload.fileId
            }
          },
          {
            __typename: true,
            '...on GraphQLError': {
              message: true
            },
            '...on MutationCustomerUpdateTradeVehicleSuccess': {
              data: {
                status: true
              }
            }
          }
        ]
      });

      if (resp.customerUpdateTradeVehicle?.__typename === 'GraphQLError') {
        throw new Error(resp.customerUpdateTradeVehicle.message ?? undefined);
      }

      await Promise.all([
        resetMeQuery(),
        g.reg.queryClient.resetQueries({
          queryKey: ['transaction', transactionId]
        })
      ]);

      setIsSubmitting(false);
    }

    navigate('/dashboard/:dealershipSlug/:transactionId/trade-vehicle/payoff', {
      params: { transactionId, dealershipSlug }
    });
  };
  const handleMobileImageCapture = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];

      // If the user is on a tablet in kiosk mode, they are using a card holder
      // Therefore, we have to crop the image
      if (isTablet && kioskMode) {
        const croppedImage = await cropCardHolderImage(file);

        setCapture(croppedImage);
      } else {
        setCapture(file);
      }
    }
  };
  const handleCloseDesktopWebcamModal = () => {
    setIsDesktopWebcamModalOpen(false);
  };
  const handleDesktopImageCapture = (file: File) => {
    setCapture(file);
    setIsDesktopWebcamModalOpen(false);
  };
  const existingUrl = data?.transaction?.tradeVehicle?.registrationCard?.url;

  return (
    <div className="flex flex-col h-dvh">
      <Header
        title={t('Trade Registration')}
        leftElement={
          <button className=" text-primary-brand" onClick={handleCancel}>
            {t('Cancel')}
          </button>
        }
        totalSteps={4}
        currentStep={2}
      />

      {isLoading && (
        <div className="flex flex-1 items-center justify-center">
          <Spinner />
        </div>
      )}

      <div className="flex w-full px-6 justify-center z-30">
        <div className="flex w-full max-w-screen-md self-center pt-4">
          <Link
            to="/dashboard/:dealershipSlug/:transactionId/trade-vehicle/information"
            params={{ transactionId, dealershipSlug }}
            className="flex w-6"
          >
            <div className="relative">
              <ChevronRightIcon className="w-6 -scale-x-100 icon-tertiary" />
            </div>
          </Link>
        </div>
      </div>

      {!isLoading && (
        <Fragment>
          {/**
           * This input opens the camera on mobile devices
           */}
          <input
            ref={mobileCameraInputRef}
            accept="image/*"
            type="file"
            capture="environment"
            className="hidden"
            onChange={(e) => void handleMobileImageCapture(e)}
          />

          {/**
           * This is the webcam modal for desktop
           */}
          <DesktopWebcamModal
            title={t('Capture the Front of Your Registration')}
            isOpen={isDesktopWebcamModalOpen}
            orientation="LANDSCAPE"
            onClose={handleCloseDesktopWebcamModal}
            onDone={handleDesktopImageCapture}
          />

          <div className="flex flex-col items-center overflow-y-scroll px-6">
            <div className="flex flex-col max-w-screen-md py-10 space-y-12 justify-between w-full">
              <div className="flex flex-col space-y-8 md:space-y-16 items-center">
                <div className="space-y-5 w-full">
                  <h2>{t('Step 2: Registration Card')}</h2>

                  <p>{t('Do you have your vehicle registration on hand?')}</p>
                </div>

                <div className="w-full">
                  <SelectableRow
                    selected={hasRegistrationCard}
                    text={t('Yes')}
                    onClick={handleYesClick}
                    disabled={
                      !!data?.transaction?.tradeVehicle?.registrationCard
                    }
                  />

                  <SelectableRow
                    selected={!hasRegistrationCard}
                    text={t('No')}
                    onClick={handleNoClick}
                    disabled={
                      !!data?.transaction?.tradeVehicle?.registrationCard
                    }
                  />
                </div>

                {hasRegistrationCard && !existingUrl && !capture && (
                  <div
                    className="flex flex-col space-y-4 w-full cursor-pointer items-center"
                    onClick={handleCapture}
                  >
                    <h3>{t('Capture Front')}</h3>

                    <CaptureIcon className="max-w-64 icon-tertiary" />
                  </div>
                )}

                {hasRegistrationCard && (existingUrl || capture) && (
                  <div className="flex relative h-52">
                    <img
                      src={
                        existingUrl ??
                        (capture
                          ? URL.createObjectURL(capture)
                          : 'Will not happen') // We can force unwrap because if there is no existing card, there's a capture
                      }
                      className="size-full object-contain"
                    />
                  </div>
                )}

                <div className="flex w-full justify-end">
                  <Button
                    onClick={() => {
                      void handleGoToNext();
                    }}
                    disabled={hasRegistrationCard && !existingUrl && !capture}
                    loading={isSubmitting}
                  >
                    {t('Next')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default TradeVehicleRegistrationCardPage;
