import { ExpandLess, ExpandMore } from '@mui/icons-material';
import classNames from 'classnames';
import { FC, Fragment } from 'react';
import InputBase from '..';
import { SelectBase } from '../..';
import { DropdownOption } from '../dropdown/dropdownConfig';
import { InputBackgroundType } from '../inputConfig';

/**
 * Select component.
 *
 * TODOs:
 *
 * 1. Accessibility: As this component does not use basic HTML components,
 *    it is not accessible. Extra attention should be made to make this component accessible.
 * 2. Keyboard navigation: This component does not support keyboard navigation and options selection.
 *
 * Testing:
 *
 * This component does not use the <select><option /></select> structure that is normal for
 * these types of dropdown. The pragmatic middle ground has been to give all elements in the
 * dropdown a hidden <option /> element so we can select them.
 *
 */
const Select: FC<{
  options: DropdownOption[];
  value?: string;
  label: string;
  assistiveMessage?: string;
  errorMessage?: string;
  placeholder: string;
  onSelect: (option: DropdownOption) => Promise<void> | void;
  onBlur?: (() => void) | undefined;
  required: boolean;
  disabled: boolean;
  className?: string;
  backgroundType?: InputBackgroundType;
  dataTestId?: string;
}> = ({
  options,
  value,
  label,
  assistiveMessage,
  errorMessage,
  placeholder,
  onSelect,
  onBlur,
  required,
  disabled,
  className,
  backgroundType = 'DARK',
  dataTestId
}) => {
  return (
    <SelectBase
      disabled={disabled}
      label={label}
      onSelect={onSelect}
      options={options}
      placeholder={placeholder}
      required={required}
      onBlur={onBlur}
      dataTestId={dataTestId}
      className={className}
      inputElement={(open) => {
        return (
          <InputBase
            inputComponent={
              <Fragment>
                <p
                  className={classNames(
                    'flex truncate text-start body-01 w-full px-spacing-02 py-[6px] text-primary outline-none bg-transparent',
                    {
                      'text-tertiary': disabled || !value || value.length === 0
                    }
                  )}
                >
                  {value && value.length > 0
                    ? (options.find((o) => o.value === value)?.label ?? value)
                    : placeholder}
                </p>

                <div className="relative flex w-10 max-h-10 items-center justify-center">
                  {open ? <ExpandLess /> : <ExpandMore />}
                </div>
              </Fragment>
            }
            label={label}
            assistiveMessage={assistiveMessage}
            required={required}
            disabled={disabled}
            isBeingUpdated={open}
            errorMessage={errorMessage}
            backgroundType={backgroundType}
            inputContainerClassName={classNames(
              'group-focus:border-interactive',
              { 'border-interactive': open }
            )}
          />
        );
      }}
    />
  );
};

export default Select;
