import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router';
import { OnboardingStep } from '~/__generated__/backend/zeus';
import { useEnsureLanguage } from '~/hooks/useEnsureLanguage';
import customerQuery from '~/queries/customerQuery';
import meQuery from '~/queries/meQuery';
import { useNavigate, useParams } from '~/router';

/**
 * This decorator ensures that the user is on the correct onboarding step
 *
 * @returns
 */
export default function OnboardingLayout() {
  // This should work for all steps
  const { transactionId, dealershipSlug } = useParams(
    '/onboarding/:dealershipSlug/:transactionId/step1'
  );
  const location = useLocation();
  const navigate = useNavigate();

  const { data: meData } = useQuery(meQuery());
  const { data: customerData } = useQuery(
    customerQuery(
      transactionId,
      meData?.me?.user?.id ?? undefined,
      dealershipSlug
    )
  );

  useEnsureLanguage(transactionId, dealershipSlug);

  useEffect(() => {
    const custStep = customerData?.customer?.onboardingStep;

    if (custStep === OnboardingStep.ONBOARDING_COMPLETE) {
      // Redirect to the correct step
      navigate('/dashboard/:dealershipSlug/:transactionId', {
        params: {
          dealershipSlug,
          transactionId
        }
      });
      return;
    }

    if (
      custStep === OnboardingStep.STEP1 &&
      !location.pathname.endsWith('step1')
    ) {
      // Redirect to the correct step
      navigate('/onboarding/:dealershipSlug/:transactionId/step1', {
        params: {
          dealershipSlug,
          transactionId
        }
      });
      return;
    }

    if (
      custStep === OnboardingStep.STEP2 &&
      !location.pathname.endsWith('step2')
    ) {
      // Redirect to the correct step
      navigate('/onboarding/:dealershipSlug/:transactionId/step2', {
        params: {
          dealershipSlug,
          transactionId
        }
      });
      return;
    }
  }, [customerData?.customer?.onboardingStep, location.pathname]);
  return (
    <>
      <Outlet />
    </>
  );
}
