import { useForm } from '@tanstack/react-form-old';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import createAddressAction from '~/actions/addresses/createAddressAction';
import updateAddressAction from '~/actions/addresses/updateAddressAction';
import updateCustomerAction from '~/actions/customers/updateCustomerAction';
import updateMyUserAction from '~/actions/updateMyUserAction';
import BinaryQuestion from '~/components/BinaryQuestion';
import Button from '~/components/Button';
import Header from '~/components/Header';
import DropDown from '~/components/inputs/DropDown';
import PhoneNumberInput from '~/components/inputs/PhoneNumberInput';
import TextInput from '~/components/inputs/TextInput';
import Spinner from '~/components/Spinner';
import stateOptions from '~/config/formSelectionOptions/stateOptions';
import { g } from '~/globals';
import { isBuyer } from '~/lib/onboarding';
import customerQuery from '~/queries/customerQuery';
import meQuery, { resetMeQuery } from '~/queries/meQuery';
import transactionQuery from '~/queries/transactionQuery';
import { AddressType } from '~/querySelectors/address';
import { CustomerType } from '~/querySelectors/customer';
import { UserType } from '~/querySelectors/userSelector';
import { useNavigate, useParams } from '~/router';

const DashboardPersonalInformationForm: FC<{
  isBuyer: boolean;
  user?: UserType;
  customer?: Pick<CustomerType, 'firstName' | 'lastName'>;
  residentialAddress?: AddressType;
  vehicleRegistrationAddress?: AddressType;
  wantsRegistrationAtSameAddress?: boolean;
  isSubmitting: boolean;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  isBuyer,
  user,
  customer,
  residentialAddress,
  vehicleRegistrationAddress,
  wantsRegistrationAtSameAddress,
  isSubmitting,
  setIsSubmitting
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { transactionId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/personalInformation'
  );

  const handleCancel = () => {
    navigate('/dashboard/:dealershipSlug/:transactionId', {
      params: { transactionId, dealershipSlug }
    });
  };
  const form = useForm({
    defaultValues: {
      firstName: customer?.firstName ?? '',
      lastName: customer?.lastName ?? '',
      email: user?.email ?? '',
      phoneNumber: user?.phoneNumber ?? '',
      residentialAddressStreet: residentialAddress?.street ?? '',
      residentialAddressApartmentDetails:
        residentialAddress?.apartmentDetails ?? '',
      residentialAddressZipCode: residentialAddress?.zipCode ?? '',
      residentialAddressCity: residentialAddress?.city ?? '',
      residentialAddressState: residentialAddress?.state ?? '',
      wantsRegistrationAtSameAddress: !!wantsRegistrationAtSameAddress,
      vehicleRegistrationAddressStreet:
        vehicleRegistrationAddress?.street ?? '',
      vehicleRegistrationAddressApartmentDetails:
        vehicleRegistrationAddress?.apartmentDetails ?? '',
      vehicleRegistrationAddressZipCode:
        vehicleRegistrationAddress?.zipCode ?? '',
      vehicleRegistrationAddressCity: vehicleRegistrationAddress?.city ?? '',
      vehicleRegistrationAddressState: vehicleRegistrationAddress?.state ?? ''
    },
    onSubmit: async (values) => {
      if (!residentialAddress) {
        throw new Error('No residential address');
      }

      setIsSubmitting(true);
      await updateMyUserAction({
        firstName: values.firstName,
        lastName: values.lastName
      });
      await updateCustomerAction({ dealershipSlug }, transactionId, {
        firstName: values.firstName,
        lastName: values.lastName,
        wantsRegistrationAtSameAddress: isBuyer // Only the buyer specifies the vehicle registration address, so it only makes sense to save the answer for the buyer
          ? values.wantsRegistrationAtSameAddress
          : undefined
      });
      await updateAddressAction(
        residentialAddress.id ?? 'no-address-id',
        { dealershipSlug },
        {
          street: values.residentialAddressStreet,
          apartmentDetails: values.residentialAddressApartmentDetails,
          zipCode: values.residentialAddressZipCode,
          city: values.residentialAddressCity,
          state: values.residentialAddressState
        }
      );

      // We have to save the vehicle registration address if the buyer doesn't want to register at the same address.
      // In case the buyer previously had a dedicated vehicle registration addresses and then wishes to register at his residential address,
      // we are not removing the vehicle registration address but instead the `wantsRegistrationAtSameAddress` is definitive.
      if (isBuyer && !values.wantsRegistrationAtSameAddress) {
        const addressValues = {
          street: values.vehicleRegistrationAddressStreet,
          apartmentDetails: values.vehicleRegistrationAddressApartmentDetails,
          zipCode: values.vehicleRegistrationAddressZipCode,
          city: values.vehicleRegistrationAddressCity,
          state: values.vehicleRegistrationAddressState
        };

        // If the buyer has an existing vehicle registration address, we update it
        if (vehicleRegistrationAddress) {
          await updateAddressAction(
            vehicleRegistrationAddress.id ?? 'no-address-id',
            { dealershipSlug },
            addressValues
          );
        } else {
          // We have to create a new vehicle registration address
          const newVehicleRegistrationAddress = await createAddressAction(
            addressValues,
            { dealershipSlug },
            transactionId
          );

          // We have to link the address object to the customer
          await updateCustomerAction({ dealershipSlug }, transactionId, {
            vehicleRegistrationAddressId: newVehicleRegistrationAddress?.id
          });
        }
      }

      await Promise.all([
        resetMeQuery(),
        g.reg.queryClient.resetQueries({
          queryKey: ['transaction', transactionId]
        })
      ]);

      navigate('/dashboard/:dealershipSlug/:transactionId', {
        params: { transactionId, dealershipSlug }
      });

      setIsSubmitting(false);
    }
  });

  return (
    <div className="overflow-y-scroll">
      <form.Provider>
        <form
          className="space-y-12"
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            void form.handleSubmit();
          }}
        >
          <div className="grid grid-cols-2 md:grid-cols-2 gap-4 items-end">
            <form.Field name="firstName">
              {(field) => {
                return (
                  <TextInput
                    fieldName={field.name}
                    value={field.state.value}
                    labelText={t('First Name')}
                    placeholder={t('First Name')}
                    subtitleText={t('First Name')}
                    required
                    disabled={isSubmitting}
                    error={field.state.meta.touchedErrors.at(0)}
                    onChange={(e) => {
                      field.handleChange(e.target.value);
                    }}
                  />
                );
              }}
            </form.Field>

            <form.Field name="lastName">
              {(field) => {
                return (
                  <TextInput
                    fieldName={field.name}
                    value={field.state.value}
                    labelText={t('Last Name')}
                    placeholder={t('Last Name')}
                    subtitleText={t('Last Name')}
                    required
                    disabled={isSubmitting}
                    error={field.state.meta.touchedErrors.at(0)}
                    onChange={(e) => {
                      field.handleChange(e.target.value);
                    }}
                  />
                );
              }}
            </form.Field>

            <form.Field name="email">
              {(field) => {
                return (
                  <TextInput
                    fieldName={field.name}
                    value={field.state.value}
                    isEmail
                    labelText={t('Email')}
                    placeholder={t('Email')}
                    subtitleText={t('Email')}
                    disabled
                    containerClassName="col-span-2 md:col-span-1"
                  />
                );
              }}
            </form.Field>

            <form.Field name="phoneNumber">
              {(field) => {
                return (
                  <PhoneNumberInput
                    fieldName={field.name}
                    value={field.state.value}
                    placeholder={t('Phone Number')}
                    subtitleText={t('Phone Number')}
                    onChange={(value) => {
                      field.handleChange(value?.toString() ?? '');
                    }}
                    onBlur={field.handleBlur}
                    disabled
                    containerClassName="col-span-2 md:col-span-1"
                  />
                );
              }}
            </form.Field>
          </div>

          <h2>{t('Current Address')}</h2>

          <div className="grid grid-cols-2 md:grid-cols-8 gap-4 items-end">
            <form.Field name="residentialAddressStreet">
              {(field) => {
                return (
                  <TextInput
                    fieldName={field.name}
                    value={field.state.value}
                    labelText="Street"
                    subtitleText="Street"
                    placeholder="Street"
                    required
                    disabled={isSubmitting}
                    error={field.state.meta.touchedErrors.at(0)}
                    onChange={(e) => {
                      field.handleChange(e.target.value);
                    }}
                    containerClassName="md:col-span-4"
                  />
                );
              }}
            </form.Field>

            <form.Field name="residentialAddressApartmentDetails">
              {(field) => {
                return (
                  <TextInput
                    fieldName={field.name}
                    value={field.state.value}
                    labelText={t('Apartment Details')}
                    subtitleText={t('Apartment Details')}
                    placeholder={t('Suite, Apt #')}
                    disabled={isSubmitting}
                    error={field.state.meta.touchedErrors.at(0)}
                    onChange={(e) => {
                      field.handleChange(e.target.value);
                    }}
                    containerClassName="md:col-span-4"
                  />
                );
              }}
            </form.Field>

            <form.Field name="residentialAddressZipCode">
              {(field) => {
                return (
                  <TextInput
                    fieldName={field.name}
                    value={field.state.value}
                    labelText={t('Zip Code')}
                    subtitleText={t('Zip Code')}
                    placeholder={t('Zip Code')}
                    required
                    disabled={isSubmitting}
                    error={field.state.meta.touchedErrors.at(0)}
                    onChange={(e) => {
                      field.handleChange(e.target.value);
                    }}
                    containerClassName="md:col-span-3"
                  />
                );
              }}
            </form.Field>

            <form.Field name="residentialAddressCity">
              {(field) => {
                return (
                  <TextInput
                    fieldName={field.name}
                    value={field.state.value}
                    labelText={t('City')}
                    subtitleText={t('City')}
                    placeholder={t('City')}
                    required
                    disabled={isSubmitting}
                    error={field.state.meta.touchedErrors.at(0)}
                    onChange={(e) => {
                      field.handleChange(e.target.value);
                    }}
                    containerClassName="md:col-span-2"
                  />
                );
              }}
            </form.Field>

            <form.Field name="residentialAddressState">
              {(field) => {
                return (
                  <DropDown
                    fieldName={field.name}
                    value={field.state.value}
                    options={stateOptions}
                    labelText={t('State')}
                    placeholder={t('State')}
                    subtitleText={t('State')}
                    required
                    disabled={isSubmitting}
                    error={field.state.meta.touchedErrors.at(0)}
                    onChange={(e) => {
                      field.handleChange(e.target.value);
                    }}
                    containerClassName="col-span-2 md:col-span-3"
                  />
                );
              }}
            </form.Field>
          </div>

          {isBuyer && (
            <Fragment>
              <h2>{t('Vehicle Registration Address')}</h2>

              <form.Field name="wantsRegistrationAtSameAddress">
                {(field) => {
                  return (
                    <BinaryQuestion
                      inputId={field.name}
                      selectedYes={field.state.value}
                      question={t(
                        'Would you be registering the vehicle at your residential address?'
                      )}
                      onClickYes={() => {
                        field.setValue(true);
                      }}
                      onClickNo={() => {
                        field.setValue(false);
                      }}
                      disabled={isSubmitting}
                    />
                  );
                }}
              </form.Field>

              <form.Subscribe
                selector={(state) =>
                  state.values.wantsRegistrationAtSameAddress
                }
              >
                {(wantsRegistrationAtSameAddress) => {
                  return (
                    <div
                      className={classNames(
                        'grid grid-cols-2 md:grid-cols-8 gap-4 items-end',
                        { hidden: wantsRegistrationAtSameAddress }
                      )}
                    >
                      <form.Field name="vehicleRegistrationAddressStreet">
                        {(field) => {
                          return (
                            <TextInput
                              fieldName={field.name}
                              value={field.state.value}
                              labelText={t('Street')}
                              subtitleText={t('Street')}
                              placeholder={t('Street')}
                              required
                              disabled={isSubmitting}
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) => {
                                field.handleChange(e.target.value);
                              }}
                              containerClassName="md:col-span-4"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="vehicleRegistrationAddressApartmentDetails">
                        {(field) => {
                          return (
                            <TextInput
                              fieldName={field.name}
                              value={field.state.value}
                              labelText={t('Apartment Details')}
                              subtitleText={t('Apartment Details')}
                              placeholder={t('Suite, Apt #')}
                              disabled={isSubmitting}
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) => {
                                field.handleChange(e.target.value);
                              }}
                              containerClassName="md:col-span-4"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="vehicleRegistrationAddressZipCode">
                        {(field) => {
                          return (
                            <TextInput
                              fieldName={field.name}
                              value={field.state.value}
                              labelText={t('Zip Code')}
                              subtitleText={t('Zip Code')}
                              placeholder={t('Zip Code')}
                              required
                              disabled={isSubmitting}
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) => {
                                field.handleChange(e.target.value);
                              }}
                              containerClassName="md:col-span-3"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="vehicleRegistrationAddressCity">
                        {(field) => {
                          return (
                            <TextInput
                              fieldName={field.name}
                              value={field.state.value}
                              labelText={t('City')}
                              subtitleText={t('City')}
                              placeholder={t('City')}
                              required
                              disabled={isSubmitting}
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) => {
                                field.handleChange(e.target.value);
                              }}
                              containerClassName="md:col-span-2"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="vehicleRegistrationAddressState">
                        {(field) => {
                          return (
                            <DropDown
                              fieldName={field.name}
                              value={field.state.value}
                              options={stateOptions}
                              labelText={t('State')}
                              placeholder={t('State')}
                              subtitleText={t('State')}
                              required
                              disabled={isSubmitting}
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) => {
                                field.handleChange(e.target.value);
                              }}
                              containerClassName="col-span-2 md:col-span-3"
                            />
                          );
                        }}
                      </form.Field>
                    </div>
                  );
                }}
              </form.Subscribe>
            </Fragment>
          )}

          <div className="flex flex-row justify-between">
            <Button
              variant="TERTIARY"
              disabled={isSubmitting}
              onClick={handleCancel}
            >
              {t('Cancel')}
            </Button>

            <form.Subscribe selector={(state) => [state.values]}>
              {([values]) => {
                const hasAllPersonalInformation =
                  values.firstName.length && values.lastName.length;
                const hasAllResidentialAddressInformation =
                  values.residentialAddressStreet.length &&
                  values.residentialAddressZipCode.length &&
                  values.residentialAddressCity.length &&
                  values.residentialAddressState.length;
                const hasAllVehicleRegistrationAddressInformation =
                  values.vehicleRegistrationAddressStreet.length &&
                  values.vehicleRegistrationAddressZipCode.length &&
                  values.vehicleRegistrationAddressCity.length &&
                  values.vehicleRegistrationAddressState.length;
                const canContinue =
                  hasAllPersonalInformation &&
                  hasAllResidentialAddressInformation &&
                  (!isBuyer ||
                    values.wantsRegistrationAtSameAddress ||
                    hasAllVehicleRegistrationAddressInformation);

                return (
                  <Button
                    type="submit"
                    loading={isSubmitting}
                    disabled={!canContinue}
                  >
                    {t('Save')}
                  </Button>
                );
              }}
            </form.Subscribe>
          </div>
        </form>
      </form.Provider>
    </div>
  );
};

const DashboardPersonalInformationPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { transactionId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/personalInformation'
  );
  const { data: meData, isLoading: loadingMeData } = useQuery(meQuery());
  const { data: transactionData, isLoading: loadingTransactionData } = useQuery(
    transactionQuery(transactionId, dealershipSlug)
  );
  const isB = isBuyer(meData, transactionData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleCancel = () => {
    navigate('/dashboard/:dealershipSlug/:transactionId', {
      params: { transactionId, dealershipSlug }
    });
  };
  const { data: customerData } = useQuery(
    customerQuery(
      transactionId,
      meData?.me?.user?.id ?? undefined,
      dealershipSlug
    )
  );

  const addresses = customerData?.customer?.residentialAddresses;
  const currentAddress = addresses?.edges?.find(
    (e) => e.node?.timelinePosition === 0
  )?.node;

  // TODO: I am not entirely sure if this is correct from the co-buyer view
  const vehicleRegistrationAddress =
    customerData?.customer?.vehicleRegistrationAddress;

  return (
    <div className="flex flex-col h-dvh">
      <Header
        title={t('Personal Information')}
        leftElement={
          <button
            className=" text-primary-brand"
            onClick={handleCancel}
            disabled={isSubmitting}
          >
            {t('Cancel')}
          </button>
        }
      />

      <div className="flex flex-col items-center overflow-y-scroll px-4">
        <div className="flex flex-col max-w-screen-md py-10 md:py-20 lg:py-40 space-y-16 w-full">
          <div className="space-y-5">
            <h1 className="text-title">{t('Personal Information')}</h1>

            <h1>
              {t(
                'Please fill in or verify the information in the fields below.'
              )}
            </h1>
          </div>

          {(loadingMeData || loadingTransactionData) && (
            <div className="flex w-full aspect-square justify-center items-center relative">
              <Spinner className="w-10 aspect-square" />
            </div>
          )}

          {!loadingMeData && !loadingTransactionData && (
            <DashboardPersonalInformationForm
              isBuyer={!!isB}
              user={meData?.me?.user}
              customer={customerData?.customer}
              residentialAddress={currentAddress}
              vehicleRegistrationAddress={vehicleRegistrationAddress}
              isSubmitting={isSubmitting}
              setIsSubmitting={setIsSubmitting}
              wantsRegistrationAtSameAddress={
                // TODO: I am not entirely sure if this is correct from a co-buyer view
                customerData?.customer?.wantsRegistrationAtSameAddress ??
                undefined
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardPersonalInformationPage;
