import { g } from '~/globals';
import { HttpClient } from './browser';

export interface PdfHelpersInterface {
  prefetch(): Promise<void>;
  getFont(): Promise<Uint8Array>;
}

export class PdfHelpers {
  private queryKey = ['pdfFonts', 'helvetica'];

  private loadedFont?: Uint8Array;

  constructor(private httpClient: HttpClient) {}

  /**
   * This function will ensure that the font request is prefetched
   */
  async prefetch(): Promise<void> {
    const client = this.httpClient;
    await g.reg.queryClient.prefetchQuery({
      queryKey: this.queryKey,
      queryFn: async () =>
        await client.get('/fonts/helvetica.ttf', {
          responseType: 'arraybuffer'
        })
    });
  }

  /**
   * Loads fonts needed by the PDF generation library
   *
   * There is a bit of double confetti on both caching using tanstack query and also manually in this class.
   * We are in the infancy of using OOP in the frontends, so this is very much up for discussion later on.
   *
   * @returns Uint8Array form of font
   */
  async getFont(): Promise<Uint8Array> {
    if (this.loadedFont) {
      return this.loadedFont;
    }

    // We have seen examples of PDF files that can not be read.
    // our assumption is that it is because the font file has not been loaded correctly
    // to mitigate that we have 5 retries _and_ we throw and error if do we not succeed

    const client = this.httpClient;
    const response = await g.reg.queryClient.fetchQuery({
      queryKey: this.queryKey,
      retry: 5,
      queryFn: async () =>
        await client.get('/fonts/helvetica.ttf', {
          responseType: 'arraybuffer'
        })
    });

    let arrayBuffer: ArrayBuffer = response.data;

    if (!arrayBuffer) {
      throw new Error('Could not load font');
    }

    // Save it directly on the class
    this.loadedFont = new Uint8Array(arrayBuffer);
    return this.loadedFont;
  }
}
