import { Dialog, Transition } from '@headlessui/react';
import { captureException } from '@sentry/react';
import { useForm } from '@tanstack/react-form';
import { useQuery } from '@tanstack/react-query';
import { zodValidator, ZodValidator } from '@tanstack/zod-form-adapter';
import { Button, Spinner, TextInput } from '@thedealersconcierge/components';
import { PrequalFormSchema } from '@thedealersconcierge/lib/codecs/schema/prequalApplication';
import { useAtom } from 'jotai';
import { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { cognitoVerifyOtpAction } from '~/actions/auth/cognitoVerifyOtpAction';
import verifyOtpResponseAction from '~/actions/auth/verifyOtpResponseAction';
import Modal from '~/components/Modal';
import PrequalForm, {
  PrequalFormValues,
  ValidPrequalFormSchema
} from '~/components/forms/PrequalForm';
import CircleCheckMarkIcon from '~/components/icons/CircleCheckMarkIcon';
import config from '~/config';
import { gqlMutationClient, gqlQueryClient } from '~/lib/backend';
import dealershipQuery from '~/queries/dealershipQuery';
import { useParams } from '~/router';
import { authStateAtom } from '~/state/auth';
import submitWebPreQualApplicationWorkflow from '~/workflows/formSubmissions/prequal/submitWebPreQualApplicationWorkflow';

const Form: FC<{
  dealershipId: string;
  dealershipSlug: string;
  dealershipName: string;
  ipAddress: string;
  onSubmittedFormValues: (
    value: PrequalFormValues,
    formData: PrequalFormSchema
  ) => void;
  onSubmittedPrequal: () => void;
  onRequestedOtp: () => void;
}> = ({
  dealershipId,
  dealershipSlug,
  dealershipName,
  ipAddress,
  onSubmittedFormValues,
  onSubmittedPrequal,
  onRequestedOtp
}) => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const form = useForm<PrequalFormValues, ZodValidator>({
    defaultValues: {
      firstName: '',
      middleName: '',
      lastName: '',
      socialSecurityNumber: '',
      birthdate: '',
      street: '',
      apartmentDetails: '',
      city: '',
      state: '',
      zip: '',
      phoneNumber: '',
      email: '',
      confirmNotAffectCreditScoreAndPrequalConsented: false,
      acknowledgesElectronicConsent: false,
      hasCommunicationsConsented: false,
      signature: ''
    },
    validators: {
      onSubmit: ValidPrequalFormSchema(t)
    },
    validatorAdapter: zodValidator(),
    onSubmit: async ({ value }) => {
      try {
        setIsSubmitting(true);

        await submitWebPreQualApplicationWorkflow({
          dealershipId,
          value,
          ipAddress,
          onSubmittedFormValues,
          onSubmittedPrequal,
          onRequestedOtp
        });
      } catch (error) {
        toast.error('An unexpected error happened');
        console.error(error);
        captureException(error);
      } finally {
        setIsSubmitting(false);
      }
    }
  });

  return (
    <div className="flex flex-col items-center">
      <PrequalForm
        environment="WEB"
        form={form}
        isSubmitting={isSubmitting}
        dealershipSlug={dealershipSlug}
        dealershipName={dealershipName}
        onSubmit={() => {
          void form.handleSubmit();
        }}
        dataTestId="web-prequal"
      />
    </div>
  );
};

export default function WebPrequalPage() {
  const { dealershipSlug } = useParams('/prequal/:dealershipSlug');
  const { t } = useTranslation();
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  // Error when logging in/verifying OTPs
  const [loginError, setLoginError] = useState<string | null>(null);
  const [loggingIn, setLoggingIn] = useState(false);
  const [code, setCode] = useState('');
  const [, setAuthState] = useAtom(authStateAtom);
  const { data: dealership, isLoading } = useQuery(
    dealershipQuery(dealershipSlug ? { dealershipSlug } : undefined)
  );
  const { data: publicData } = useQuery({
    queryKey: ['public'],

    // We don't expect that this page will change between window switches.
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryFn: () => {
      return gqlQueryClient({ dealershiplessAuth: true })({
        public: {
          ipAddress: true
        }
      });
    }
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  // We use this to commit the form values when logging in
  // This is more convenience as we could have read the values out of the form
  // but it is darn ugly with all the getter functions we'd have to call
  const [submittedFormValues, setSubmittedFormValues] = useState<
    [PrequalFormValues, PrequalFormSchema] | null
  >(null);
  const submitOtp = async (email: string, otp: string) => {
    try {
      setLoginError(null);
      setLoggingIn(true);

      if (config.useCognito) {
        await cognitoVerifyOtpAction(otp);
      } else {
        const verifyOtpResponse = await verifyOtpResponseAction(email, otp);
        if (!verifyOtpResponse.jwt) {
          throw new Error('No JWT returned');
        }
        setAuthState({ jwt: verifyOtpResponse.jwt });
      }

      if (!submittedFormValues) {
        throw new Error('No form data was submitted');
      }

      if (!dealership?.dealership?.id) {
        throw new Error('Failed fetching dealership');
      }

      const [value, formData] = submittedFormValues;
      const data = await gqlMutationClient({
        dealershipId: dealership.dealership.id
      })({
        submitPrequalApplicationAndCreateUser: [
          {
            dealershipId: dealership?.dealership?.id ?? 'no-dealership-fetched',
            email: value.email,
            phoneNumber: value.phoneNumber,
            formData: formData
          },
          {
            __typename: true,
            '...on GraphQLError': {
              message: true
            },
            '...on MutationSubmitPrequalApplicationAndCreateUserSuccess': {
              data: {
                status: true
              }
            }
          }
        ]
      });

      if (
        data.submitPrequalApplicationAndCreateUser?.__typename !==
        'MutationSubmitPrequalApplicationAndCreateUserSuccess'
      ) {
        throw new Error(
          data.submitPrequalApplicationAndCreateUser?.message ?? undefined
        );
      }

      // All is good, full success
      setAuthState(null);
      setIsOtpModalOpen(false);
      setIsSubmitted(true);
    } catch (e) {
      console.error(e);
      captureException(e);
      setLoginError('Could not login');
    }
  };

  // When designing this, please take into account that it is being shown in an iFrame.
  return (
    <>
      <Modal
        isOpen={isOtpModalOpen}
        title={t('Verification')}
        className="p-6 mx-10"
        // We allow no control for the component
        onClose={() => {}}
      >
        <div className="flex flex-col items-center space-y-6">
          <div className="space-y-4">
            <p>
              {t(
                'Our records show that you already hold an account. We have sent a verification code to your phone and email.'
              )}
            </p>

            <div className="space-y-2">
              <TextInput
                value={code}
                placeholder={t('Code')}
                label={t('Code')}
                required
                disabled={loggingIn}
                onChange={setCode}
              />
            </div>
          </div>

          {loginError && (
            <div className="flex bg-negative-primary w-full py-1 rounded-md text-primary-inverse  items-center justify-center">
              <p>{loginError}</p>
            </div>
          )}

          <Button
            label="Submit"
            dataTestId="prequal-create-submit-otp"
            onClick={() => {
              void submitOtp(
                submittedFormValues?.[0].email ?? 'email-not-available',
                code
              );
            }}
            disabled={code.length !== 6}
            isLoading={loggingIn}
          />
        </div>
      </Modal>

      {isSubmitted && (
        <Transition appear show={isSubmitted} as={Fragment}>
          {/* Can not close */}
          <Dialog as="div" className="relative z-30" onClose={() => null}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel
                    className={
                      'w-full max-w-md transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all p-6'
                    }
                  >
                    <div
                      className="py-4 text-center text-heading-3"
                      data-test-id="web-prequal-success"
                    >
                      <Dialog.Title className="">
                        Your Prequalification Application Was Submitted
                        Successfully!
                      </Dialog.Title>
                    </div>

                    <p className="flex justify-center">
                      <CircleCheckMarkIcon className="text-[#219653] w-8 h-8" />
                    </p>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      )}

      {isLoading && (
        <div className="flex w-screen h-screen items-center justify-center">
          <Spinner size="LARGE" color="GREY" />
        </div>
      )}

      {!isLoading && !dealership?.dealership?.hasEnabledPrequalFormService && (
        <div className="flex w-screen h-screen items-center justify-center">
          <h1>No such dealership</h1>
        </div>
      )}

      {!isLoading &&
        Boolean(dealership?.dealership?.hasEnabledPrequalFormService) &&
        dealership?.dealership?.id &&
        dealership.dealership.slug &&
        dealership.dealership.name && (
          <Form
            dealershipId={dealership.dealership.id}
            dealershipSlug={dealership.dealership.slug}
            dealershipName={dealership.dealership.name}
            onSubmittedFormValues={(value, formData) => {
              setSubmittedFormValues([value, formData]);
            }}
            onSubmittedPrequal={() => {
              setIsSubmitted(true);
            }}
            onRequestedOtp={() => {
              setIsOtpModalOpen(true);
            }}
            ipAddress={publicData?.public?.ipAddress ?? 'unknown'}
          />
        )}
    </>
  );
}
