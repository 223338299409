import { QueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { createStore } from 'jotai';
import {
  BrowserInfo,
  BrowserInterface,
  HttpClient,
  NullBrowserInfo
} from './service/browser';
import { PdfHelpers, PdfHelpersInterface } from './service/pdf';
import { TdcBackendClient } from './service/tdc-backend-client';

/**
 * Most times we call the backend, we need to provide information about the dealership
 * sometimes we use slug other times ID, it changes
 * This is a structurally sound way to provide it - and also not inadvertedly give the wrong value
 */
export type DealershipIdentifier =
  | { dealershipId: string }
  | { dealershipSlug: string };

type Container = {
  httpClient: HttpClient;
  tdcBackendClient: TdcBackendClient;
  browser: BrowserInterface;
  pdfHelper: PdfHelpersInterface;
  jotaiStore: ReturnType<typeof createStore>;
  queryClient: QueryClient;
};

// This is the predecessor before full dependency injection
export const g: { reg: Container } = {
  reg: {
    httpClient: axios,
    tdcBackendClient: new TdcBackendClient(),
    browser: new NullBrowserInfo(),
    pdfHelper: new PdfHelpers(axios),
    jotaiStore: createStore(),
    queryClient: new QueryClient()
  }
};

/**
 * This sets the browser info when running the app
 */
export const mkProductionRegistry = () => {
  g.reg = {
    httpClient: axios,
    tdcBackendClient: new TdcBackendClient(),
    browser: new BrowserInfo(),
    pdfHelper: new PdfHelpers(axios),
    jotaiStore: createStore(),
    queryClient: new QueryClient()
  };
};
