import { PDFDocument, PageSizes } from 'pdf-lib';

export const convertImageToPDF = async (imageFile: File): Promise<File> => {
  const pdfDoc = await PDFDocument.create();
  const arrayBuffer = await imageFile.arrayBuffer();
  const uint8Array = new Uint8Array(arrayBuffer);

  let image;
  if (imageFile.type === 'image/png') {
    image = await pdfDoc.embedPng(uint8Array);
  } else if (imageFile.type === 'image/jpeg') {
    image = await pdfDoc.embedJpg(uint8Array);
  } else {
    throw new Error('Unsupported image type');
  }

  // A4 page size in points, with a scaling factor of 80%
  const pageSize = PageSizes.A4;
  const pageScaleFactor = 0.95; // Scale to 90% of the page
  const scaledPageSize = [
    pageSize[0] * pageScaleFactor,
    pageSize[1] * pageScaleFactor
  ];

  const page = pdfDoc.addPage(pageSize);

  // Calculate the scale to make the image fit within the scaled page dimensions
  const scaleX = scaledPageSize[0] / image.width;
  const scaleY = scaledPageSize[1] / image.height;
  const scale = Math.min(scaleX, scaleY);

  // Calculate new image dimensions
  const imgWidth = image.width * scale;
  const imgHeight = image.height * scale;

  // Calculate position to center the image on the page
  const xPosition = (pageSize[0] - imgWidth) / 2;
  const yPosition = (pageSize[1] - imgHeight) / 2;

  page.drawImage(image, {
    x: xPosition,
    y: yPosition,
    width: imgWidth,
    height: imgHeight
  });

  const pdfBytes = await pdfDoc.save();
  const blob = new Blob([pdfBytes], { type: 'application/pdf' });
  const originalFileName = imageFile.name;
  const baseFileName = originalFileName.replace(/\.[^/.]+$/, '');
  const pdfFileName = `${baseFileName}.pdf`;

  const pdfFile = new File([blob], pdfFileName, {
    type: 'application/pdf',
    lastModified: new Date().getTime()
  });

  return pdfFile;
};
