import { useQuery } from '@tanstack/react-query';
import parsePhoneNumber from 'libphonenumber-js';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import logoutAction from '~/actions/logoutAction';
import Button from '~/components/Button';
import Spinner from '~/components/Spinner';
import dealershipQuery from '~/queries/dealershipQuery';
import meQuery from '~/queries/meQuery';
import transactionQuery from '~/queries/transactionQuery';
import { useNavigate, useParams } from '~/router';
import ConsumerDashboardHeader from './_components/ConsumerDashboardHeader';
import PersonalInformationSection from './_components/PersonalInformationSection';
import TransactionDataSection from './_components/TransactionDataSection';

const DashboardPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { transactionId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId'
  );
  const [loggingOut, setLoggingOut] = useState(false);
  const dealership = useQuery(
    dealershipQuery(dealershipSlug ? { dealershipSlug } : undefined)
  );

  const { data: transactionQueryData, isLoading } = useQuery(
    transactionQuery(transactionId, dealershipSlug)
  );
  const [selectedDealershipId] = useState<string | undefined>(undefined);
  const { data } = useQuery(meQuery());
  const dealershipId = dealership.data?.dealership?.id;
  const dealerships = data?.me?.dealerships;
  const profileData = data?.me?.user;

  const selectedDealership = dealerships?.find((d) => {
    return (
      d.id === selectedDealershipId ||
      (!selectedDealershipId && dealershipId && d.id === dealershipId)
    );
  });

  const transaction = transactionQueryData?.transaction;
  // const buyer = transactionQueryData?.transaction.buyer;
  // const coBuyer = transactionQueryData?.transaction.coBuyer;
  const isCoBuyer =
    !!data?.me?.user?.id && transaction?.coBuyerId === data.me.user.id;

  const vehicle = transactionQueryData?.transaction?.vehicle;
  const tradeVehicle = transactionQueryData?.transaction?.tradeVehicle;
  const handleGoToPersonalInformation = () => {
    navigate('/dashboard/:dealershipSlug/:transactionId/personalInformation', {
      params: { transactionId, dealershipSlug }
    });
  };

  const handleGoToPurchaseVehicle = () => {
    navigate('/dashboard/:dealershipSlug/:transactionId/purchase-vehicle', {
      params: {
        transactionId,
        dealershipSlug
      }
    });
  };

  const handleLogout = () => {
    setLoggingOut(true);
    logoutAction();
  };

  return (
    <div data-test-id={'consumer-dashboard-body'}>
      <ConsumerDashboardHeader
        leftElement={
          <div className="relative">
            <img src="/logo.png" className="h-4" aria-label="Logo" />
          </div>
        }
        centerElement={<h3>{selectedDealership?.name ?? 'Dealership'}</h3>}
        rightElement={
          <>
            {/* This is not the right way, but currently it is the easiest way to make the buttons look the same
            
            TODO: Use the <Link /> component
            */}
            <Button
              variant="TEXT_ONLY"
              onClick={() => {
                navigate('/user/security');
              }}
              loading={loggingOut}
              className="!py-0 pr-0 h-fit"
            >
              <span className="text-secondary">{t('Settings')}</span>
            </Button>
            <Button
              variant="TEXT_ONLY"
              onClick={() => {
                handleLogout();
              }}
              loading={loggingOut}
              className="!py-0 pr-0 h-fit"
            >
              <span className="text-secondary">{t('Logout')}</span>
            </Button>
          </>
        }
      />

      {transaction && (
        <PersonalInformationSection
          transactionRole={isCoBuyer ? 'CO_BUYER' : 'BUYER'}
          transaction={transaction}
          isBuyer={!isCoBuyer}
          name={
            (!isCoBuyer
              ? transactionQueryData.transaction?.customerSharedData?.buyerName
              : transactionQueryData.transaction?.customerSharedData
                  ?.coBuyerName) ?? '-'
          }
          phoneNumber={
            profileData?.phoneNumber
              ? (parsePhoneNumber(profileData.phoneNumber)?.format(
                  parsePhoneNumber(profileData.phoneNumber)?.country === 'US'
                    ? 'NATIONAL'
                    : 'INTERNATIONAL'
                ) ?? '-')
              : '-'
          }
          email={profileData?.email ?? '-'}
          onEditPersonalInformation={handleGoToPersonalInformation}
        />
      )}

      {isLoading || !transaction ? (
        <div className="flex justify-center pt-10">
          <Spinner />
        </div>
      ) : (
        <TransactionDataSection
          transaction={transaction}
          transactionRole={isCoBuyer ? 'CO_BUYER' : 'BUYER'}
          selectedTransactionId={transactionId}
          tradeVehicle={tradeVehicle}
          purchaseVehicle={vehicle}
          onEditPurchaseVehicle={handleGoToPurchaseVehicle}
          onAddPurchaseVehicle={handleGoToPurchaseVehicle}
        />
      )}
    </div>
  );
};

export default DashboardPage;
