import { useAtomValue } from 'jotai';
import { Outlet } from 'react-router';

import Spinner from '@thedealersconcierge/components/src/spinner';
import { Suspense } from 'react';
import { kioskDealershipAtom } from '~/state/kiosk';
import AuthCheckerHook from './_components/AuthCheckerHook';
import LogoutTimer from './_components/LogoutTimer';

export default function Layout() {
  const kioskMode = useAtomValue(kioskDealershipAtom);
  return (
    <Suspense
      fallback={
        <div className="flex size-screen items-center justify-center">
          <Spinner size="LARGE" color="GREY" />
        </div>
      }
    >
      {/* Only logged in users are allows here */}
      <AuthCheckerHook />
      <Outlet />
      {kioskMode && <LogoutTimer />}
    </Suspense>
  );
}
