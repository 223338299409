import { z } from "zod";

const UserLoggedInEventPersistedSchema = z.object({
  type: z.literal("LOGGED_IN"),
});

const UserUpdatedEmailEventPersistedSchema = z.object({
  type: z.literal("UPDATED_EMAIL"),
  previousEmail: z.string(),
  newEmail: z.string(),
});

const UserUpdatedPhoneNumberEventPersistedSchema = z.object({
  type: z.literal("UPDATED_PHONE_NUMBER"),
  previousPhoneNumber: z.string(),
  newPhoneNumber: z.string(),
});

const UserCreatedPhoneNumberEventPersistedSchema = z.object({
  type: z.literal("CREATED"),
});

export const UserAuditLogPersistedSchema = z.union([
  UserLoggedInEventPersistedSchema,
  UserUpdatedEmailEventPersistedSchema,
  UserUpdatedPhoneNumberEventPersistedSchema,
  UserCreatedPhoneNumberEventPersistedSchema,
]);

export type UserAuditLogPersistedSchema = z.TypeOf<
  typeof UserAuditLogPersistedSchema
>;
