import { flexRender, Table as ReactTableInstance } from '@tanstack/react-table';
import classNames from 'classnames';
import { z } from 'zod';

type TableProps<T> = {
  table: ReactTableInstance<T>;
  rowOnClick?: (row: T) => void;
  dataTestId?: string;
};

// We make the column meta into Zod Schema so we can safely work with the column meta data
// This also done to avoid module level alteration, check:
// https://tanstack.com/table/v8/docs/api/core/column-def#meta
export const TableColumnMetaSchema = z
  .object({
    isHeaderCentered: z.boolean().optional(),
    isCellCentered: z.boolean().optional(),
    hasLeftBorder: z.boolean().optional(),
    hasRightBorder: z.boolean().optional()
  })
  .optional();

const Table = <T,>({ table, rowOnClick, dataTestId }: TableProps<T>) => {
  const numberOfStickyHeaders =
    table
      .getHeaderGroups()
      .at(0)
      ?.headers.filter((h) => h.column.getIsPinned()).length ?? 0;
  return (
    <div
      className="rounded-radius-02 overflow-hidden shadow-md"
      data-test-id={dataTestId}
    >
      <div className="relative overflow-x-auto flex flex-gow">
        <table
          className="table-fixed bg-white min-w-full overflow-y-scroll overflow-x-auto body-02 border-separate border-spacing-0"
          {...{
            style: {
              width: table.getCenterTotalSize()
            }
          }}
          data-test-id={`${dataTestId}-table`}
        >
          <thead className="rounded-radius-02 border-b">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                className="border-b border-secondary"
                data-test-id={`${dataTestId}-header-tr`}
              >
                {headerGroup.headers.map((header) => {
                  const meta = TableColumnMetaSchema.parse(
                    header.column.columnDef.meta
                  );
                  const pinnedColumns = table
                    .getVisibleLeafColumns()
                    .filter((col) => col.getIsPinned());
                  const cumulativeWidth = pinnedColumns
                    .slice(
                      0,
                      pinnedColumns.findIndex(
                        (col) => col.id === header.column.id
                      )
                    )
                    .reduce((sum, col) => sum + col.getSize(), 0);

                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className={classNames(
                        'bg-white cursor-pointer border-b border-secondary',
                        {
                          'sticky left-0 z-sticky': header.column.getIsPinned(),
                          'border-r border-secondary':
                            numberOfStickyHeaders - 1 === header.index ||
                            meta?.hasRightBorder,
                          'border-l border-secondary': meta?.hasLeftBorder
                        }
                      )}
                      style={{
                        left: header.column.getIsPinned()
                          ? `${cumulativeWidth}px`
                          : undefined,
                        width: header.column.getSize()
                      }}
                      data-test-id={`${dataTestId}-th`}
                    >
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            className: classNames(
                              'relative flex w-full h-full px-spacing-04 items-center text-start text-primary py-spacing-03 text-heading-04 font-semibold',
                              {
                                'cursor-pointer select-none':
                                  header.column.getCanSort(),
                                'flex flex-row items-center justify-center w-full':
                                  meta?.isHeaderCentered
                              }
                            ),
                            onClick: header.column.getToggleSortingHandler(),
                            colSpan: header.colSpan
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}

                          {{
                            asc: (
                              <div className="absolute flex top-0 right-4 bottom-0 items-center">
                                ↑
                              </div>
                            ),
                            desc: (
                              <div className="absolute flex top-0 right-4 bottom-0 items-center">
                                ↓
                              </div>
                            )
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>

          {/* TBODY */}
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                className={classNames('cursor-pointer hover:bg-tertiary', {
                  'bg-secondary': row.index % 2 === 0,
                  'bg-white': row.index % 2 === 1
                })}
                onClick={() => {
                  if (rowOnClick) rowOnClick(row.original);
                }}
                data-test-id={`${dataTestId}-row-${row.id}`}
              >
                {row.getVisibleCells().map((cell, index) => {
                  const meta = TableColumnMetaSchema.parse(
                    cell.column.columnDef.meta
                  );

                  const pinnedColumns = table
                    .getVisibleLeafColumns()
                    .filter((col) => col.getIsPinned());
                  const cumulativeWidth = pinnedColumns
                    .slice(
                      0,
                      pinnedColumns.findIndex(
                        (col) => col.id === cell.column.id
                      )
                    )
                    .reduce((sum, col) => sum + col.getSize(), 0);
                  return (
                    <td
                      key={cell.id}
                      className={classNames({
                        'sticky z-sticky': cell.column.getIsPinned(),

                        'bg-secondary': row.index % 2 === 0,
                        'bg-white': row.index % 2 === 1,

                        'border-r border-secondary':
                          numberOfStickyHeaders - 1 === index ||
                          meta?.hasRightBorder,
                        'border-l border-secondary': meta?.hasLeftBorder
                      })}
                      style={{
                        left: cell.column.getIsPinned()
                          ? `${cumulativeWidth}px`
                          : undefined,
                        width: cell.column.getSize()
                      }}
                      data-test-id={`${dataTestId}-td`}
                    >
                      <div
                        className={classNames(
                          'flex items-center px-spacing-04 text-primary text-body-2 w-full h-[48px]',
                          {
                            'flex flex-row items-center justify-center w-full':
                              meta?.isHeaderCentered
                          }
                        )}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </div>
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
