import { captureException } from '@sentry/react';
import { useForm } from '@tanstack/react-form';
import { useQuery } from '@tanstack/react-query';
import { zodValidator, ZodValidator } from '@tanstack/zod-form-adapter';
import {
  Button,
  Checkbox,
  ProgressBar,
  Spinner
} from '@thedealersconcierge/components';
import classNames from 'classnames';
import { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { z } from 'zod';
import CreditApplicationConsentForm, {
  CreditApplicationConsentFormValues,
  ValidCreditApplicationConsentSchema
} from '~/components/forms/CreditApplicationConsentForm';
import Header from '~/components/Header';
import { gqlQueryClient } from '~/lib/backend';
import customerQuery, { resetCustomerQuery } from '~/queries/customerQuery';
import dealershipQuery from '~/queries/dealershipQuery';
import meQuery from '~/queries/meQuery';
import { DealershipType } from '~/querySelectors/dealership';
import { useNavigate, useParams } from '~/router';
import createAndSubmitCreditApplicationWorkflow from '~/workflows/formSubmissions/creditApplication/createAndSubmitCreditApplicationWorkflow';
import modifyCreditApplicationWorkflow from '~/workflows/formSubmissions/creditApplication/modification/modifyCreditApplicationWorkflow';

const Forms: FC<{
  transactionId: string;
  userId: string;
  dealership: DealershipType;
  dealershipSlug: string;
  ipAddress: string;
  isModification: boolean;
  fullName?: string;
}> = ({
  transactionId,
  userId,
  dealership,
  dealershipSlug,
  ipAddress,
  isModification,
  /**
   * This is used for the modification notice
   */
  fullName
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const consentForm = useForm<CreditApplicationConsentFormValues, ZodValidator>(
    {
      defaultValues: {
        hasAcceptedAcknowledgements: false,
        hasAcceptedElectronicDisclosure: false,
        hasAcceptedContactConsent: false,
        fullName: '',
        signature: ''
      },
      validators: {
        onSubmit: ValidCreditApplicationConsentSchema(t, 'IN_APP')
      },
      validatorAdapter: zodValidator(),
      onSubmitInvalid: () => {
        toast.error(t('Please acknowledge and provide your signature'));
      },
      onSubmit: async ({ value }) => {
        try {
          setIsSubmitting(true);
          await createAndSubmitCreditApplicationWorkflow({
            transactionId,
            userId,
            dealership,
            signature: value.signature,
            signatureName: value.fullName,
            deviceId: window.navigator.userAgent,
            ipAddress,
            hasAcceptedAcknowledgements: value.hasAcceptedAcknowledgements,
            hasAcceptedElectronicDisclosure:
              value.hasAcceptedElectronicDisclosure,
            t
          });
          await resetCustomerQuery(transactionId, userId);
          navigate('/dashboard/:dealershipSlug/:transactionId', {
            params: { dealershipSlug, transactionId }
          });
        } catch (e) {
          captureException(e);
          console.error(e);
          toast.error(t('An unexpected error happened'));
        } finally {
          setIsSubmitting(false);
        }
      }
    }
  );
  const modificationForm = useForm<
    { hasAcceptedModificationNotice: boolean },
    ZodValidator
  >({
    defaultValues: {
      hasAcceptedModificationNotice: false
    },
    validatorAdapter: zodValidator(),
    validators: {
      onSubmit: z.object({
        hasAcceptedModificationNotice: z.literal(true)
      })
    },
    onSubmitInvalid: () => {
      toast.error(t('Please acknowledge and provide your signature'));
    },
    onSubmit: async ({ value }) => {
      try {
        setIsSubmitting(true);

        if (!fullName) {
          throw new Error('Name not provided');
        }

        await modifyCreditApplicationWorkflow({
          t,
          transactionId,
          userId,
          dealership,
          ipAddress,
          deviceId: window.navigator.userAgent,
          hasAcceptedModificationNotice: value.hasAcceptedModificationNotice
        });
        await resetCustomerQuery(transactionId, userId);
        navigate('/dashboard/:dealershipSlug/:transactionId', {
          params: { dealershipSlug, transactionId }
        });
      } catch (e) {
        captureException(e);
        console.error(e);
        toast.error(t('An unexpected error happened'));
      } finally {
        setIsSubmitting(false);
      }
    }
  });

  return (
    <Fragment>
      {!isModification && (
        <CreditApplicationConsentForm
          dealershipSlug={dealershipSlug}
          dealershipName={dealership.name ?? 'no-dealership-name'}
          form={consentForm}
          isSubmitting={isSubmitting}
          type="IN_APP"
          dataTestId="credit-application-acknowledgement-form"
        />
      )}

      {isModification && (
        <div
          className={classNames(
            'col-span-4 tablet:col-span-6 desktop:col-span-8 tablet:col-start-2 desktop:col-start-3', // Parent grid layout
            'space-y-spacing-02'
          )}
        >
          <h4 className="heading-04">
            {t('Acknowledgements, Consents and Disclosures')}
          </h4>

          <modificationForm.Field name="hasAcceptedModificationNotice">
            {(field) => {
              return (
                <div
                  className="flex flex-row space-x-spacing-02 cursor-pointer"
                  onClick={() => {
                    field.handleChange(!field.state.value);
                  }}
                >
                  <Checkbox
                    checked={field.state.value}
                    onChange={() => {}} // The whole div is clickable
                    disabled={isSubmitting}
                    size="LARGE"
                  />

                  <p className="body-01">
                    {t(
                      'I am modifying my original application due to an error or omission on my behalf. I do this of my own free will and understand that this information may be used to make a credit decision by a bank or other similar entity.'
                    )}
                  </p>
                </div>
              );
            }}
          </modificationForm.Field>
        </div>
      )}

      <div
        className={classNames(
          'col-span-4 tablet:col-span-6 desktop:col-span-8 tablet:col-start-2 desktop:col-start-3', // Parent grid layout
          'flex flex-row justify-end pt-spacing-04'
        )}
      >
        <Button
          label={t('Submit')}
          isLoading={isSubmitting}
          onClick={() => {
            if (!isModification) {
              void consentForm.handleSubmit();
            } else {
              void modificationForm.handleSubmit();
            }
          }}
          data-test-id="credit-application-submit-button"
        />
      </div>
    </Fragment>
  );
};

const Acknowledgements = () => {
  const { t } = useTranslation();
  const searchParams = new URLSearchParams(location.search);
  const isModification = searchParams.get('modification') === 'true';
  const navigate = useNavigate();
  const { dealershipSlug, transactionId } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/credit-application/acknowledgements'
  );
  const { data: meData, isLoading: fetchingMeData } = useQuery(meQuery());
  const userId = meData?.me?.user?.id ?? undefined;
  const { data: customerData, isLoading: fetchingCustomerData } = useQuery(
    customerQuery(transactionId, userId, dealershipSlug)
  );
  const { data: dealership, isLoading: fetchingDealership } = useQuery(
    dealershipQuery(dealershipSlug ? { dealershipSlug } : undefined)
  );
  const { data: publicData, isLoading: fetchingPublicData } = useQuery({
    queryKey: ['public'],
    queryFn: () => {
      return gqlQueryClient({ dealershipSlug })({
        public: {
          ipAddress: true
        }
      });
    }
  });
  const handleGoBack = () => {
    navigate(
      {
        pathname:
          '/dashboard/:dealershipSlug/:transactionId/credit-application/employment',
        search: isModification ? 'modification=true' : ''
      },
      {
        params: {
          dealershipSlug,
          transactionId
        }
      }
    );
  };
  const handleCancel = () => {
    navigate('/dashboard/:dealershipSlug/:transactionId', {
      params: {
        dealershipSlug,
        transactionId
      }
    });
  };

  return (
    <div className="relative flex flex-col items-center bg-primary h-full max-h-screen overflow-y-scroll">
      <Header
        title={
          isModification
            ? t('Credit Application Modification')
            : t('Credit Application')
        }
        className="w-full"
        leftElement={
          <Button
            label={t('Back')}
            variant="GHOST"
            size="LARGE"
            onClick={handleGoBack}
          />
        }
        rightElement={
          <Button
            label={t('Cancel')}
            variant="GHOST"
            size="LARGE"
            onClick={handleCancel}
          />
        }
      />

      <div
        className={classNames(
          'mobile-screen-grid tablet:tablet-screen-grid desktop:desktop-screen-grid', // Grid layout
          'size-full space-y-spacing-05 tablet:space-y-spacing-06 py-spacing-05 tablet:py-spacing-06'
        )}
      >
        <div
          className={classNames(
            'col-span-4 tablet:col-span-8 desktop:col-span-10 desktop:col-start-2', // Parent grid layout
            'w-full relative flex flex-row justify-center'
          )}
        >
          <ProgressBar totalSteps={3} currentStep={3} />
        </div>

        {!fetchingDealership &&
        !fetchingPublicData &&
        !fetchingCustomerData &&
        !fetchingMeData ? (
          dealership?.dealership && userId ? (
            <Forms
              transactionId={transactionId}
              userId={userId}
              dealershipSlug={dealershipSlug}
              dealership={dealership.dealership}
              ipAddress={publicData?.public?.ipAddress ?? 'unknown'}
              isModification={isModification}
              fullName={`${customerData?.customer?.firstName ?? ''} ${customerData?.customer?.lastName ?? ''}`.trim()}
            />
          ) : (
            // This case should usually not happen
            <p>{t('Could not load dealership or user')}</p>
          )
        ) : (
          <div
            className={classNames(
              'col-span-4 tablet:col-span-8 desktop:col-span-10 desktop:col-start-2', // Parent grid layout
              'w-full h-96 relative flex flex-row justify-center items-center'
            )}
          >
            <Spinner color="GREY" size="LARGE" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Acknowledgements;
