import { Add } from '@mui/icons-material';
import { captureException } from '@sentry/react';
import { Button, UserConfirmationModal } from '@thedealersconcierge/components';
import { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import removeCoBuyerAction from '~/actions/removeCoBuyerAction';
import { formatPhoneNumber } from '~/lib/utils';
import {
  TransactionQueryType,
  resetTransactionQuery
} from '~/queries/transactionQuery';
import { useNavigate, useParams } from '~/router';

/**
 * Shows the opposite part of the currently logged in part
 *
 * @param param0
 * @returns
 */
const ParaPartDataSection: FC<{
  seeForRole: 'BUYER' | 'CO_BUYER';
  transaction: TransactionQueryType['transaction'];
}> = ({ seeForRole, transaction }) => {
  const { t } = useTranslation();
  // This is a section type component, hence we know it is only used once
  const { transactionId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId'
  );
  const [
    isRemoveCoBuyerConfirmationModalOpen,
    setIsRemoveCoBuyerConfirmationModalOpen
  ] = useState(false);
  const [isRemovingCoBuyer, setIsRemovingCoBuyer] = useState(false);
  const navigate = useNavigate();

  const customerFullName =
    seeForRole === 'BUYER'
      ? transaction?.customerSharedData?.buyerName
      : transaction?.customerSharedData?.coBuyerName;

  const userEmail =
    seeForRole === 'BUYER'
      ? transaction?.customerSharedData?.buyerEmail
      : transaction?.customerSharedData?.coBuyerEmail;

  const userPhoneNumber =
    seeForRole === 'BUYER'
      ? transaction?.customerSharedData?.buyerPhoneNumber
      : transaction?.customerSharedData?.coBuyerPhoneNumber;

  const removeCoBuyer = async () => {
    try {
      setIsRemovingCoBuyer(true);
      await removeCoBuyerAction({ dealershipSlug }, transactionId);
      await resetTransactionQuery(transactionId, dealershipSlug);
    } catch (error) {
      console.error(error);
      captureException(error);

      if (error instanceof Error && error.message) {
        toast.error(error.message);
      } else {
        toast.error(t('Failed to remove co-buyer. Please contact support.'));
      }
    } finally {
      setIsRemovingCoBuyer(false);
    }
  };

  const handleGoToCoBuyer = () => {
    navigate('/dashboard/:dealershipSlug/:transactionId/coBuyer', {
      params: { transactionId, dealershipSlug }
    });
  };

  const haveCoBuyer = Boolean(transaction?.coBuyerId);

  return (
    <Fragment>
      <UserConfirmationModal
        isOpen={isRemoveCoBuyerConfirmationModalOpen}
        message={t(
          'You are about to remove the co-buyer from this transaction. This action is permanent and cannot be undone. Are you sure you want to proceed?'
        )}
        onClose={() => {
          setIsRemoveCoBuyerConfirmationModalOpen(false);
        }}
        onConfirm={() => {
          setIsRemoveCoBuyerConfirmationModalOpen(false);
          void removeCoBuyer();
        }}
      />

      <div className="flex flex-col h-full">
        {/**
         * The transaction doesn't have a co-buyer
         */}
        {!haveCoBuyer && (
          <div className="bg-primary rounded-lg shadow-border p-6 flex-col space-y-4 h-full">
            <h2>{t('Co-Buyer')}</h2>

            <Button
              label={t('Add')}
              iconLeft={<Add />}
              onClick={handleGoToCoBuyer}
            />
          </div>
        )}

        {/**
         * The transaction does have a co-buyer
         */}
        {haveCoBuyer && (
          <div className="bg-primary rounded-lg shadow-border p-6 flex-col space-y-4 h-full">
            <h2>
              {seeForRole === 'CO_BUYER' ? t('Co-Buyer') : t('Primary Buyer')}
            </h2>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="flex flex-col space-y-1">
                <p className="text-body-2 font-bold uppercase text-tertiary">
                  {t('Name')}
                </p>

                <p>{customerFullName ?? '-'}</p>
              </div>

              <div className="md:col-span-2 flex flex-col space-y-1">
                <p className="text-body-2 font-bold uppercase text-tertiary">
                  {t('Email')}
                </p>

                <p className="overflow-ellipsis overflow-hidden">
                  {userEmail ?? '-'}
                </p>
              </div>

              <div className="flex flex-col space-y-1 mt-2">
                <p className="text-body-2 font-bold uppercase text-tertiary">
                  {t('Phone')}
                </p>

                <p>{formatPhoneNumber(userPhoneNumber ?? undefined) ?? '-'}</p>
              </div>
            </div>

            {/* This is assuming the the logged in part is the buyer */}
            {seeForRole === 'CO_BUYER' && (
              <div className="flex flex-row justify-end">
                <Button
                  label={t('Remove Co-Buyer')}
                  variant="GHOST"
                  size="SMALL"
                  isLoading={isRemovingCoBuyer}
                  onClick={() => {
                    setIsRemoveCoBuyerConfirmationModalOpen(true);
                  }}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default ParaPartDataSection;
