import { DeepKeys, DeepValue, FieldApi } from "@tanstack/react-form";
import { ZodValidator } from "@tanstack/zod-form-adapter";

/**
 * We create this utility function because Tanstack's input field's "field.state.meta.errors" might be undefined contrary to types.
 */
export const getFormFieldErrorMsg = <
  TParentData,
  TName extends DeepKeys<TParentData>,
  TValue extends DeepValue<TParentData, TName>,
>(
  field: FieldApi<TParentData, TName, undefined, ZodValidator, TValue>
): string | undefined => {
  return field.state.meta.errors?.[0]?.toString();
};
