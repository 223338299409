// The Main file
//
// This can be seen as the start script for the app so a lot of things happen globally here.
//
// Therefore:
// 1. Avoid to import this in other files

import { Routes } from '@generouted/react-router';
import * as Sentry from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'jotai';
import { PostHogProvider } from 'posthog-js/react';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router';
import config from './config';
import { g, mkProductionRegistry } from './globals';
import './i18n';
import './index.css';

// Make sure that all dependencies are wired before anything else
mkProductionRegistry();

/**
 * We prefetch some assets to reduce the change of things breaking down stream
 *
 * Top level awaits are not supported, so we do like this
 */
void g.reg.pdfHelper.prefetch();

if (config.sentryDns) {
  Sentry.init({
    dsn: config.sentryDns,
    environment: config.prodEnv,

    // Additional settings
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      }),
      Sentry.replayIntegration()
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const RootComponent = () => {
  // This is not the approach that should be scaled.
  // Move to conditional HOC if we have more things that need to go
  // on conditionally
  if (config.posthog.apiKey && config.posthog.apiHost) {
    return (
      <React.StrictMode>
        <PostHogProvider
          apiKey={config.posthog.apiKey}
          options={{
            api_host: config.posthog.apiHost,
            capture_exceptions: true
          }}
        >
          <Provider store={g.reg.jotaiStore}>
            <QueryClientProvider client={g.reg.queryClient}>
              <Routes />
            </QueryClientProvider>
          </Provider>
        </PostHogProvider>
      </React.StrictMode>
    );
  }

  return (
    <React.StrictMode>
      <Provider store={g.reg.jotaiStore}>
        <QueryClientProvider client={g.reg.queryClient}>
          <Routes />
        </QueryClientProvider>
      </Provider>
    </React.StrictMode>
  );
};

// Render our app!
const rootElement = document.getElementById('root');

if (rootElement && !rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);

  root.render(<RootComponent />);
}
