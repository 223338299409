import { captureException } from '@sentry/react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { Button, PhoneNumberInput } from '@thedealersconcierge/components';
import { UserAuditLogPersistedSchema } from '@thedealersconcierge/lib/codecs/user-audit-events';
import { format } from 'date-fns';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import logoutAction from '~/actions/logoutAction';
import DeprecatedButton from '~/components/Button';
import { gqlMutationClient, gqlQueryClient } from '~/lib/backend';
import { useNavigate } from '~/router';
import ConsumerDashboardHeader from '../dashboard/[dealershipSlug]/[transactionId]/_components/ConsumerDashboardHeader';

export default function TransactionOverviewPage() {
  const { t } = useTranslation();
  const [newPhoneNumber, setNewPhoneNumber] = useState<string | undefined>();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const meData = useSuspenseQuery({
    queryKey: ['userPage'],
    queryFn: async () =>
      await gqlQueryClient({ dealershiplessAuth: true })({
        me: {
          user: {
            id: true,
            email: true,
            phoneNumber: true,

            // For now we are merely showing these, they are not required - yet
            userLogs: [
              {
                first: 10
              },
              {
                edges: {
                  node: {
                    id: true,
                    createdAt: true,
                    event: true
                  }
                }
              }
            ]
          }
        }
      })
  });

  const logEvents =
    meData.data?.me?.user?.userLogs?.edges?.map((r) => r?.node) ?? [];

  const onUpdatePhoneNumber = async () => {
    try {
      if (!newPhoneNumber) {
        toast.error(t('No phone number updated'));
        return;
      }
      const res = await gqlMutationClient({ dealershiplessAuth: true })({
        customerUpdatePhoneNumber: [
          { newPhoneNumber: newPhoneNumber },
          {
            __typename: true,
            '...on GraphQLError': { message: true },
            '...on MutationCustomerUpdatePhoneNumberSuccess': {
              data: {
                status: true
              }
            }
          }
        ]
      });

      await meData.refetch();

      if (
        res.customerUpdatePhoneNumber?.__typename ===
        'MutationCustomerUpdatePhoneNumberSuccess'
      ) {
        setNewPhoneNumber(undefined);
        toast.success(t('Successfully updated phone number'));
        return;
      }

      if (res.customerUpdatePhoneNumber?.__typename === 'GraphQLError') {
        toast.error(
          res.customerUpdatePhoneNumber.message ?? t('Unexpected error')
        );
        return;
      }
    } catch (e) {
      toast.error(
        e instanceof Error ? e.message : t('Unexpected error happened')
      );
      captureException(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const readableEvent = (e: UserAuditLogPersistedSchema) => {
    switch (e.type) {
      case 'LOGGED_IN':
        return t('User logged in');
      case 'UPDATED_EMAIL':
        return t('Email was updated');
      case 'UPDATED_PHONE_NUMBER':
        return t('Phone number was updated');
    }
  };

  return (
    <div className="flex flex-col min-h-dvh">
      <ConsumerDashboardHeader
        leftElement={
          <div className="relative">
            <img src="/logo.png" className="h-4" aria-label="Logo" />
          </div>
        }
        centerElement={<h3>{t('User Settings')}</h3>}
        rightElement={
          <>
            {/* This is not the right way, but currently it is the easiest way to make the buttons look the same
            
            TODO: Use the <Link /> component
            */}
            <DeprecatedButton
              variant="TEXT_ONLY"
              onClick={() => {
                navigate('/dashboard');
              }}
              className="!py-0 pr-0 h-fit"
            >
              <span className="text-secondary">{t('Transactions')}</span>
            </DeprecatedButton>
            <DeprecatedButton
              variant="TEXT_ONLY"
              onClick={() => {
                logoutAction();
              }}
              className="!py-0 pr-0 h-fit"
            >
              <span className="text-secondary">{t('Logout')}</span>
            </DeprecatedButton>
          </>
        }
      />

      <div className="flex flex-1 justify-center bg-secondary py-4 px-6">
        <div className="bg-primary w-full h-fit overflow-y-scroll max-w-screen-lg p-4 rounded-lg shadow-border space-y-4">
          <div className="flex flex-row justify-between">
            <div className="flex flex-row space-x-2">
              <h2>{t('Security')}</h2>
            </div>
          </div>

          {/* <pre>{JSON.stringify(meData.data.me, null, 2)}</pre> */}

          <h3 className="heading-03">{t('Update account phone number')}</h3>

          <div className="flex items-center space-x-6">
            <div>
              <PhoneNumberInput
                value={
                  newPhoneNumber ?? meData.data.me?.user?.phoneNumber ?? ''
                }
                onChange={setNewPhoneNumber}
                label={t('Phone Number')}
                placeholder={t('Phone Number')}
                required={false}
                disabled={false}
                backgroundType="LIGHT"
              />
            </div>
            <div>
              <Button
                isLoading={isSubmitting}
                onClick={() => {
                  void onUpdatePhoneNumber();
                }}
                disabled={!newPhoneNumber}
                label={t('Update Phone Number')}
              />
            </div>
          </div>

          <h3 className="heading-03">{t('User events')}</h3>

          <ul className="flex flex-col space-y-2">
            <div className="grid grid-cols-12 rounded-lg shadow-border bg-secondary px-4 py-2">
              <p className="col-span-5 font-bold">{t('Time')}</p>
              <p className="col-span-3 font-bold">{t('Event')}</p>
            </div>

            {logEvents.length === 0 && (
              <div className="grid grid-cols-12 rounded-lg shadow-border px-4 py-2">
                <div className="col-span-12">{t('No events recorded')}</div>
              </div>
            )}

            {logEvents.map((e) => {
              if (!e) {
                return null;
              }
              if (!e.id) {
                return null;
              }

              const payload = e.event
                ? UserAuditLogPersistedSchema.parse(e.event)
                : undefined;

              return (
                <div
                  key={e?.id}
                  className="grid grid-cols-12  rounded-lg shadow-border px-4 py-2"
                >
                  <div className="col-span-5">
                    {e?.createdAt
                      ? format(e?.createdAt, 'MM/dd/yyyy hh:mm:ss a')
                      : '-'}
                  </div>
                  <div className="col-span-3">
                    {payload ? readableEvent(payload) : '-'}
                  </div>
                </div>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}
