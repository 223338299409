import { PrequalFormSchema } from '@thedealersconcierge/lib/codecs/schema/prequalApplication';
import { generatePdf } from '@thedealersconcierge/lib/pdf-gen';
import { TFunction } from 'i18next';
import stateOptions from '~/config/formSelectionOptions/stateOptions';
import { DealershipIdentifier, g } from '~/globals';
import { prequalForm } from '~/lib/form/prequal-form';
import { dateToDisplayString } from '~/lib/utils';
import uploadFileAction from '../../../actions/formSubmissions/uploadFileAction';

const generateAndUploadPrequalPdfWorkflow = async (
  t: TFunction,
  formData: PrequalFormSchema,
  dealershipName: string,
  dealershipIdentifier: DealershipIdentifier
) => {
  const pdf = await generatePdf(
    prequalForm(t),
    {
      ...formData,
      dealershipName,
      state:
        stateOptions.find((o) => o.value === formData.state)?.label ??
        formData.state,
      birthdate: dateToDisplayString(formData.birthdate),
      middleName: formData.middleName ?? ''
    },
    false,
    undefined,
    await g.reg.pdfHelper.getFont()
  );
  const pdfBlob = new Blob([pdf]);
  const prequalFile = await uploadFileAction(
    dealershipIdentifier,
    'pdf',
    'application/pdf',
    pdfBlob
  );

  return prequalFile.file;
};

export default generateAndUploadPrequalPdfWorkflow;
