import { generatePdf } from '@thedealersconcierge/lib/pdf-gen';
import { TFunction } from 'i18next';
import stateOptions from '~/config/formSelectionOptions/stateOptions';
import { g } from '~/globals';
import { creditApplicationForm } from '~/lib/form/credit-form';
import { dateToDisplayString } from '~/lib/utils';
import { CustomerType } from '~/querySelectors/customer';
import { DealershipType } from '~/querySelectors/dealership';
import generateHardCreditApplicationFormSubmissionDataAction from '../../../actions/formSubmissions/hardCreditApplication/generateHardCreditApplicationFormSubmissionDataAction';
import uploadFileAction from '../../../actions/formSubmissions/uploadFileAction';

export const uploadCreditApplicationWorkflow = async ({
  customer,
  dealership,
  signature,
  hasAcceptedAcknowledgements,
  hasAcceptedElectronicDisclosure,
  hasAcceptedModificationNotice,
  deviceId,
  ipAddress,
  t,
  signatureName
}: {
  customer: CustomerType;
  dealership: DealershipType;
  signature?: string | undefined; // When we do a credit application modification, we do not require a signature again
  hasAcceptedAcknowledgements?: boolean;
  hasAcceptedElectronicDisclosure?: boolean;
  hasAcceptedModificationNotice?: boolean;
  deviceId: string;
  ipAddress: string;
  t: TFunction;
  signatureName?: string | undefined;
}) => {
  if (!dealership.id) {
    throw new Error('Dealership not available');
  }

  const formSubmissionData =
    generateHardCreditApplicationFormSubmissionDataAction({
      customer,
      dealership,
      hasAcceptedAcknowledgements,
      hasAcceptedElectronicDisclosure,
      hasAcceptedModificationNotice,
      signature,
      deviceId,
      ipAddress,
      t,
      signatureName
    });

  // Generate hard credit application
  const applicationPdf = await generatePdf(
    creditApplicationForm(t),
    {
      ...formSubmissionData.submissionData,
      state:
        stateOptions.find(
          (o) => o.value === formSubmissionData.submissionData.state
        )?.label ?? formSubmissionData.submissionData.state,
      birthdate: dateToDisplayString(
        new Date(`${formSubmissionData.submissionData.birthdate}T00:00:00.000Z`)
      ),
      signature: signature ?? ''
    }, // For modified credit applications we do not have a signature
    false,
    undefined,
    await g.reg.pdfHelper.getFont()
  );
  const applicationPdfBlob = new Blob([applicationPdf]);

  const uploadResponse = await uploadFileAction(
    { dealershipId: dealership.id },
    'pdf',
    'application/pdf',
    applicationPdfBlob
  );

  return [uploadResponse, formSubmissionData] as const;
};
