import config from '~/config';
import { g } from '~/globals';
import { authStateAtom, cognitoUserAtom } from '~/state/auth';

export default function logoutAction() {
  // We can not just clear everything as we don't want to loose the kiosk mode
  g.reg.jotaiStore.set(authStateAtom, null);

  // Remove all data that is present cached
  g.reg.queryClient.clear();

  // Sign out the cognito user
  if (config.useCognito) {
    const user = g.reg.jotaiStore.get(cognitoUserAtom);
    user?.signOut();
  }

  // This will reset all atoms that are not stored
  window.location.href = '/';
}
